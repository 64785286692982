/* Nitification */
//get-states;
export const GET_STATES = "GET_STATES";

//get the list of notification
export const NOTIFICATION_LIST = "NOTIFICATION_LIST";

//create notification
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";

//modal toggle
export const MODAL_TOGGLE = "MODAL_TOGGLE";

//update modal
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

//data to be update
export const DATA_TO_BE_UPDATE = "DATA_TO_BE_UPDATE";

//actual updated the data
export const UPDATED_NOTIFICATION_DATA = "UPDATED_NOTIFICATION_DATA";

/* ===End of notification types===  */

/* Dashboard */
export const DASHBOARD = {
  INDEX: "INDEX",
  BOOKING_PER_MONTH: "BOOKING_PER_MONTH",
  BOOKING_PER_WEEK: "BOOKING_PER_WEEK",
  BOOKING_PER_DAY: "BOOKING_PER_DAY",
  BOOKING_SELECTION: "BOOKING_SELECTION",
  SALES_PER_MONTH: "SALES_PER_MONTH",
  SALES_PER_WEEK: "SALES_PER_WEEK",
  SALES_PER_DAY: "SALES_PER_DAY",
  SALES_SELECTION: "SALES_SELECTION",
  SIGNUP_PER_MONTH: "SIGNUP_PER_MONTH",
  SIGNUP_PER_WEEK: "SIGNUP_PER_WEEK",
  SIGNUP_PER_DAY: "SIGNUP_PER_DAY",
  SIGNUP_SELECTION: "SIGNUP_SELECTION",
};

/* Insurance */
export const INSURANCE = {
  //
};

/* Ad module */

export const ADSMODULE = {
  LOADING_WHILE_FETCHING: "LOADING_WHILE_FETCHING",
  FETCH_ADS_MODULE: "FETCH_ADS_MODULE",
  ADS_MODAL: "ADS_MODAL",
  CREATE_ADS: "CREATE_ADS",
  SUCCESSFULLY_CREATED_ADS: "SUCCESSFULLY_CREATED_ADS",
  SUCCESS_MESSAGE: "SUCCESS_MESSAGE",
  DELETE_ADS_MODULE: "DELETE_ADS_MODULE",
  UPDATE_ADS_MODULE: "UPDATE_ADS_MODULE",
  CONFIRM_UPDATE_ADS_MODULE: "CONFIRM_UPDATE_ADS_MODULE",
};

/* Terms and Condition */
export const TERMSANDCONDITION = {
  GET_TERMS_AND_CONDITION: "GET_TERMS_AND_CONDITION",
  UPDATE_TERMS_AND_CONDITION: "UPDATE_TERMS_AND_CONDITION",
};
