import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Row, Col, Table, Button } from "reactstrap";
import {
  fetchAdsModuleAction,
  adsModalAction,
  deleteAdsModuleAction,
  updateAdsModuleAction,
} from "../../../redux/actions/adsModule";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";
import * as icons from "react-icons/fa";
import AdsModule from "./AdsModule";
import SuccessMessage from "../../../components/AdsModuleErrorSuccessMessages/SuccessMessage";

const AdsModuleList = () => {
  const DeleteAdModule = window.Swal;

  const dispatch = useDispatch();

  const adsModuleData = useSelector(
    (state) => state.adsModuleReducer.adsModule
  );

  const loadingWhileFetching = useSelector(
    (state) => state.adsModuleReducer.loadingWhileFetching
  );
  const successfullyCreatedAds = useSelector(
    (state) => state.adsModuleReducer.sucessfullyCreatedAds
  );

  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });

  useEffect(() => {
    dispatch(fetchAdsModuleAction(pagination));
  }, [dispatch]);

  const _deleteAdModule = async (item) => {
    // console.log(item);
    const result = await DeleteAdModule.fire({
      // icon: "warning",
      imageUrl: item.image.url,
      imageWidth: 30,
      imageHeight: 30,
      title: "Are you sure you want to delete?",
      toast: true,
      position: "top-end",
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: `Delete`,
      denyButtonText: `Cancel`,
    });

    //confirm the delete
    if (result.isConfirmed) {
      DeleteAdModule.fire({
        icon: "success",
        title: "You have successfully deleted",
        toast: true,
        position: "top-end",
        timer: 5000,
        showConfirmButton: false,
        timerProgressBar: false,
        onOpen: (toast) => {
          toast.addEventListener("mouseenter", DeleteAdModule.stopTimer);
          toast.addEventListener("mouseleave", DeleteAdModule.resumeTimer);
        },
      });
      //delete into the state also into the db
      dispatch(deleteAdsModuleAction(item.id));
    } else if (result.isDenied) {
      // DeleteAdModule.fire("not deleted", "", "info");
    }
  };
  return (
    <>
      <div>{successfullyCreatedAds ? <SuccessMessage /> : null}</div>

      <div className="content">
        {loadingWhileFetching ? (
          <LinearProgress />
        ) : (
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h5 className="title">Advertisements list</h5>
                </Col>
                <Col className="text-right">
                  <Button
                    className="mb-3"
                    onClick={() => dispatch(adsModalAction(true))}
                  >
                    {" "}
                    <icons.FaPlus /> New Ad
                  </Button>
                </Col>
              </Row>
              <div>
                {adsModuleData.length > 0 ? (
                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Image</th>
                        <th className="text-center">Url</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {adsModuleData.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td className="text-center">{item.id}</td>
                            <td className="text-center">
                              <img
                                src={item.image["url"]}
                                alt={item.id}
                                width="50"
                                height="50"
                              />
                            </td>
                            <td className="text-center">
                              <Link to={item.url}>{item.url}</Link>
                            </td>
                            <td className="text-center">
                              <Button
                                color="warning"
                                size="sm"
                                className="btn-icon btn-simple"
                                onClick={() =>
                                  dispatch(
                                    updateAdsModuleAction({
                                      update: true,
                                      id: item.id,
                                      image: item.image.url,
                                      imageToUpdate: null,
                                      url: item.url,
                                    })
                                  )
                                }
                              >
                                <i className="tim-icons icon-pencil" />
                              </Button>
                              {` `}
                              <Button
                                className="btn-icon btn-simple"
                                color="danger"
                                size="sm"
                                onClick={() => _deleteAdModule(item)}
                              >
                                <i className="tim-icons icon-simple-remove" />
                              </Button>
                              {` `}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  "No record"
                )}
              </div>
            </CardBody>
          </Card>
        )}
      </div>
      <AdsModule />
    </>
  );
};

export default AdsModuleList;
