import React from "react";
import qs from "qs";
import classNames from "classnames";
import moment from "moment";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import {
  addProviderType,
  getProviderType,
  editProviderType,
  updateProviderTypePricing,
} from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";

const providerTypesFieldsArray = [
  {
    id: "NRIC_name_required",
    label: "NRIC_name_required",
    value: true,
  },
  {
    id: "NRIC_number_required",
    label: "NRIC_number_required",
    value: true,
  },
  {
    id: "NRIC_document_required",
    label: "NRIC_document_required",
    value: true,
  },
  {
    id: "license_number_required",
    label: "license_number_required",
    value: true,
  },
  {
    id: "license_document_required",
    label: "license_document_required",
    value: true,
  },
  {
    id: "vehicle_registration_number_required",
    label: "vehicle_registration_number_required",
    value: true,
  },
  {
    id: "type_of_tow_truck_required",
    label: "type_of_tow_truck_required",
    value: true,
  },
  {
    id: "type_of_vehicle_brand_required",
    label: "type_of_vehicle_brand_required",
    value: false,
  },
  {
    id: "type_of_vehicle_model_required",
    label: "type_of_vehicle_model_required",
    value: false,
  },
  {
    id: "vehicle_insurance_policy_number_required",
    label: "vehicle_insurance_policy_number_required",
    value: false,
  },
  {
    id: "vehicle_insurance_expiry_required",
    label: "vehicle_insurance_expiry_required",
    value: false,
  },
  {
    id: "insurance_company_certification_letter_document_required",
    label: "insurance_company_certification_letter_document_required",
    value: false,
  },
  {
    id: "car_auto_certificate_authorized_workshop_PIAM_document_required",
    label: "car_auto_certificate_authorized_workshop_PIAM_document_required",
    value: false,
  },
];

class ProviderType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      providerTypeId: "",
      formInfo: {
        name: "",
        providerTypesFields: providerTypesFieldsArray,
        min_distance: "",
        min_distance_price: "",
        per_km_charge: "",
      },
      loading: false,
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const providerTypeId = params.id;
    if (providerTypeId) {
      this.setState({ providerTypeId, loading: true }, () => {
        this.getProviderType();
      });
    }
  }

  getProviderType = () => {
    const { providerTypeId } = this.state;
    getProviderType(providerTypeId)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getType = getProp(["data", "data", "providerType"]);
          this.setState({
            formInfo: this.parsedFormInfoData(getType(response)),
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  parsedFormInfoData = (data) => {
    let providerTypesFields = this.state.formInfo.providerTypesFields;
    for (const objectProperty in data) {
      let fieldIndex = providerTypesFields.findIndex(
        (o) => o.id == objectProperty
      );
      if (fieldIndex != -1) {
        providerTypesFields[fieldIndex].value = data[objectProperty]
          ? true
          : false;
      }
    }
    let formInfo = {
      name: data.name,
      providerTypesFields,
      min_distance: data.pricing ? data.pricing.min_distance : "",
      min_distance_price: data.pricing ? data.pricing.min_distance_price : "",
      per_km_charge: data.pricing ? data.pricing.per_km_charge : "",
    };
    return formInfo;
  };

  updateProviderType = (e) => {
    e.preventDefault();
    const { formInfo, providerTypeId } = this.state;
    this.setState({ loading: true });

    let updateProviderTypePayload = {
      name: formInfo.name,
    };

    formInfo.providerTypesFields.forEach((type) => {
      updateProviderTypePayload[type.id] = type.value ? 1 : 0;
    });
    editProviderType(providerTypeId, updateProviderTypePayload)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          if (providerTypeId != 1) {
            this.updatePricing();
          } else {
            const options = messageNotificationOptions(
              "Provider Type updated successfully.",
              "success"
            );
            this.refs.notificationAlert.notificationAlert(options);

            this.setState({
              loading: false,
            });
          }
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  updatePricing = () => {
    const { formInfo, providerTypeId } = this.state;

    let pricingUpdatePayload = {
      min_distance: formInfo.min_distance,
      min_distance_price: formInfo.min_distance_price,
      per_km_charge: formInfo.per_km_charge,
    };

    updateProviderTypePricing(providerTypeId, pricingUpdatePayload)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Provider Type updated successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function AddNewProviderType
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */

  AddNewProviderType = (e) => {
    e.preventDefault();
    const { formInfo } = this.state;
    this.setState({ loading: true });

    let addNewproviderTypePayload = {
      name: formInfo.name,
    };

    formInfo.providerTypesFields.forEach((type) => {
      addNewproviderTypePayload[type.id] = type.value ? 1 : 0;
    });
    addProviderType(addNewproviderTypePayload)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Provider Type added successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function updateState
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */
  updateState = (changes, callback = null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...changes,
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * @function updatedFormInfo
   * @param updatedInfo
   * @description Returns updated login info
   */
  updatedFormInfo = (updatedInfo) => {
    const { formInfo } = this.state;
    return { ...formInfo, ...updatedInfo };
  };

  change = (e, key) => {
    const value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };

  /**
   * @function addSlotDistances
   * @param e
   * @description add or edit a model local
   */

  addSlotDistances = (e) => {
    e.preventDefault();
    const { slotDistances, formInfo, selectedDistance } = this.state;
    if (
      formInfo &&
      formInfo.start_distance &&
      formInfo.end_distance &&
      formInfo.price
    ) {
      if (selectedDistance) {
        const deleteIndex = slotDistances.findIndex((item) => {
          return item.id === selectedDistance.id;
        });
        slotDistances.splice(deleteIndex, 1, {
          id: selectedDistance.id,
          start_distance: formInfo.start_distance,
          end_distance: formInfo.end_distance,
          price: formInfo.price,
        });
      } else {
        let distancePayload = {
          start_distance: formInfo.start_distance,
          end_distance: formInfo.end_distance,
          price: formInfo.price,
          id: new Date().getTime(),
        };
        slotDistances.push(distancePayload);
      }
      this.setState({ slotDistances });
    }
  };

  change = (e, key) => {
    let value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };

  changeCheckBoxValue(e, key) {
    let value = e.target.value;
    let providerTypesFields = this.state.formInfo.providerTypesFields;
    let selectedCheckBoxIndex = providerTypesFields.findIndex(
      (o) => o.id == key
    );
    if (selectedCheckBoxIndex != -1) {
      providerTypesFields[selectedCheckBoxIndex].value = !providerTypesFields[
        selectedCheckBoxIndex
      ].value;
    }

    this.setState({
      formInfo: {
        ...this.state.formInfo,
        providerTypesFields: providerTypesFields,
      },
    });
  }

  editSlotDistance = (item) => {
    this.setState({
      formInfo: {
        ...this.state.formInfo,
        ...item,
      },
      selectedDistance: item,
    });
  };

  /**
   * @function deleteSlotDistance
   * @param e
   * @description delete distance from local model array
   */

  deleteSlotDistance = (item) => {
    const { slotDistances } = this.state;
    const deleteIndex = slotDistances.indexOf(item);
    slotDistances.splice(deleteIndex, 1);
    this.setState({ slotDistances });
  };

  cancelForm = (e) => {
    history.goBack();
  };

  render() {
    const { formInfo, providerTypeId } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">
                    {providerTypeId
                      ? "Edit Provider Type"
                      : "Add Provider Type"}
                  </h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row className="row-center-align">
                      <Col md="12">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            type="text"
                            value={formInfo && formInfo.name}
                            onChange={(e) => this.change(e, "name")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {formInfo.providerTypesFields.map((field, key) => (
                          <FormGroup check inline key={key}>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={field.value}
                                value={field.value}
                                onChange={(e) =>
                                  this.changeCheckBoxValue(e, field.id)
                                }
                              />
                              <span className="form-check-sign" />
                              {field.label}
                            </Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </Row>
                    {providerTypeId && providerTypeId != 1 ? (
                      <Row className="mt-4">
                        <Col md="12">
                          <h5>Pricing</h5>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label>Minimum Distance</label>
                            <Input
                              type="text"
                              value={formInfo && formInfo.min_distance}
                              onChange={(e) => this.change(e, "min_distance")}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label>Minimum Distance Price</label>
                            <Input
                              type="text"
                              value={formInfo && formInfo.min_distance_price}
                              onChange={(e) =>
                                this.change(e, "min_distance_price")
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <label>Per KM Charge</label>
                            <Input
                              type="text"
                              value={formInfo && formInfo.per_km_charge}
                              onChange={(e) => this.change(e, "per_km_charge")}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : null}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Button
              className="btn-fill"
              color="secondary"
              type="submit"
              onClick={(e) => {
                this.cancelForm(e);
              }}
            >
              Cancel
            </Button>
            <Button
              className="btn-fill"
              color="primary"
              type="submit"
              onClick={(e) => {
                if (providerTypeId) {
                  this.updateProviderType(e);
                } else {
                  this.AddNewProviderType(e);
                }
              }}
            >
              Save
            </Button>
          </Row>
        </div>
      </>
    );
  }
}

export default ProviderType;
