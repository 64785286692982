import React from "react";
import qs from "qs";
import classNames from "classnames";
import moment from "moment";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import TagsInput from "react-tagsinput";
import ReactTable from "react-table";
import NotificationAlert from "react-notification-alert";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import {
  addPolicySlot,
  getSlotDetail,
  updateSlotDetail,
} from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";

class PolicySlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policyId: "",
      slotId: "",
      formInfo: null,
      loading: false,
      slotDistances: [],
      selectedDistance: null,
      edit: false,
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const policyId = params.policyId;
    const slotId = params.id;
    const isEdit = params.edit;
    if (isEdit) {
      // Edit slot
      this.setState({ slotId, loading: true, edit: true }, () => {
        this.getSlotDetails();
      });
    }
    if (policyId && !isEdit) {
      this.setState({ policyId });
    }
  }

  getSlotDetails = () => {
    const { slotId } = this.state;
    getSlotDetail(slotId)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getSlot = getProp([
            "data",
            "data",
            "insuranceCompanyPolicyTimeSlot",
          ]);
          const getSlotDistances = getProp([
            "data",
            "data",
            "insuranceCompanyPolicyTimeSlot",
            "distances",
          ]);
          const distances = getSlotDistances(response);
          this.setState({
            formInfo: this.parsedFormInfoData(getSlot(response)),
            loading: false,
            slotDistances:
              distances &&
              distances.length &&
              distances.map((item, key) => {
                return { ...item, id: key + 1 };
              }),
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getCurrentDateWithCustomTimeAppend(timeString) {
    let time = timeString.split(":");
    let hour = parseInt(time[0].trim());
    let min = parseInt(time[1].trim());
    let date = new Date();
    date.setHours(hour);
    date.setMinutes(min);

    return moment(date);
  }

  parsedFormInfoData = (data) => {
    let formInfo = {
      ...this.state.formInfo,
      start_time: this.getCurrentDateWithCustomTimeAppend(data.start_time),
      end_time: this.getCurrentDateWithCustomTimeAppend(data.end_time),
    };
    return formInfo;
  };

  updateSlotDetails = (e) => {
    e.preventDefault();
    let { slotId, formInfo, slotDistances } = this.state;
    this.setState({ loading: true });

    let slotPayload = {
      start_time: formInfo.start_time.format("HH:mm"),
      end_time: formInfo.end_time.format("HH:mm"),
      distances: slotDistances,
    };

    updateSlotDetail(slotId, slotPayload)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Slot updated successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function AddNewSlotDetails
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */

  AddNewSlotDetails = (e) => {
    e.preventDefault();
    const { formInfo, policyId, slotDistances } = this.state;
    this.setState({ loading: true });

    let slotPayload = {
      insurance_company_policy_id: policyId,
      start_time: formInfo.start_time.format("HH:mm"),
      end_time: formInfo.end_time.format("HH:mm"),
      distances: slotDistances,
    };
    addPolicySlot(slotPayload)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Slot added successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function updateState
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */
  updateState = (changes, callback = null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...changes,
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * @function updatedFormInfo
   * @param updatedInfo
   * @description Returns updated login info
   */
  updatedFormInfo = (updatedInfo) => {
    const { formInfo } = this.state;
    return { ...formInfo, ...updatedInfo };
  };

  change = (e, key) => {
    const value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };

  /**
   * @function addSlotDistances
   * @param e
   * @description add or edit a model local
   */

  addSlotDistances = (e) => {
    e.preventDefault();
    const { slotDistances, formInfo, selectedDistance } = this.state;
    if (
      formInfo &&
      formInfo.start_distance &&
      formInfo.end_distance &&
      formInfo.price
    ) {
      if (selectedDistance) {
        const deleteIndex = slotDistances.findIndex((item) => {
          return item.id === selectedDistance.id;
        });
        slotDistances.splice(deleteIndex, 1, {
          id: selectedDistance.id,
          start_distance: formInfo.start_distance,
          end_distance: formInfo.end_distance,
          price: formInfo.price,
        });
      } else {
        let distancePayload = {
          start_distance: formInfo.start_distance,
          end_distance: formInfo.end_distance,
          price: formInfo.price,
          id: new Date().getTime(),
        };
        slotDistances.push(distancePayload);
      }
      this.setState({ slotDistances });
    }
  };

  change = (e, key) => {
    const value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };

  editSlotDistance = (item) => {
    this.setState({
      formInfo: {
        ...this.state.formInfo,
        ...item,
      },
      selectedDistance: item,
    });
  };

  /**
   * @function deleteSlotDistance
   * @param e
   * @description delete distance from local model array
   */

  deleteSlotDistance = (item) => {
    const { slotDistances } = this.state;
    const deleteIndex = slotDistances.indexOf(item);
    slotDistances.splice(deleteIndex, 1);
    this.setState({ slotDistances });
  };

  cancelForm = (e) => {
    history.goBack();
  };

  render() {
    const {
      formInfo,
      loading,
      slotDistances,
      selectedModel,
      edit,
    } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">{edit ? "Edit Slot" : "Add Slot"}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Start Time</label>
                          <ReactDatetime
                            dateFormat={false}
                            timeFormat={true}
                            viewMode="time"
                            inputProps={{
                              className: "form-control",
                              placeholder: "Start Time",
                            }}
                            value={formInfo && formInfo.start_time}
                            onChange={(date) =>
                              this.updateState({
                                formInfo: this.updatedFormInfo({
                                  start_time: date,
                                }),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>End Time</label>
                          <ReactDatetime
                            dateFormat={false}
                            timeFormat={true}
                            viewMode="time"
                            inputProps={{
                              className: "form-control",
                              placeholder: "End Time",
                            }}
                            value={formInfo && formInfo.end_time}
                            onChange={(date) =>
                              this.updateState({
                                formInfo: this.updatedFormInfo({
                                  end_time: date,
                                }),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="row-center-align">
                      <Col className="pr-md-1" md="3">
                        <FormGroup>
                          <label>Start Distance</label>
                          <Input
                            type="number"
                            value={formInfo && formInfo.start_distance}
                            onChange={(e) => this.change(e, "start_distance")}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="3">
                        <FormGroup>
                          <label>End Distance</label>
                          <Input
                            type="number"
                            value={formInfo && formInfo.end_distance}
                            onChange={(e) => this.change(e, "end_distance")}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-md-1" md="3">
                        <FormGroup>
                          <label>Cost Per KM</label>
                          <Input
                            type="number"
                            value={formInfo && formInfo.price}
                            onChange={(e) => this.change(e, "price")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <Button
                          className="btn-round btn-icon"
                          color="primary"
                          onClick={(e) => {
                            this.addSlotDistances(e);
                          }}
                        >
                          <i className="tim-icons icon-simple-add" />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReactTable
                          showPagination={false}
                          data={slotDistances}
                          resizable={false}
                          minRows={0}
                          columns={[
                            {
                              Header: "Distance",
                              accessor: "",
                              Cell: (row) => {
                                if (row) {
                                  return (
                                    <div>
                                      {row.original.start_distance +
                                        "Km - " +
                                        row.original.end_distance +
                                        "Km"}
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              },
                              //width: 90,
                            },
                            {
                              Header: "Cost Per KM",
                              accessor: "",
                              Cell: (row) => {
                                if (row) {
                                  return <div>{row.original.price}</div>;
                                } else {
                                  return null;
                                }
                              },
                              //width: 90,
                            },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              Cell: (row) => {
                                if (row) {
                                  return (
                                    <div className="actions-right">
                                      {/* use this button to edit */}
                                      <Button
                                        onClick={() => {
                                          this.editSlotDistance(row.original);
                                        }}
                                        color="warning"
                                        size="sm"
                                        className={classNames(
                                          "btn-icon btn-link like"
                                        )}
                                      >
                                        <i className="tim-icons icon-pencil" />
                                      </Button>
                                      {/* use this button to remove the data row */}
                                      <Button
                                        onClick={() => {
                                          this.deleteSlotDistance(row.original);
                                        }}
                                        color="danger"
                                        size="sm"
                                        className={classNames(
                                          "btn-icon btn-link like"
                                        )}
                                      >
                                        <i className="tim-icons icon-simple-remove" />
                                      </Button>
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              },

                              width: 80,
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                          showPageSizeOptions={false}
                          className="-striped -highlight"
                        />
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-fill"
                    color="secondary"
                    type="submit"
                    onClick={(e) => {
                      this.cancelForm(e);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-fill"
                    color="primary"
                    type="submit"
                    onClick={(e) => {
                      if (edit) {
                        this.updateSlotDetails(e);
                      } else {
                        this.AddNewSlotDetails(e);
                      }
                    }}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PolicySlot;
