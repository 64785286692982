import React, { Component } from "react";
import { history, isLoggedIn } from "./helpers";

export default class CheckAuth extends Component {
  componentDidMount() {
    const user = isLoggedIn();
    if (!user) {
      history.push("/auth/login");
    } else {
      history.push("/admin/dashboard");
    }
  }

  render() {
    return <div />;
  }
}
