import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  notificationList,
  notificationModalToggle,
  updateNotificationModal,
  dataTobeUpdate,
} from "../../../redux/actions/notification";
import Notification from "./Notification";

const NotificationsList = () => {
  //paganition state
  const [pages, setPages] = useState({
    page: 1,
    perPage: 10,
  });

  const dispatch = useDispatch();

  const notificationData = useSelector(
    (state) => state.notificationReducer.notificationList
  );

  useEffect(() => {
    dispatch(notificationList());
  }, [dispatch]);

  const schelude = (dateTime) => {
    const now = new Date(dateTime);
    return `${now.getFullYear()}-0${
      now.getMonth() + 1
    }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} `;
  };
  return (
    <>
      <div className="content">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <CardHeader>
                  <h5 className="title">Notifications list</h5>
                </CardHeader>
              </Col>
              <Col className="text-right">
                <Button
                  className="mb-3"
                  title="Create notification"
                  onClick={() => dispatch(notificationModalToggle(true))}
                >
                  Create notification
                </Button>
              </Col>
            </Row>
            {notificationData.length > 0 ? (
              <div>
                <Table striped bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th className="text-center">Title</th>
                      <th className="text-center">Type</th>
                      <th className="text-center">Post code</th>
                      <th className="text-center">Scheduled</th>
                      <th className="text-center">Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notificationData.map((list) => {
                      return (
                        <tr key={list.id}>
                          <th className="text-center">{list.title}</th>
                          <th className="text-center">
                            {list.send_type === 1 ? "User" : "Provider"}
                          </th>
                          <th className="text-center">
                            {list.type_data.join(",")}
                          </th>
                          <th className="text-center">
                            {schelude(list.scheduled_on)}
                          </th>
                          <th className="text-center">{list.message}</th>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              "No record"
            )}
          </CardBody>
        </Card>
      </div>
      <Notification />
    </>
  );
};

export default NotificationsList;
