import axios from "axios";
import {
  getAuthHeaders,
  baseUrl,
  getAuthHeadersForUploading,
} from "../helpers";

export const fetchBatteries = (page, perPage) => {
  let url = `${baseUrl}panel-api/admin/car-batteries/gets?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const addBattery = (data) => {
  let url = `${baseUrl}panel-api/admin/car-batteries/add`;
  return axios.post(url, data, {
    headers: getAuthHeadersForUploading(),
  });
};

export const getBattery = (id) => {
  let url = `${baseUrl}panel-api/admin/car-batteries/get/${id}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const editBattery = (id, data) => {
  let url = `${baseUrl}panel-api/admin/car-batteries/edit/${id}`;
  return axios.post(url, data, {
    headers: getAuthHeadersForUploading(),
  });
};
