import axios from "axios";
import { getAuthHeaders, baseUrl } from "../../helpers";
import { DASHBOARD } from "../types";

const indexDashBoard = (payload) => {
  return {
    type: DASHBOARD.INDEX,
    payload,
  };
};

export const indexDashBoardAction = () => {
  const url = `${baseUrl}panel-api/admin/dashboard/index`;
  return async (dispatch) => {
    try {
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });
      if (response.status === 200) {
        const data = response.data.data;
        dispatch(indexDashBoard(data));
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
};

const bookingSelectionPayload = (payload) => {
  return {
    type: DASHBOARD.BOOKING_SELECTION,
    payload,
  };
};

//booking per month
const bookingPerMonthPayload = (payload) => {
  return {
    type: DASHBOARD.BOOKING_PER_MONTH,
    payload,
  };
};
//booking per week
const bookingPerWeekPayload = (payload) => {
  return {
    type: DASHBOARD.BOOKING_PER_WEEK,
    payload,
  };
};

//booking per day
const bookingPerDay = (payload) => {
  return {
    type: DASHBOARD.BOOKING_PER_DAY,
    payload,
  };
};

//booking selection
export const bookingAction = ({ start_date, end_date, type }, booking) => {
  return async (dispatch) => {
    let url = "";

    //default value booking per month
    if (booking === null) {
      url = `${baseUrl}panel-api/admin/dashboard/get-bookings-per-month?start_date=${start_date}&end_date=${end_date}&type=${type}`;
    } else if (booking === "Monthly") {
      url = `${baseUrl}panel-api/admin/dashboard/get-bookings-per-month?start_date=${start_date}&end_date=${end_date}&type=${type}`;
    } else if (booking === "Weekly") {
      url = `${baseUrl}panel-api/admin/dashboard/get-bookings-per-week?start_date=${start_date}&end_date=${end_date}&type=${type}`;
    } else if (booking === "Daily") {
      url = `${baseUrl}panel-api/admin/dashboard/get-bookings-per-day?start_date=${start_date}&end_date=${end_date}&type=2`;
    }

    try {
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });

      if (response.status === 200) {
        //default value booking per month
        if (booking === null) {
          const data = response.data.data;
          const entries = new Map(data);
          const obj = Object.fromEntries(entries);
          dispatch(bookingPerMonthPayload(obj));
        } else if (booking === "Monthly") {
          const data = response.data.data;
          const entries = new Map(data);
          const obj = Object.fromEntries(entries);
          dispatch(bookingPerMonthPayload(obj));
          //set the selection to monthly
          dispatch(bookingSelectionPayload("Monthly"));
        } else if (booking === "Weekly") {
          // console.log(response.data.data);

          const data = response.data.data;

          dispatch(bookingPerWeekPayload(data));

          //set the selection to Weekly
          dispatch(bookingSelectionPayload("Weekly"));
        } else if (booking === "Daily") {
          // console.log(response.data.data);

          const data = response.data.data;

          dispatch(bookingPerDay(data));

          //set the selection to Daily
          dispatch(bookingSelectionPayload("Daily"));
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
};

/* End of booking */

/* Sale */
const salesPerMonth = (payload) => {
  return {
    type: DASHBOARD.SALES_PER_MONTH,
    payload,
  };
};

const salesPerWeek = (payload) => {
  return {
    type: DASHBOARD.SALES_PER_WEEK,
    payload,
  };
};

const salePerDay = (payload) => {
  return {
    type: DASHBOARD.SALES_PER_DAY,
    payload,
  };
};

const salesSelection = (payload) => {
  return {
    type: DASHBOARD.SALES_SELECTION,
    payload,
  };
};
export const salesAction = ({ start_date, end_date }, sales) => {
  return async (dispatch) => {
    let url = "";

    //default value salse per month
    if (sales === null) {
      url = `${baseUrl}panel-api/admin/dashboard/get-sales-per-month?start_date=${start_date}&end_date=${end_date}`;
    } else if (sales === "Monthly") {
      url = `${baseUrl}panel-api/admin/dashboard/get-sales-per-month?start_date=${start_date}&end_date=${end_date}`;
    } else if (sales === "Weekly") {
      url = `${baseUrl}panel-api/admin/dashboard/get-sales-per-week?start_date=${start_date}&end_date=${end_date}`;
    } else if (sales === "Daily") {
      url = `${baseUrl}panel-api/admin/dashboard/get-sales-per-day?start_date=${start_date}&end_date=${end_date}`;
    }
    try {
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });
      if (response.status === 200) {
        //default value booking per month
        if (sales === null) {
          const data = response.data.data;
          const entries = new Map(data);
          const obj = Object.fromEntries(entries);
          dispatch(salesPerMonth(obj));
        } else if (sales === "Monthly") {
          const data = response.data.data;
          const entries = new Map(data);
          const obj = Object.fromEntries(entries);
          dispatch(salesPerMonth(obj));

          dispatch(salesSelection("Monthly"));
        } else if (sales === "Weekly") {
          // console.log(response.data.data);
          const data = response.data.data;
          dispatch(salesPerWeek(data));
          dispatch(salesSelection("Weekly"));
        } else if (sales === "Daily") {
          // console.log(response.data.data);
          const data = response.data.data;
          dispatch(salePerDay(data));
          dispatch(salesSelection("Daily"));
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
};
/* End of sale */

/* Sign up */

const singUpPerMonth = (payload) => {
  return {
    type: DASHBOARD.SIGNUP_PER_MONTH,
    payload,
  };
};

const signUpPerWeek = (payload) => {
  return {
    type: DASHBOARD.SIGNUP_PER_WEEK,
    payload,
  };
};

const singUpPerDay = (payload) => {
  return {
    type: DASHBOARD.SIGNUP_PER_DAY,
    payload,
  };
};

const signUpSelection = (payload) => {
  return {
    type: DASHBOARD.SIGNUP_SELECTION,
    payload,
  };
};
export const signUpAction = ({ start_date, end_date }, signUp) => {
  return async (dispatch) => {
    let url = "";

    //default value salse per month
    if (signUp === null) {
      url = `${baseUrl}panel-api/admin/dashboard/get-sign-ups-per-month?start_date=${start_date}&end_date=${end_date}`;
    } else if (signUp === "Monthly") {
      url = `${baseUrl}panel-api/admin/dashboard/get-sign-ups-per-month?start_date=${start_date}&end_date=${end_date}`;
    } else if (signUp === "Weekly") {
      url = `${baseUrl}panel-api/admin/dashboard/get-sign-ups-per-week?start_date=${start_date}&end_date=${end_date}`;
    } else if (signUp === "Daily") {
      url = `${baseUrl}panel-api/admin/dashboard/get-sign-ups-per-day?start_date=${start_date}&end_date=${end_date}`;
    }
    try {
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });
      if (response.status === 200) {
        if (signUp === null) {
          const data = response.data.data;
          const entries = new Map(data);
          const obj = Object.fromEntries(entries);
          dispatch(singUpPerMonth(obj));
        } else if (signUp === "Monthly") {
          const data = response.data.data;
          const entries = new Map(data);
          const obj = Object.fromEntries(entries);
          dispatch(singUpPerMonth(obj));
          dispatch(signUpSelection("Monthly"));
        } else if (signUp === "Weekly") {
          const data = response.data.data;
          dispatch(signUpPerWeek(data));
          dispatch(signUpSelection("Weekly"));
        } else if (signUp === "Daily") {
          console.log(response.data.data);
          const data = response.data.data;
          dispatch(singUpPerDay(data));
          dispatch(signUpSelection("Daily"));
        }
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
};
