import React from "react";
// reactstrap components
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Container,
  Col,
  FormGroup,
} from "reactstrap";
import qs from "qs";
import { resetPassword } from "../../services/AuthServices";
import {
  getProp,
  history,
  messageNotificationOptions,
  verifyLength,
  compare,
} from "../../helpers";

class ResetPassword extends React.Component {
  state = {
    password: "",
    passwordState: "",
    passwordFocus: false,
    confirmPassword: "",
    confirmPasswordState: "",
    vcode: "",
    email: "",
  };
  componentDidMount() {
    // document.body.classList.toggle("lock-page");
    const params = qs.parse(this.props.location.search.slice(1));
    const email = params.ph;
    const vcode = params.vc;
    this.setState({ email, vcode });
  }
  componentWillUnmount() {
    // document.body.classList.toggle("lock-page");
  }

  change = (event, stateName, type) => {
    switch (type) {
      case "password":
        if (verifyLength(event.target.value, 6)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "confirmPassword":
        if (verifyLength(event.target.value, 6)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;

      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  sendResetRequest = (e) => {
    e.preventDefault();
    const { password, confirmPassword, email, vcode } = this.state;
    if (!compare(password, confirmPassword)) {
      const options = messageNotificationOptions(
        "Password and confirm password does not match.",
        "danger"
      );
      this.refs.notificationAlert.notificationAlert(options);
      return;
    }
    const params = { username: email, code: vcode, password };
    resetPassword(params)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Your password has been reset successfully. Please login again.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
          setTimeout(() => {
            history.push("/auth/login");
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };

  render() {
    const { confirmPasswordState, passwordState } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-lock card-white text-center">
                {/* <CardHeader>
                  <img alt="..." src={require("assets/img/emilyz.jpg")} />
                </CardHeader> */}
                <CardBody>
                  <CardTitle tag="h4">Forget Password</CardTitle>

                  <FormGroup className={`has-label ${passwordState}`}>
                    <label>New Password *</label>
                    <Input
                      placeholder="New Password"
                      type="text"
                      onChange={(e) => this.change(e, "password", "password")}
                    />
                    {passwordState === "has-danger" ? (
                      <label className="error">
                        Password should be of atleast 6 characters.
                      </label>
                    ) : null}
                  </FormGroup>
                  <FormGroup className={`has-label ${passwordState}`}>
                    <label>Confirm New Password *</label>
                    <Input
                      placeholder="Confirm Password"
                      type="text"
                      onChange={(e) =>
                        this.change(e, "confirmPassword", "confirmPassword")
                      }
                    />
                    {confirmPasswordState === "has-danger" ? (
                      <label className="error">
                        Password and confrim password should match.
                      </label>
                    ) : null}
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    disabled={
                      passwordState !== "has-success" ||
                      confirmPasswordState !== "has-success"
                    }
                    className="btn-round"
                    color="primary"
                    href="#pablo"
                    size="lg"
                    onClick={(e) => {
                      this.sendResetRequest(e);
                    }}
                  >
                    Reset Password
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default ResetPassword;
