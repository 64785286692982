import React from "react";
import { history } from "./index";

/**
 * @function getProp
 * @param {*} p
 * @desc it will get the data string from the repsonse
 */
export const getProp = (p) => (o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

export const messageNotificationOptions = (message, type) => {
  var options = {};
  options = {
    place: "tr",
    message: (
      <div>
        <div>{message}</div>
      </div>
    ),
    type: type,
    icon: "tim-icons icon-bell-55",
    autoDismiss: 5,
  };
  return options;
};

// function that verifies if two strings are equal
export const compare = (string1, string2) => {
  if (string1 === string2) {
    return true;
  }
  return false;
};

// function that verifies if a string has a given length or not
export const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

// function that returns true if value is email, false otherwise
export const verifyEmail = (value) => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
