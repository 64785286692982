import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from "reactstrap";
import ReactTable from "react-table";
import { fetchCars } from "../../../services";
import { getProp } from "../../../helpers";

class CarBrandsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 5,
      loading: false,
      alert: null,
      isModalOpen: false,
    };
  }

  componentDidMount() {
    this.getCarBrands();
  }

  getCarBrands = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchCars(currentPage, perPage)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getBrandsList = getProp(["data", "data", "carBrands"]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const brands = getBrandsList(response);
          const modifiedBrands = brands.map((item) => {
            return {
              ...item,
            };
          });
          this.setState({
            data: modifiedBrands,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  open = () => {
    this.setState({ isModalOpen: true });
  };

  close = () => {
    this.setState({ isModalOpen: false });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getCarBrands();
    });
  };

  render() {
    const { loading, data, currentPage, totalPages, isModalOpen } = this.state;

    return (
      <Modal isOpen={isModalOpen} toggle={this.close}>
        <ModalHeader toggle={this.close}>Select Car Brand</ModalHeader>
        <ModalBody>
          <Card>
            <CardBody>
              <ReactTable
                data={data}
                filterable
                resizable={false}
                columns={[
                  {
                    Header: "Name",
                    accessor: "name",
                  },
                  //   {
                  //     Header: "Model",
                  //     accessor: "model",
                  //   },
                ]}
                page={currentPage - 1}
                pages={totalPages}
                defaultPageSize={5}
                showPaginationBottom={true}
                showPageSizeOptions={false}
                onPageChange={this.onPageChange}
                className="-striped -highlight"
                loading={loading}
                manual={true}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: (e) => {
                        this.close();
                        this.props.onSelectMenuItem(rowInfo.original);
                      },
                    };
                  } else {
                    return {};
                  }
                }}
              />
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CarBrandsModal;
