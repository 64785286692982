import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import * as icons from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  termsAndConditionAction,
  updateTermsAndConditionAction,
} from "../../../redux/actions/termsAndCondition";

const ContactUs = () => {
  const Toast = window.Swal;

  const dispatch = useDispatch();

  const contactUsData = useSelector(
    (state) => state.termsAndConditionReducer.policy
  );

  const [contactUs, setContactUs] = useState({
    contact_us: "",
  });

  useEffect(() => {
    document.title = "CemasKhidmat | Contact Us";

    setContactUs({
      ...contactUs,
      contact_us: contactUsData.contact_us,
    });

    dispatch(termsAndConditionAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, contactUsData.contact_us]);

  const _updateContactUs = (e) => {
    e.preventDefault();

    dispatch(updateTermsAndConditionAction(contactUs));

    Toast.fire({
      icon: "success",
      title: "Contact Us updated successfully",
      toast: true,
      position: "top-end",
      timer: 5000,
      showConfirmButton: false,
      timerProgressBar: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Toast.stopTimer);
        toast.addEventListener("mouseleave", Toast.resumeTimer);
      },
    });
  };

  return (
    <>
      <div className="content">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <CardHeader>
                  <h5 className="title"> Contact Us</h5>
                </CardHeader>
              </Col>
              <Col className="text-right">
                <Button
                  className="mb-3"
                  title="Create notification"
                  onClick={_updateContactUs}
                >
                  <icons.BsArrowRepeat /> Update Contact Us
                </Button>
              </Col>
            </Row>
            <ReactQuill
              theme="snow"
              value={contactUs.contact_us || " "}
              onChange={(value) =>
                setContactUs({
                  ...contactUs,
                  contact_us: value,
                })
              }
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ContactUs;
