/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import VectorMap from "views/maps/VectorMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import ReactTables from "views/tables/ReactTables.js";
import RegularTables from "views/tables/RegularTables.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import Wizard from "views/forms/Wizard.js";
import ValidationForms from "views/forms/ValidationForms.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import RegularForms from "views/forms/RegularForms.js";
import Calendar from "views/Calendar.js";
import Widgets from "views/Widgets.js";
import Charts from "views/Charts.js";
// import Dashboard from "views/Dashboard.js";

import Buttons from "views/components/Buttons.js";
import Notifications from "views/components/Notifications.js";
import Grid from "views/components/Grid.js";
import Typography from "views/components/Typography.js";
import Panels from "views/components/Panels.js";
import Icons from "views/components/Icons.js";
import Pricing from "views/pages/Pricing.js";
import Register from "views/pages/Register.js";
import Timeline from "views/pages/Timeline.js";
import User from "views/pages/Users/User.js";
import Login from "views/pages/Login.js";
import Rtl from "views/pages/Rtl.js";
import Lock from "views/pages/Lock.js";
import Reset from "views/pages/ResetPassword.js";
import Verify from "views/pages/VerifyCode.js";
import Users from "views/pages/Users/UserList.js";
import Cars from "views/pages/Cars/CarsList.js";
import Car from "views/pages/Cars/Car.js";
import CompanyList from "views/pages/Insurance/CompanyList";
import Company from "views/pages/Insurance/Company";
import PoliciesList from "views/pages/Insurance/PoliciesList";
import Policy from "views/pages/Insurance/Policy";
import PolicySlots from "views/pages/Insurance/Policy-Slots";
import PolicySlot from "views/pages/Insurance/PolicySlot";
import Bookings from "views/pages/Bookings/BookingList";
import ProviderTypesList from "views/pages/ProviderTypes/ProviderTypesList";
import ProviderType from "views/pages/ProviderTypes/ProviderType";
import BatteryList from "views/pages/Battery/BatteryList";
import Battery from "views/pages/Battery/Battery";
import Providers from "views/pages/Providers/ProviderList";
import Provider from "views/pages/Providers/Provider";
import VehicleTypesList from "views/pages/VehicleTypes/VehicleTypesList";
import VehicleType from "views/pages/VehicleTypes/VehicleType";
import UserQueriesList from "views/pages/Queries/UserQueriesList";
import ProviderQueriesList from "views/pages/Queries/ProviderQueriesList";
import Withdrawls from "views/pages/UserWithdrawls/Withdrawls";
import ProviderRequests from "views/pages/ProviderWithdrawls/ProviderRequests";
import Tax from "views/pages/Tax/Tax";
import Commision from "views/pages/Tax/Commision";
import TransactionFee from "views/pages/Tax/TransactionFee";
// import Notification from "views/pages/Notification/Notification";
// import AddNotification from "views/pages/Notification/AddNotification";
import NotificationsList from "./views/pages/Notification/NotificationsList";
import Dashboard from "./views/Dashboard.jsx";
import AdsModuleList from "./views/pages/AdModule/AdsModuleList";
import TermsAndCondition from "./views/pages/Policy/TermsAndCondition";
import PrivacyPolicy from "./views/pages/Policy/PrivacyPolicy";
import ContactUs from "./views/pages/Policy/ContactUs";
import ContactList from "./views/pages/DefaultEmergencyContacts/ContactList";
import Contact from "./views/pages/DefaultEmergencyContacts/Contact";
import ReviewList from "./views/pages/Reviews/ReviewList";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   mini: "I",
  //   rtlMini: "و",
  //   component: Icons,
  //   layout: "/admin",
  // },
  {
    path: "/companies",
    name: "Insurance Companies",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-components",
    component: CompanyList,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/user-withdrawls",
    name: "User Withdrawls",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-money-coins",
    component: Withdrawls,
    layout: "/admin",
  },
  {
    path: "/bookings",
    name: "Bookings",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-copy-04",
    component: Bookings,
    layout: "/admin",
  },
  {
    path: "/providers",
    name: "Providers",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-settings",
    component: Providers,
    layout: "/admin",
  },
  {
    path: "/provider-withdrawls",
    name: "Provider Withdrawls",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-money-coins",
    component: ProviderRequests,
    layout: "/admin",
  },
  {
    path: "/cars",
    name: "Cars",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-bus-front-12",
    component: Cars,
    layout: "/admin",
  },
  {
    path: "/battery",
    name: "Battery Management",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-vector",
    component: BatteryList,
    layout: "/admin",
  },
  {
    path: "/provider-types",
    name: "Provider Types",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-bullet-list-67",
    component: ProviderTypesList,
    layout: "/admin",
  },
  {
    path: "/vehicle-types",
    name: "Vehicle Types",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: VehicleTypesList,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   mini: "I",
  //   rtlMini: "و",
  //   component: Icons,
  //   layout: "/admin",
  // },
  {
    path: "/user-queries",
    name: "User Queries",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-support-17",
    component: UserQueriesList,
    layout: "/admin",
  },
  {
    path: "/provider-queries",
    name: "Provider Queries",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-support-17",
    component: ProviderQueriesList,
    layout: "/admin",
  },
  {
    path: "/emergency-contacts",
    name: "Emergency Contacts",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-bulb-63",
    component: ContactList,
    layout: "/admin",
  },
  {
    path: "/booking-reviews",
    name: "Booking Reviews",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-satisfied",
    component: ReviewList,
    layout: "/admin",
  },
  {
    redirect: true,

    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: "tim-icons icon-image-02",
    state: "pagesCollapse",
    views: [
      {
        path: "/edit-contact",
        name: "Emergency Contact",
        rtlName: "لوحة القيادة",
        icon: "tim-icons icon-single-02",
        component: Contact,
        layout: "/admin",
      },
      {
        redirect: true,
        path: "/pricing",
        name: "Pricing",
        rtlName: "عالتسعير",
        mini: "P",
        rtlMini: "ع",
        component: Pricing,
        layout: "/auth",
      },
      {
        redirect: true,
        path: "/rtl-support",
        name: "RTL Support",
        rtlName: "صودعم رتل",
        mini: "RS",
        rtlMini: "صو",
        component: Rtl,
        layout: "/rtl",
      },
      {
        redirect: true,

        path: "/timeline",
        name: "Timeline",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: Timeline,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/login",
        name: "Login",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: Login,
        layout: "/auth",
      },
      {
        redirect: true,

        path: "/register",
        name: "Register",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: Register,
        layout: "/auth",
      },
      {
        redirect: true,

        path: "/lock-screen",
        name: "Lock Screen",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: Lock,
        layout: "/auth",
      },
      {
        redirect: true,

        path: "/verify-code",
        name: "Verify Screen",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: Verify,
        layout: "/auth",
      },
      {
        redirect: true,

        path: "/reset-password",
        name: "Reset Screen",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: Reset,
        layout: "/auth",
      },
      {
        redirect: true,

        path: "/edit-user",
        name: "Edit User",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: User,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/edit-company",
        name: "Edit Company",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: Company,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/edit-car",
        name: "Edit Car",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: Car,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/add-car",
        name: "Add Car",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: Car,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/insurance-policies",
        name: "Insurance Policies",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: PoliciesList,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/add-policy",
        name: "Add Policy",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: Policy,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/edit-policy",
        name: "Edit Policy",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: Policy,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/view-policy-slots",
        name: "Edit Policy",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: PolicySlots,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/add-policy-slot",
        name: "Add Policy Slot",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: PolicySlot,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/edit-policy-slot",
        name: "Edit Policy Slot",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: PolicySlot,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/add-provider-type",
        name: "Add Provider Type",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: ProviderType,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/edit-provider-type",
        name: "Edit Provider Type",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: ProviderType,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/add-battery",
        name: "Add Battery",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: Battery,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/edit-battery",
        name: "Edit Battery",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: Battery,
        layout: "/admin",
      },
      {
        redirect: true,

        path: "/provider",
        name: "Provider",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: Provider,
        layout: "/admin",
      },
      {
        redirect: true,
        path: "/add-vehicle-type",
        name: "Vehicle Type",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: VehicleType,
        layout: "/admin",
      },
      {
        redirect: true,
        path: "/edit-vehicle-type",
        name: "Vehicle Type",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: VehicleType,
        layout: "/admin",
      },
    ],
  },
  {
    redirect: true,

    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: "tim-icons icon-molecule-40",
    state: "componentsCollapse",
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MLT",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "/buttons",
            name: "Buttons",
            rtlName: "وصفت",
            mini: "B",
            rtlMini: "ب",
            component: Buttons,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: Grid,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/admin",
      },

      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    redirect: true,
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "tim-icons icon-notes",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    redirect: true,
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: "tim-icons icon-puzzle-10",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/admin",
      },
    ],
  },
  {
    redirect: true,
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: "tim-icons icon-pin",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    redirect: true,

    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: "tim-icons icon-settings",
    component: Widgets,
    layout: "/admin",
  },
  {
    redirect: true,

    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: "tim-icons icon-chart-bar-32",
    component: Charts,
    layout: "/admin",
  },
  {
    redirect: true,

    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: "tim-icons icon-time-alarm",
    component: Calendar,
    layout: "/admin",
  },
  {
    path: "/tax",
    name: "Tax",
    rtlName: "التقويم",
    icon: "tim-icons icon-coins",
    component: Tax,
    layout: "/admin",
  },
  {
    path: "/transaction-fee",
    name: "Transaction Fee",
    rtlName: "التقويم",
    icon: "tim-icons icon-coins",
    component: TransactionFee,
    layout: "/admin",
  },
  {
    path: "/commision",
    name: "Commision",
    rtlName: "التقويم",
    icon: "tim-icons icon-coins",
    component: Commision,
    layout: "/admin",
  },
  /* notification route */
  {
    path: "/notification",
    name: "Notification",
    rtlName: "الرموز",
    mini: "I",
    rtlMini: "و",
    icon: "tim-icons icon-bell-55",
    component: NotificationsList,
    layout: "/admin",
  },
  // {
  //   redirect: true,
  //   path: "/add-notification",
  //   name: "AddNotification",
  //   rtlName: "التقويم",
  //   icon: "tim-icons icon-time-alarm",
  //   component: AddNotification,
  //   layout: "/admin",
  // },
  /* ad module */
  {
    path: "/add-module",
    name: "Ad Management",
    icon: "tim-icons icon-planet",
    component: AdsModuleList,
    layout: "/admin",
  },
  //Terms and condition
  // {
  //   path: "/terms-and-condition",
  //   name: "Terms and Condition",
  //   icon: "tim-icons icon-planet",
  //   component: TermsAndCondition,
  //   layout: "/admin",
  // },
  {
    collapse: true,
    name: "Policy",
    icon: "tim-icons icon-alert-circle-exc",
    // state: "string",
    views: [
      {
        path: "/terms-and-condition",
        name: "Terms and Condition",
        icon: "tim-icons icon-alert-circle-exc",
        component: TermsAndCondition,
        layout: "/admin",
      },
      {
        path: "/privacy-policy",
        name: "Privacy Policy",
        icon: "tim-icons icon-alert-circle-exc",
        component: PrivacyPolicy,
        layout: "/admin",
      },
      {
        path: "/contuct-us",
        name: "Contact Us",
        icon: "tim-icons icon-alert-circle-exc",
        component: ContactUs,
        layout: "/admin",
      },
    ],
  },
];

export default routes;
