import axios from "axios";
import { getAuthHeaders, baseUrl } from "../helpers";

export const getTax = () => {
  let url = `${baseUrl}panel-api/admin/others/get-tax`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};
export const addNewTax = (tax) => {
  console.log("data sent", tax);
  let url = `${baseUrl}panel-api/admin/others/set-tax`;
  return axios.post(url, tax, {
    headers: getAuthHeaders(),
  });
};

export const getCommision = () => {
  let url = `${baseUrl}panel-api/admin/others/get-commision-percentage`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};
export const addNewCommision = (commision) => {
  let url = `${baseUrl}panel-api/admin/others/set-commision-percentage`;
  return axios.post(url, commision, {
    headers: getAuthHeaders(),
  });
};
