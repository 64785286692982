import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import notificationReducer from "./reducers/notifiaction";
import dashboardReducers from "./reducers/dashboard";
import adsModuleReducer from "./reducers/adsModule";
import termsAndConditionReducer from "./reducers/termsAndCondition";

const middleware = compose(
  process.env.NODE_ENV === "development"
    ? applyMiddleware(thunk, logger)
    : applyMiddleware(thunk)
);

const reducers = combineReducers({
  notificationReducer,
  dashboardReducers,
  adsModuleReducer,
  termsAndConditionReducer,
});

export default createStore(
  reducers,
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(middleware)
    : middleware
);
