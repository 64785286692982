import { baseUrl, getAuthHeaders } from "../../helpers";
import axios from "axios";
import { TERMSANDCONDITION } from "../types";

const termsAndConditionPayload = (payload) => {
  return {
    type: TERMSANDCONDITION.GET_TERMS_AND_CONDITION,
    payload,
  };
};

export const termsAndConditionAction = () => {
  const url = `${baseUrl}panel-api/admin/others/gets`;
  return async (dispatch) => {
    try {
      const response = await axios.get(url, {
        headers: getAuthHeaders(),
      });

      if (response.status === 200 && response.data.success === 1) {
        const data = response.data.data;
        console.log("Response: ", data);
        dispatch(termsAndConditionPayload(data));
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
};

// const updateTermsAndConditionPayload = (payload) => {
//   return {
//     type: TERMSANDCONDITION.UPDATE_TERMS_AND_CONDITION,
//     payload,
//   };
// };
export const updateTermsAndConditionAction = (policy) => {
  const url = `${baseUrl}panel-api/admin/others/save`;
  return async (dispatch) => {
    // console.log("policy: ", policy);
    await axios.post(url, policy, {
      headers: getAuthHeaders(),
    });
    // console.log("response: ", response);
  };
};
