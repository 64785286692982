import axios from "axios";
import { getAuthHeaders, baseUrl } from "../helpers";

export const fetchBookingReviews = (page, perPage) => {
  let url = `${baseUrl}panel-api/admin/ratings/gets?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const deleteReview = (reviewid) => {
  let url = `${baseUrl}panel-api/admin/ratings/delete/${reviewid}`;
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
};
