import React from "react";
// reactstrap components
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Container,
  Col,
  FormGroup,
} from "reactstrap";
import { forgetPassword } from "../../services/AuthServices";
import {
  getProp,
  history,
  messageNotificationOptions,
  verifyEmail,
} from "../../helpers";

class Lock extends React.Component {
  state = { email: "", emailState: "", emailFocus: false };
  componentDidMount() {
    document.body.classList.toggle("lock-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("lock-page");
  }

  change = (event, stateName, type) => {
    switch (type) {
      case "email":
        if (verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;

      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  sendResetEmail = (e) => {
    e.preventDefault();
    const { email } = this.state;
    forgetPassword(email)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getCode = getProp(["data", "data", "code"]);
          const verificationCode = getCode(response);
          console.log(verificationCode);
          const options = messageNotificationOptions(
            "A verification code has been sent to your email. Please enter code to reset password.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
          setTimeout(() => {
            history.push(`/auth/verify-code?ph=${email}`);
          }, 2000);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };

  render() {
    const { emailState } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-lock card-white text-center">
                {/* <CardHeader>
                  <img alt="..." src={require("assets/img/emilyz.jpg")} />
                </CardHeader> */}
                <CardBody>
                  <CardTitle tag="h4">Forget Password</CardTitle>

                  <FormGroup className={`has-label ${emailState}`}>
                    <label>Email Address *</label>
                    <Input
                      placeholder="Email"
                      type="text"
                      onFocus={(e) => this.setState({ emailFocus: true })}
                      onBlur={(e) => this.setState({ emailFocus: false })}
                      onChange={(e) => this.change(e, "email", "email")}
                    />
                    {emailState === "has-danger" ? (
                      <label className="error">
                        Please enter a valid email address.
                      </label>
                    ) : null}
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round"
                    color="secondary"
                    href="#pablo"
                    size="lg"
                    onClick={(e) => {
                      history.goBack(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={emailState !== "has-success"}
                    className="btn-round"
                    color="primary"
                    href="#pablo"
                    size="lg"
                    onClick={(e) => {
                      this.sendResetEmail(e);
                    }}
                  >
                    Submit
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default Lock;
