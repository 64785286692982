import React, { Component } from "react";
import classNames from "classnames";
import { fetchBatteries } from "../../../services";
import { getProp, baseUrl, history } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { textSpanIntersectsWithPosition } from "typescript";

class BatteryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedUser: "",
      alert: null,
    };
  }

  componentDidMount() {
    this.getBatteries();
  }

  /**
   * @function getBatteries
   * @description Get batteries
   */

  getBatteries = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchBatteries(currentPage, perPage)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getBatteriesList = getProp(["data", "data", "carBatteries"]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const batteries = getBatteriesList(response);
          const modifiedBatteries = batteries.map((item) => {
            return {
              ...item,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to edit */}
                  <Button
                    onClick={() => {
                      this.editBattery(item);
                    }}
                    color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  {/* use this button to remove the data row */}
                  <Button
                    onClick={() => {
                      this.deletePopup(item);
                    }}
                    color="danger"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </div>
              ),
            };
          });
          this.setState({
            data: modifiedBatteries,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getBatteries();
    });
  };

  onClickAddBattery = (e) => {
    e.preventDefault();
    history.push("/admin/add-battery");
  };

  /**
   * @function editBattery
   * @param selected battery
   * @description edit a battery
   */

  editBattery = (battery) => {
    history.push(`/admin/edit-battery?id=${battery.id}`);
  };

  render() {
    const { loading, data, currentPage, totalPages, alert } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <div className="content">
          <Row>
            <Col md={8} className="ml-auto mr-auto">
              <h2 className="text-center">All Batteries</h2>
            </Col>

            <Col md={1.5} className="ml-auto mr-auto mb-auto">
              <Button
                color="primary"
                onClick={(e) => {
                  this.onClickAddBattery(e);
                }}
              >
                Add Battery
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        filterable: false,
                        Header: "Battery",
                        accessor: "logo",
                        Cell: (row) => {
                          if (row) {
                            return (
                              <div>
                                <img
                                  src={row.value.url}
                                  className="img-responsive"
                                  style={{ width: 50, height: 50 }}
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                      },
                      { filterable: false, Header: "Type", accessor: "type" },
                      { filterable: false, Header: "Model", accessor: "model" },
                      {
                        filterable: false,
                        Header: "Retail Price",
                        accessor: "retail_price",
                      },
                      {
                        filterable: false,
                        Header: "Actions",
                        accessor: "actions",
                        width: 120,
                        sortable: false,
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default BatteryList;
