/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
const icons = [
  { name: "icon-alert-circle-exc" },
  { name: "icon-align-center" },
  { name: "icon-align-left-2" },
  { name: "icon-app" },
  { name: "icon-atom" },
  { name: "icon-attach-87" },
  { name: "icon-badge" },
  { name: "icon-bag-16" },
  { name: "icon-bank" },
  { name: "icon-basket-simple" },
  { name: "icon-bell-55" },
  { name: "icon-bold" },
  { name: "icon-book-bookmark" },
  { name: "icon-double-right" },
  { name: "icon-bulb-63" },
  { name: "icon-bullet-list-67" },
  { name: "icon-bus-front-12" },
  { name: "icon-button-power" },
  { name: "icon-camera-18" },
  { name: "icon-calendar-60" },
  { name: "icon-caps-small" },
  { name: "icon-cart" },
  { name: "icon-chart-bar-32" },
  { name: "icon-chart-pie-36" },
  { name: "icon-chat-33" },
  { name: "icon-check-2" },
  { name: "icon-cloud-download-93" },
  { name: "icon-cloud-upload-94" },
  { name: "icon-coins" },
  { name: "icon-compass-05" },
  { name: "icon-controller" },
  { name: "icon-credit-card" },
  { name: "icon-delivery-fast" },
  { name: "icon-email-85" },
  { name: "icon-gift-2" },
  { name: "icon-globe-2" },
  { name: "icon-headphones" },
  { name: "icon-heart-2" },
  { name: "icon-html5" },
  { name: "icon-double-left" },
  { name: "icon-image-02" },
  { name: "icon-istanbul" },
  { name: "icon-key-25" },
  { name: "icon-laptop" },
  { name: "icon-light-3" },
  { name: "icon-link-72" },
  { name: "icon-lock-circle" },
  { name: "icon-map-big" },
  { name: "icon-minimal-down" },
  { name: "icon-minimal-left" },
  { name: "icon-minimal-right" },
  { name: "icon-minimal-up" },
  { name: "icon-mobile" },
  { name: "icon-molecule-40" },
  { name: "icon-money-coins" },
  { name: "icon-notes" },
  { name: "icon-palette" },
  { name: "icon-paper" },
  { name: "icon-pin" },
  { name: "icon-planet" },
  { name: "icon-puzzle-10" },
  { name: "icon-pencil" },
  { name: "icon-satisfied" },
  { name: "icon-scissors" },
  { name: "icon-send" },
  { name: "icon-settings-gear-63" },
  { name: "icon-settings" },
  { name: "icon-wifi" },
  { name: "icon-single-02" },
  { name: "icon-single-copy-04" },
  { name: "icon-sound-wave" },
  { name: "icon-spaceship" },
  { name: "icon-square-pin" },
  { name: "icon-support-17" },
  { name: "icon-tablet-2" },
  { name: "icon-tag" },
  { name: "icon-tap-02" },
  { name: "icon-tie-bow" },
  { name: "icon-time-alarm" },
  { name: "icon-trash-simple" },
  { name: "icon-trophy" },
  { name: "icon-tv-2" },
  { name: "icon-upload" },
  { name: "icon-user-run" },
  { name: "icon-vector" },
  { name: "icon-video-66" },
  { name: "icon-wallet-43" },
  { name: "icon-volume-98" },
  { name: "icon-watch-time" },
  { name: "icon-world" },
  { name: "icon-zoom-split" },
  { name: "icon-refresh-01" },
  { name: "icon-refresh-02" },
  { name: "icon-shape-star" },
  { name: "icon-components" },
  { name: "icon-triangle-right-17" },
  { name: "icon-button-pause" },
  { name: "icon-simple-remove" },
  { name: "icon-simple-add" },
  { name: "icon-simple-delete" }
];

export default icons;
