import React, { Component } from "react";
import classNames from "classnames";
import qs from "qs";
import { fetchPolicySlots, deletePolicySlot } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
import TagsInput from "react-tagsinput";
import { deleteWarningWithConfirmMessage } from "../../components/SweetAlert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { textSpanIntersectsWithPosition } from "typescript";

class PolicySlots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedSlot: "",
      alert: null,
      file: null,
      policyId: "",
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const policyId = params.id;
    if (policyId) {
      this.setState({ policyId, loading: true }, () => {
        this.getPolicySlots();
      });
    }
  }

  /**
   * @function getPolicySlots
   * @description Get policy slots
   */

  getPolicySlots = () => {
    const { policyId, currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchPolicySlots(policyId, currentPage, perPage)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getPolicySlots = getProp([
            "data",
            "data",
            "insuranceCompanyPolicyTimeSlots",
          ]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const policySlots = getPolicySlots(response);
          const modifiedPolicySlots = policySlots.map((item) => {
            return {
              ...item,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to edit */}
                  <Button
                    onClick={() => {
                      this.editSlot(item);
                    }}
                    color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  {/* use this button to remove the data row */}
                  <Button
                    onClick={() => {
                      this.deletePopup(item);
                    }}
                    color="danger"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </div>
              ),
            };
          });
          this.setState({
            data: modifiedPolicySlots,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  /**
   * @function editSlot
   * @param selected slot
   * @description edit a slot
   */

  editSlot = (slot) => {
    history.push(`/admin/edit-policy-slot?id=${slot.id}&edit=true`);
  };

  /**
   * @function deletePopup
   * @param slot
   * @description open confirmation popup to delete a slot
   */

  deletePopup = (slot) => {
    const alert = deleteWarningWithConfirmMessage(
      this.deleteSingleSlot,
      this.hideAlert,
      "Are you sure you want to delete?",
      "Yes, delete it!"
    );
    this.setState({ selectedSlot: slot, alert });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  /**
   * @function deleteSingleSlot
   * @param selectedSlot
   * @description delete a slot
   */

  deleteSingleSlot = () => {
    const { selectedSlot } = this.state;
    this.setState({ alert: null });
    deletePolicySlot(selectedSlot.id)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          this.getPolicySlots();
          const options = messageNotificationOptions(
            "Slot deleted successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getPolicySlots();
    });
  };

  /**
   * @function renderDistances
   * @param distances
   * @description show distances of a policy in tags
   */

  renderDistances = (distances) => {
    const tagNames = distances.map((item) => {
      return (
        item.start_distance +
        " KM - " +
        item.end_distance +
        " KM (" +
        item.price +
        " RM Cost Per KM)"
      );
    });
    return (
      <TagsInput
        disabled={true}
        maxTags={0}
        tagProps={{ className: "react-tagsinput-tag info" }}
        value={tagNames}
        inputProps={{
          placeholder: "",
        }}
      />
    );
  };

  onClickAddSlot = (e) => {
    e.preventDefault();
    const { policyId } = this.state;
    history.push(`/admin/add-policy-slot?policyId=${policyId}`);
  };

  render() {
    const { loading, data, currentPage, totalPages, alert } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <input
          type="file"
          onChange={this.handleFileChange}
          ref="fileInput"
          hidden
        />

        <div className="content">
          <Row>
            <Col md={8} className="ml-auto mr-auto">
              <h2 className="text-center">Policy Slots</h2>
            </Col>

            <Col md={1.5} className="ml-auto mr-auto mb-auto">
              <Button
                color="primary"
                onClick={(e) => {
                  this.onClickAddSlot(e);
                }}
              >
                Add Slot
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Slot",
                        accessor: "start_time",
                        Cell: (row) => {
                          if (row) {
                            return (
                              row.original.start_time +
                              " - " +
                              row.original.end_time
                            );
                          }
                        },
                        width: 200,
                      },
                      {
                        Header: "Distances",
                        accessor: "distances",
                        Cell: (row) => {
                          if (row) {
                            return this.renderDistances(row.value);
                          } else {
                            return null;
                          }
                        },
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        width: 120,
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PolicySlots;
