import axios from "axios";
import { baseUrl, getAuthHeaders } from "../../helpers";
import { ADSMODULE } from "../types";

const successfullCreateAdsPayload = (payload) => {
  return {
    type: ADSMODULE.SUCCESSFULLY_CREATED_ADS,
    payload,
  };
};

const successMessagePayload = (payload) => {
  return {
    type: ADSMODULE.SUCCESS_MESSAGE,
    payload,
  };
};

const loadingWhileFetchingPayload = (payload) => {
  return {
    type: ADSMODULE.LOADING_WHILE_FETCHING,
    payload,
  };
};

const fetchAdsModulPayload = (payload) => {
  return {
    type: ADSMODULE.FETCH_ADS_MODULE,
    payload,
  };
};
export const fetchAdsModuleAction = ({ page, perPage }) => {
  const url = `${baseUrl}panel-api/admin/ads-module/gets?page=${page}&perPage=${perPage}&search`;
  return async (dispatch) => {
    dispatch(loadingWhileFetchingPayload(true));
    try {
      const respone = await axios.get(url, {
        headers: getAuthHeaders(),
      });

      if (respone.status === 200 && respone.data.success === 1) {
        const adsModuleData = respone.data.data.carBatteries;
        dispatch(fetchAdsModulPayload(adsModuleData));
        dispatch(loadingWhileFetchingPayload(false));
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
};

const adsModalPayload = (payload) => {
  return {
    type: ADSMODULE.ADS_MODAL,
    payload,
  };
};
export const adsModalAction = (modal) => {
  return (dispatch) => dispatch(adsModalPayload(modal));
};

const createAdsPayload = (payload) => {
  return {
    type: ADSMODULE.CREATE_ADS,
    payload,
  };
};
export const createAdsAction = ({ image, url, screen_type, priority }) => {
  const createUrl = `${baseUrl}panel-api/admin/ads-module/add`;
  const formData = new FormData();
  return async (dispatch) => {
    formData.append("image", image);
    formData.append("url", url);
    formData.append("screen_type", screen_type);
    formData.append("priority", priority);

    try {
      const response = await axios.post(createUrl, formData, {
        headers: getAuthHeaders(),
      });

      const message = {
        success: response.data.success,
        message: response.data.message,
      };

      if (response.status === 200 && response.data.success === 1) {
        const data = response.data.data.ad;

        //push array(new data to show in table)
        dispatch(createAdsPayload(data));

        //success message
        dispatch(successMessagePayload(message));

        //close the modal
        dispatch(adsModalAction(false));

        //show the Toast (success message)
        dispatch(successfullCreateAdsPayload(true));

        //rest the success created add to false
        setTimeout(() => {
          dispatch(successfullCreateAdsPayload(false));
        }, 5000);

        //set the update obj into defaule value
        dispatch(
          updateAdsModulePayload({
            update: false,
            id: 0,
            image: null,
            imageToUpdate: null,
            url: "",
          })
        );
      } else {
        //error message
        dispatch(successMessagePayload(message));

        //show the Toast (success message)
        dispatch(successfullCreateAdsPayload(true));

        //rest the success created add to false
        setTimeout(() => {
          dispatch(successfullCreateAdsPayload(false));
        }, 5000);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
};

const deleteAdsModulePayload = (payload) => {
  return {
    type: ADSMODULE.DELETE_ADS_MODULE,
    payload,
  };
};

export const deleteAdsModuleAction = (id) => {
  const url = `${baseUrl}panel-api/admin/ads-module/delete/${id}`;
  return async (dispatch) => {
    try {
      await axios.post(
        url,
        {},
        {
          headers: getAuthHeaders(),
        }
      );
      dispatch(deleteAdsModulePayload(id));
    } catch (error) {
      console.log("Error: ", error);
    }
  };
};

const updateAdsModulePayload = (payload) => {
  return {
    type: ADSMODULE.UPDATE_ADS_MODULE,
    payload,
  };
};

export const updateAdsModuleAction = (data) => {
  return (dispatch) => {
    //ads to be updated
    dispatch(updateAdsModulePayload(data));
    //show the modal
    dispatch(adsModalAction(true));
  };
};

const confirmUpdateAdsModulePayload = (payload) => {
  return {
    type: ADSMODULE.CONFIRM_UPDATE_ADS_MODULE,
    payload,
  };
};
export const confirmUpdateAdsModuleAction = ({
  id,
  image,
  imageToUpdate,
  url,
}) => {
  const updateUrl = `${baseUrl}panel-api/admin/ads-module/edit/${id}`;
  const formData = new FormData();
  return async (dispatch) => {
    if (imageToUpdate === null) {
      formData.append("url", url);
    } else if (imageToUpdate === 1) {
      formData.append("url", url);
      formData.append("image", image);
    }
    try {
      const response = await axios.post(updateUrl, formData, {
        headers: getAuthHeaders(),
      });

      const message = {
        success: response.data.success,
        message: response.data.message,
      };
      if (response.status === 200 && response.data.success === 1) {
        const data = response.data.data.ad;
        dispatch(confirmUpdateAdsModulePayload(data));
        //close the modal
        dispatch(adsModalAction(false));

        //show the Toast (success message)
        dispatch(successfullCreateAdsPayload(true));

        //rest the success created add to false
        setTimeout(() => {
          dispatch(successfullCreateAdsPayload(false));
        }, 5000);

        //set the update obj into defaule value
        dispatch(
          updateAdsModulePayload({
            update: false,
            id: 0,
            image: null,
            imageToUpdate: null,
            url: "",
          })
        );

        //success message
        dispatch(successMessagePayload(message));
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
};
