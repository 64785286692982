import React, { Component } from "react";
import classNames from "classnames";
import { fetchBookings } from "../../../services";
import { getProp } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import "react-table/react-table.css";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { textSpanIntersectsWithPosition } from "typescript";
import moment from "moment";

class BookingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedUser: "",
      alert: null,
      filtered: [],
      search: "",
    };
  }

  componentDidMount() {
    this.getBookings();
  }

  getStatustext = (jobStatus) => {
    switch (jobStatus) {
      case 0:
        return "Cancelled";
      case 1 || 2:
        return "Searching Provider";
      case 3:
        return "Provider Assigned";
      case 4:
        return "Payment Complete";
      case 5:
        return "Navigating";
      case 6:
        return "Reached Location";
      case 7:
        return "Job Complete";
      case 8:
        return "Finished";
      default:
        return "In-Progress";
    }
  };

  /**
   * @function getBookings
   * @description Get bookings
   */

  getBookings = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchBookings(currentPage, perPage)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getBookingList = getProp(["data", "data", "bookings"]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const bookings = getBookingList(response);
          const modifiedBookings = bookings.map((item) => {
            return {
              ...item,
            };
          });
          this.setState({
            data: modifiedBookings,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getBookings();
    });
  };

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
  };
  handleSearch = (event) => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render() {
    const { loading, currentPage, totalPages, alert, filtered } = this.state;

    var updatedList = this.state.data,
      search = this.state.search.toLowerCase();
    updatedList = updatedList.filter((item) => {
      return (
        item.provider_type.name.toLowerCase().match(search) ||
        item.user.name.toLowerCase().match(search)
      );
    });
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <div className="content">
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">All Bookings</h2>
          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <label style={searchLabel}>
                    Search:
                    <input
                      type="text"
                      style={searchBooking}
                      onChange={this.handleSearch}
                      placeholder="Seach by booking type, user, provider"
                    />
                  </label>
                  <ReactTable
                    data={updatedList}
                    filterable
                    filtered={filtered}
                    onFilteredChange={(filtered, column, value) => {
                      this.onFilteredChangeCustom(
                        value,
                        column.id || column.accessor
                      );
                    }}
                    defaultFilterMethod={(filter, row, column) => {
                      const id = filter.pivotId || filter.id;
                      if (typeof filter.value === "object") {
                        return row[id] !== undefined
                          ? filter.value.indexOf(row[id]) > -1
                          : true;
                      } else {
                        return row[id] !== undefined
                          ? String(row[id]).indexOf(filter.value) > -1
                          : true;
                      }
                    }}
                    resizable={false}
                    columns={[
                      {
                        Header: "Id",
                        accessor: "id",
                        filterable: false,
                      },
                      {
                        filterable: false,
                        Header: "Booking Date",
                        accessor: "created_at",
                        Cell: (row) => {
                          if (row) {
                            return (
                              <div>
                                {moment(row.value).format("DD-MM-YYYY hh:mm")}
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                      },
                      {
                        Header: "Booking Type",
                        accessor: "provider_type",
                        filterable: false,
                        Cell: (row) => {
                          if (row) {
                            return <div>{row.value.name}</div>;
                          } else {
                            return null;
                          }
                        },
                      },
                      {
                        Header: "User",
                        accessor: "user",
                        Cell: (row) => {
                          if (row && row.value) {
                            return (
                              <div>
                                <div>{row.value.name}</div>
                                <div>{row.value.phone}</div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                        filterable: false,
                      },
                      {
                        Header: "Provider",
                        accessor: "provider_bookings",
                        Cell: (row) => {
                          if (!row.value) return null;
                          const filtered = row.value.find((item) => {
                            return item.status !== 0;
                          });
                          if (filtered) {
                            return (
                              <div>
                                <div>{filtered.provider.name}</div>
                                <div>{filtered.provider.phone}</div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                        filterable: false,
                      },
                      {
                        filterable: false,
                        Header: "Car Id",
                        accessor: "car_id",
                      },
                      {
                        filterable: false,
                        Header: "Distance",
                        accessor: "provider_bookings",
                        Cell: (row) => {
                          if (!row.value) return null;
                          const filtered = row.value.find((item) => {
                            return item.status !== 0;
                          });
                          if (filtered) {
                            return (
                              <div>
                                <div>
                                  {parseFloat(filtered.distance).toFixed(2)}
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                      },
                      {
                        filterable: false,
                        Header: "Valid Distance",
                        accessor: "provider_bookings",
                        Cell: (row) => {
                          if (!row.value) return null;
                          const filtered = row.value.find((item) => {
                            return item.status !== 0;
                          });
                          if (filtered) {
                            return (
                              <div>
                                <div>
                                  {parseFloat(filtered.valid_distance).toFixed(
                                    2
                                  )}
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                      },

                      {
                        filterable: false,
                        Header: "CalCost",
                        accessor: "calculated_cost",
                        Cell: (row) => {
                          if (row) {
                            return <div>RM{row.value}</div>;
                          } else {
                            return null;
                          }
                        },
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        Cell: (row) => {
                          if (row) {
                            return <div>{this.getStatustext(row.value)}</div>;
                          } else {
                            return null;
                          }
                        },
                        filterable: false,
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const searchBooking = {
  border: "1px solid #ced4da",
  borderColor: "#2b3553",
  backgroundColor: "transparent",
  borderRadius: "0.4285rem",
  color: "rgba(255, 255, 255, 0.8)",
  lineHeight: "normal",
  fontSize: "0.8571em",
  transition:
    "color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
  boxShadow: "none",
  marginLeft: "0.5em",
  display: "inline-block",
  width: "auto",
  height: "calc(1.8125rem + 2px)",
  padding: "0.25rem 0.5rem",
};
const searchLabel = {
  fontSize: "12px",
  fontWeight: "700",
  color: "rgba(255, 255, 255, 0.7)",
};
export default BookingList;
