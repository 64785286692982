import axios from "axios";
import { getAuthHeaders, baseUrl } from "../helpers";

export const postLogin = (username, password) => {
  const signInObj = {
    username,
    password,
  };
  return axios.post(`${baseUrl}panel-api/admin/login/do`, signInObj);
};

export const forgetPassword = (username) => {
  const signInObj = {
    username,
  };
  return axios.post(
    `${baseUrl}panel-api/admin/login/forgot-password`,
    signInObj
  );
};

export const resetPassword = (params) => {
  return axios.post(`${baseUrl}panel-api/admin/login/reset-password`, params);
};

export const verifyCode = (params) => {
  return axios.post(
    `${baseUrl}panel-api/admin/login/reset-password-code-verification`,
    params
  );
};
