import React, { Component } from "react";
import classNames from "classnames";
import qs from "qs";
import {
  deleteInsurancePolicy,
  fetchCompanyInsurancePolicies,
} from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
import { deleteWarningWithConfirmMessage } from "../../components/SweetAlert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { textSpanIntersectsWithPosition } from "typescript";

const MileageeEnums = {
  0: "Unlimited",
  1: "Limited",
  2: "Accumulated",
};

const TripsEnums = {
  0: "Unlimited",
  1: "Limited",
};

const MileageCell = ({ value }) => {
  return MileageeEnums[value];
};

const TripsCell = ({ value }) => {
  return TripsEnums[value];
};

class PoliciesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policiesData: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedPolicy: "",
      alert: null,
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const companyId = params.id;
    if (companyId) {
      this.setState({ companyId, loading: true }, () => {
        this.getCompanyInsurancePolicies();
      });
    }
  }

  /**
   * @function getCompanyInsurancePolicies
   * @description Get insurance companies
   */

  getCompanyInsurancePolicies = () => {
    const { currentPage, perPage, companyId } = this.state;
    this.setState({ loading: true });
    fetchCompanyInsurancePolicies(companyId, currentPage, perPage)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getInsurancePoliciesList = getProp([
            "data",
            "data",
            "insuranceCompanyPolicies",
          ]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const insurancePolicies = getInsurancePoliciesList(response);
          const modifiedInsurancePolicies = insurancePolicies.map((item) => {
            return {
              ...item,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to show slots */}
                  <Button
                    onClick={() => {
                      this.viewPolicySlots(item);
                    }}
                    color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-single-copy-04" />
                  </Button>
                  {/* use this button to edit */}
                  <Button
                    onClick={() => {
                      this.editPolicy(item);
                    }}
                    color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  {/* use this button to remove the data row */}
                  <Button
                    onClick={() => {
                      this.deletePopup(item);
                    }}
                    color="danger"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </div>
              ),
            };
          });
          this.setState({
            policiesData: modifiedInsurancePolicies,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  editPolicy = (policy) => {
    history.push(`/admin/edit-policy?edit=true&id=${policy.id}`);
  };

  viewPolicySlots = (policy) => {
    history.push(`/admin/view-policy-slots?id=${policy.id}`);
  };

  deletePopup = (policy) => {
    const alert = deleteWarningWithConfirmMessage(
      this.deleteSinglePolicy,
      this.hideAlert,
      "Are you sure you want to delete?",
      "Yes, delete it!"
    );
    this.setState({ selectedPolicy: policy, alert });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  deleteSinglePolicy = () => {
    const { selectedPolicy } = this.state;
    this.setState({ alert: null });
    deleteInsurancePolicy(selectedPolicy.id)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          this.getCompanyInsurancePolicies();
          const options = messageNotificationOptions(
            "Policy deleted successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getCompanyInsurancePolicies();
    });
  };

  onClickAddPolicy = (e) => {
    e.preventDefault();
    const { companyId } = this.state;
    history.push(`/admin/add-policy?companyId=${companyId}`);
  };

  render() {
    const {
      loading,
      policiesData,
      currentPage,
      totalPages,
      alert,
    } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <div className="content">
          <Row>
            <Col md={8} className="ml-auto mr-auto">
              <h2 className="text-center">All Insurance Company Policies</h2>
            </Col>

            <Col md={1.5} className="ml-auto mr-auto mb-auto">
              <Button
                color="primary"
                onClick={(e) => {
                  this.onClickAddPolicy(e);
                }}
              >
                Add Insurance Policy
              </Button>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={policiesData}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "Number Start",
                        accessor: "no_start",
                      },
                      {
                        Header: "Number End",
                        accessor: "no_end",
                      },
                      {
                        Header: "Mileage",
                        accessor: "pricing_policy.mileage",
                        Cell: MileageCell,
                      },
                      {
                        Header: "Mileage Value",
                        accessor: "pricing_policy.mileage_value",
                      },
                      {
                        Header: "Trips",
                        accessor: "pricing_policy.trips",
                        Cell: TripsCell,
                      },
                      {
                        Header: "Trips Value",
                        accessor: "pricing_policy.trips_value",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default PoliciesList;
