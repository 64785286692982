import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

const Provider = ({ location }) => {
  const { providerData } = location.state;
  let providerTypes =
    providerData.info && providerData.info.provider_type_name
      ? providerData.info.provider_type_name.map((element) => element.name)
      : [];

  console.log(providerTypes, "providerTypes==>");
  console.log(providerData, "providerData==>");
  return (
    <>
      <div className="rna-container">
        {/* <NotificationAlert ref="notificationAlert" /> */}
      </div>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">{"Provider"}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <img
                      src={require("../../../assets/img/default-avatar.png")}
                      className="img-responsive"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </Col>
                  <Col md="8">
                    <Row>
                      <Col md="4">
                        <h5>Name:</h5>
                      </Col>
                      <Col md="8">
                        <h6>{providerData.name}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <h5>Email:</h5>
                      </Col>
                      <Col md="8">
                        <h6>
                          <a href={`mailto:${providerData.email}`}>
                            {providerData.email}
                          </a>
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <h5>Phone:</h5>
                      </Col>
                      <Col md="8">
                        <h6>{providerData.phone}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <h5>Provider Types:</h5>
                      </Col>
                      <Col md="8">
                        <h6>{providerTypes.join(", ")}</h6>
                      </Col>
                    </Row>
                    {providerData.info && providerData.info.license_number && (
                      <Row>
                        <Col md="4">
                          <h5>License Number:</h5>
                        </Col>
                        <Col md="8">
                          <h6>{providerData.info.license_number}</h6>
                        </Col>
                      </Row>
                    )}
                    {providerData.info && providerData.info.NRIC_name && (
                      <Row>
                        <Col md="4">
                          <h5>NRIC Name:</h5>
                        </Col>
                        <Col md="8">
                          <h6>{providerData.info.NRIC_name}</h6>
                        </Col>
                      </Row>
                    )}
                    {providerData.info && providerData.info.NRIC_number && (
                      <Row>
                        <Col md="4">
                          <h5>NRIC Number:</h5>
                        </Col>
                        <Col md="8">
                          <h6>{providerData.info.NRIC_number}</h6>
                        </Col>
                      </Row>
                    )}
                    {providerData.info &&
                      providerData.info.vehicle_registration_number && (
                        <Row>
                          <Col md="4">
                            <h5>Vehicle Registration Number:</h5>
                          </Col>
                          <Col md="8">
                            <h6>
                              {providerData.info.vehicle_registration_number}
                            </h6>
                          </Col>
                        </Row>
                      )}
                    {providerData.info &&
                      providerData.info.type_of_vehicle_brand && (
                        <Row>
                          <Col md="4">
                            <h5>Type of Vehicle Brand:</h5>
                          </Col>
                          <Col md="8">
                            <h6>{providerData.info.type_of_vehicle_brand}</h6>
                          </Col>
                        </Row>
                      )}
                    {providerData.info &&
                      providerData.info.type_of_vehicle_model && (
                        <Row>
                          <Col md="4">
                            <h5>Type of Vehicle Model:</h5>
                          </Col>
                          <Col md="8">
                            <h6>{providerData.info.type_of_vehicle_model}</h6>
                          </Col>
                        </Row>
                      )}
                    {providerData.info &&
                      providerData.info.vehicle_insurance_policy_number && (
                        <Row>
                          <Col md="4">
                            <h5>Vehicle Insurance Policy Number:</h5>
                          </Col>
                          <Col md="8">
                            <h6>
                              {
                                providerData.info
                                  .vehicle_insurance_policy_number
                              }
                            </h6>
                          </Col>
                        </Row>
                      )}
                    {providerData.info &&
                      providerData.info.vehicle_insurance_expiry && (
                        <Row>
                          <Col md="4">
                            <h5>Vehicle Insurance Expiry:</h5>
                          </Col>
                          <Col md="8">
                            <h6>
                              {providerData.info.vehicle_insurance_expiry}
                            </h6>
                          </Col>
                        </Row>
                      )}

                    <Row>
                      {providerData.info &&
                        providerData.info.NRIC_document &&
                        providerData.info.NRIC_document.url && (
                          <Col md="3">
                            <h5>NRIC Document</h5>
                            <a
                              href={providerData.info.NRIC_document.url}
                              target="_new"
                            >
                              <img
                                src={providerData.info.NRIC_document.url}
                                style={{ width: 100, height: 100 }}
                              />
                            </a>
                          </Col>
                        )}
                      {providerData.info &&
                        providerData.info
                          .insurance_company_certification_letter_document &&
                        providerData.info
                          .insurance_company_certification_letter_document
                          .url && (
                          <Col md="3">
                            <h5>
                              Insurance Company Certification Letter Document
                            </h5>
                            <a
                              href={
                                providerData.info
                                  .insurance_company_certification_letter_document
                                  .url
                              }
                              target="_new"
                            >
                              <img
                                src={
                                  providerData.info
                                    .insurance_company_certification_letter_document
                                    .url
                                }
                                style={{ width: 100, height: 100 }}
                              />
                            </a>
                          </Col>
                        )}
                      {providerData.info &&
                        providerData.info
                          .car_auto_certificate_authorized_workshop_PIAM_document &&
                        providerData.info
                          .car_auto_certificate_authorized_workshop_PIAM_document
                          .url && (
                          <Col md="3">
                            <h5>
                              Car Auto Certificate Authorized Workshop PIAM
                              Document
                            </h5>
                            <a
                              href={
                                providerData.info
                                  .car_auto_certificate_authorized_workshop_PIAM_document
                                  .url
                              }
                              target="_new"
                            >
                              <img
                                src={
                                  providerData.info
                                    .car_auto_certificate_authorized_workshop_PIAM_document
                                    .url
                                }
                                style={{ width: 100, height: 100 }}
                              />
                            </a>
                          </Col>
                        )}
                      {providerData.info &&
                        providerData.info.license_document &&
                        providerData.info.license_document.url && (
                          <Col md="3">
                            <h5>License Document</h5>
                            <a
                              href={providerData.info.license_document.url}
                              target="_new"
                            >
                              <img
                                src={providerData.info.license_document.url}
                                style={{ width: 100, height: 100 }}
                              />
                            </a>
                          </Col>
                        )}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Provider;
