import axios from "axios";
import { getAuthHeaders, baseUrl } from "../helpers";

export const getTransactionFee = () => {
  let url = `${baseUrl}panel-api/admin/others/get-transaction-fee`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const setTransactionFies = (data) => {
  console.log("Data: ", data);
  let url = `${baseUrl}panel-api/admin/others/set-transaction-fee`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};
