import React from "react";
import logo from "./logo.svg";
import "./App.css";

import AuthLayout from "../src/layouts/Auth/Auth.js";
import AdminLayout from "../src/layouts/Admin/Admin.js";
// import RTLLayout from "../src/layouts/RTL/RTL.js";
import { history } from "./helpers";
// import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import CheckAuth from "./CheckAuth";

function App() {
  return (
    <>
      <Router history={history}>
        <script crossOrigin="true" src="*"></script>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          {/* <Route path="/rtl" render={(props) => <RTLLayout {...props} />} /> */}
          <Route from="/" component={CheckAuth} />
        </Switch>
      </Router>
      ,
    </>
  );
}

export default App;
