import React, { Component } from "react";
import classNames from "classnames";
import { deleteVehicleType, fetchCarTypes } from "../../../services";
import { getProp, history, messageNotificationOptions } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { deleteWarningWithConfirmMessage } from "../../components/SweetAlert";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { textSpanIntersectsWithPosition } from "typescript";

class VehicleTypesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedVehicleType: "",
      alert: null,
    };
  }

  componentDidMount() {
    this.getCarTypes();
  }

  /**
   * @function getCarTypes
   * @description Get vehicle types
   */

  getCarTypes = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchCarTypes(currentPage, perPage)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getProviderTypeList = getProp(["data", "data", "userCarTypes"]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const userCarTypes = getProviderTypeList(response);
          const modifiedUserCarTypes = userCarTypes.map((item) => {
            return {
              ...item,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to edit */}
                  <Button
                    onClick={() => {
                      this.editVehicleType(item);
                    }}
                    color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  {/* use this button to remove the data row */}
                  <Button
                    onClick={() => {
                      this.deletePopup(item);
                    }}
                    color="danger"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </div>
              ),
            };
          });
          this.setState({
            data: modifiedUserCarTypes,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getCarTypes();
    });
  };

  onClickAddType = (e) => {
    e.preventDefault();
    history.push("/admin/add-vehicle-type");
  };

  /**
   * @function editVehicleType
   * @param selected vehicle type
   * @description edit a vehcile type
   */

  editVehicleType = (vehicleType) => {
    history.push(`/admin/edit-vehicle-type?id=${vehicleType.id}`);
  };

  /**
   * @function deletePopup
   * @param selected vehicle type
   * @description open confirmation popup to delete a vehicle type
   */

  deletePopup = (vehicleType) => {
    const alert = deleteWarningWithConfirmMessage(
      this.deleteSingleVehicleType,
      this.hideAlert,
      "Are you sure you want to delete?",
      "Yes, delete it!"
    );
    this.setState({ selectedVehicleType: vehicleType, alert });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  /**
   * @function deleteSingleVehicleType
   * @description delete a providerType
   */

  deleteSingleVehicleType = () => {
    const { selectedVehicleType } = this.state;
    this.setState({ alert: null });
    deleteVehicleType(selectedVehicleType.id)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          this.getCarTypes();
          const options = messageNotificationOptions(
            "Vehicle Type deleted successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };

  render() {
    const { loading, data, currentPage, totalPages, alert } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <div className="content">
          <Row>
            <Col md={8} className="ml-auto mr-auto">
              <h2 className="text-center">All Vehicle Types</h2>
            </Col>

            <Col md={1.5} className="ml-auto mr-auto mb-auto">
              <Button
                color="primary"
                onClick={(e) => {
                  this.onClickAddType(e);
                }}
              >
                Add Type
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        filterable: false,
                        Header: "Image",
                        accessor: "image",
                        Cell: (row) => {
                          if (row.value) {
                            return (
                              <div>
                                <img
                                  src={row.value.url}
                                  className="img-responsive"
                                  style={{ width: 50, height: 50 }}
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                      },
                      {
                        filterable: false,
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        width: 120,
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default VehicleTypesList;
