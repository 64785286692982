import {
  GET_STATES,
  NOTIFICATION_LIST,
  CREATE_NOTIFICATION,
  MODAL_TOGGLE,
  UPDATE_NOTIFICATION,
  DATA_TO_BE_UPDATE,
  UPDATED_NOTIFICATION_DATA,
} from "../types";

const initState = {
  getState: [],
  notificationList: [],
  modoToggle: false,
  updateNotification: false,
  dataTobeUpdate: {},
};

const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_STATES:
      return {
        ...state,
        getState: (state.getState = action.payload),
      };

    case NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: (state.notificationList = action.payload),
      };
    case MODAL_TOGGLE:
      return {
        ...state,
        modoToggle: (state.modoToggle = action.payload),
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        updateNotification: (state.updateNotification = action.payload),
      };
    case DATA_TO_BE_UPDATE:
      return {
        ...state,
        dataTobeUpdate: (state.dataTobeUpdate = action.payload),
      };
    case UPDATED_NOTIFICATION_DATA:
      break;
    default:
      return state;
  }
};
export default notificationReducer;
