/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
// import { Router, Route, Switch, Redirect } from "react-router-dom";
import { InterceptorService } from "./services/interceptor";

// import AuthLayout from "../src/layouts/Auth/Auth.js";
// import AdminLayout from "../src/layouts/Admin/Admin.js";
// import RTLLayout from "../src/layouts/RTL/RTL.js";
// import { history } from "./helpers";

import "../src/assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "../src/assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "../src/assets/demo/demo.css";

//plugins
import "./plugins/sweetAlert";
// import CheckAuth from "./CheckAuth";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";

InterceptorService();
ReactDOM.render(
  // <Router history={history}>
  //   <script crossOrigin="true" src="*"></script>
  //   <Switch>
  //     <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
  //     <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
  //     {/* <Route path="/rtl" render={(props) => <RTLLayout {...props} />} /> */}
  //     <Route from="/" component={CheckAuth} />
  //   </Switch>
  // </Router>,
  <Provider store={store}>
    <App />
  </Provider>,

  document.getElementById("root")
);
