import React, { Component } from "react";
import classNames from "classnames";
import { fetchCars, deleteCar, uploadxlsFile } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
import TagsInput from "react-tagsinput";
import { deleteWarningWithConfirmMessage } from "../../components/SweetAlert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { textSpanIntersectsWithPosition } from "typescript";

class CarsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedCar: "",
      alert: null,
      file: null,
    };
  }

  componentDidMount() {
    this.getCars();
  }

  /**
   * @function getUsers
   * @description Get users
   */

  getCars = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchCars(currentPage, perPage)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getCarList = getProp(["data", "data", "carBrands"]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const cars = getCarList(response);
          const modifiedCars = cars.map((item) => {
            return {
              ...item,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to edit */}
                  <Button
                    onClick={() => {
                      this.editCar(item);
                    }}
                    color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  {/* use this button to remove the data row */}
                  <Button
                    onClick={() => {
                      this.deletePopup(item);
                    }}
                    color="danger"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </div>
              ),
            };
          });
          this.setState({
            data: modifiedCars,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  /**
   * @function editCar
   * @param selected car
   * @description edit a car
   */

  editCar = (car) => {
    history.push(`/admin/edit-car?id=${car.id}`);
  };

  /**
   * @function deletePopup
   * @param selected car
   * @description open confirmation popup to delete a car
   */

  deletePopup = (car) => {
    const alert = deleteWarningWithConfirmMessage(
      this.deleteSingleCar,
      this.hideAlert,
      "Are you sure you want to delete?",
      "Yes, delete it!"
    );
    this.setState({ selectedCar: car, alert });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  /**
   * @function deleteSingleCar
   * @param selected car
   * @description delete a car
   */

  deleteSingleCar = () => {
    const { selectedCar } = this.state;
    this.setState({ alert: null });
    deleteCar(selectedCar.id)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          this.getCars();
          const options = messageNotificationOptions(
            "Car deleted successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getCars();
    });
  };

  /**
   * @function renderModels
   * @param models
   * @description show models of a car in tags
   */

  renderModels = (models) => {
    const modelNames = models.map((item) => {
      return item.name;
    });
    return (
      <TagsInput
        disabled={true}
        maxTags={0}
        tagProps={{ className: "react-tagsinput-tag info" }}
        value={modelNames}
        inputProps={{
          placeholder: "",
        }}
      />
    );
  };

  onClickAddCar = (e) => {
    e.preventDefault();
    history.push("/admin/add-car");
  };

  onClickImportCar = (e) => {
    e.preventDefault();
    this.refs.fileInput.click();
  };

  /**
   * @function handleFileChange
   * @param e
   * @description upload selected xls file to upload
   */

  handleFileChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
      });
      uploadxlsFile(file).then(
        (response) => {
          const getSuccess = getProp(["data", "success"]);
          if (getSuccess(response) === 1) {
            this.getCars();
            const options = messageNotificationOptions(
              "Cars imported successfully.",
              "success"
            );
            this.refs.notificationAlert.notificationAlert(options);
          } else {
            const getErrorMessage = getProp(["data", "message"]);
            const options = messageNotificationOptions(
              getErrorMessage(response),
              "danger"
            );
            this.refs.notificationAlert.notificationAlert(options);
          }
        },
        (error) => {
          const options = messageNotificationOptions(
            "There is some server error. Please try again.",
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      );
    };
    reader.readAsDataURL(file);
  };

  render() {
    const { loading, data, currentPage, totalPages, alert } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <input
          type="file"
          onChange={this.handleFileChange}
          ref="fileInput"
          hidden
        />

        <div className="content">
          <Row>
            <Col md={8} className="ml-auto mr-auto">
              <h2 className="text-center">All Cars</h2>
            </Col>

            <Col md={1.5} className="ml-auto mr-auto mb-auto">
              <Button
                color="primary"
                onClick={(e) => {
                  this.onClickImportCar(e);
                }}
              >
                Import
              </Button>
              <Button
                color="primary"
                onClick={(e) => {
                  this.onClickAddCar(e);
                }}
              >
                Add Car
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        filterable: false,
                        Header: "Name",
                        accessor: "name",
                        width: 200,
                      },
                      {
                        filterable: false,
                        Header: "Models",
                        accessor: "models",
                        Cell: (row) => {
                          if (row) {
                            return this.renderModels(row.value);
                          } else {
                            return null;
                          }
                        },
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        width: 120,
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default CarsList;
