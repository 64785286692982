import React, { Component } from "react";
import classNames from "classnames";
import { fetchUsers, deleteUser } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
import { deleteWarningWithConfirmMessage } from "../../components/SweetAlert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { textSpanIntersectsWithPosition } from "typescript";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedUser: "",
      alert: null,
      search: "",
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  /**
   * @function getUsers
   * @description Get users
   */

  getUsers = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchUsers(currentPage, perPage)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getUserList = getProp(["data", "data", "users"]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const users = getUserList(response);
          const modifiedUsers = users.map((item) => {
            return {
              ...item,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to edit */}
                  <Button
                    onClick={() => {
                      this.editUser(item);
                    }}
                    color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  {/* use this button to remove the data row */}
                  <Button
                    onClick={() => {
                      this.deletePopup(item);
                    }}
                    color="danger"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </div>
              ),
            };
          });
          this.setState({
            data: modifiedUsers,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  editUser = (user) => {
    history.push(`/admin/edit-user?id=${user.id}`);
  };

  deletePopup = (user) => {
    const alert = deleteWarningWithConfirmMessage(
      this.deleteSingleUser,
      this.hideAlert,
      "Are you sure you want to delete?",
      "Yes, delete it!"
    );
    this.setState({ selectedUser: user, alert });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  deleteSingleUser = () => {
    const { selectedUser } = this.state;
    this.setState({ alert: null });
    deleteUser(selectedUser.id)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          this.getUsers();
          const options = messageNotificationOptions(
            "User deleted successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getUsers();
    });
  };
  handleSearch = (event) => {
    this.setState({ search: event.target.value.toLowerCase() });
  };
  render() {
    const { loading, currentPage, totalPages, alert } = this.state;

    var updatedList = this.state.data,
      search = this.state.search.toLowerCase();
    updatedList = updatedList.filter((item) => {
      return (
        item.name.toLowerCase().match(search) ||
        item.email.toLowerCase().match(search)
      );
    });

    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <div className="content">
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">All Users</h2>
          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <label style={searchLabel}>
                    Search:
                    <input
                      type="text"
                      value={this.state.value}
                      style={seach}
                      onChange={this.handleSearch}
                      placeholder="Seach by name, email"
                    />
                  </label>
                  <ReactTable
                    data={updatedList}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                        filterable: false,
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                        filterable: false,
                      },
                      {
                        Header: "Phone",
                        accessor: "phone",
                        filterable: false,
                      },
                      {
                        Header: "Verified",
                        accessor: "is_phone_verified",
                        Cell: (row) => {
                          if (row) {
                            return (
                              <div className="text-center">
                                {row.value === 1 ? "Verified" : "Unverified"}
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                        filterable: false,
                      },
                      {
                        Header: "Facebook",
                        accessor: "fb_token",
                        filterable: false,
                      },
                      {
                        Header: "Google",
                        accessor: "google_token",
                        filterable: false,
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const seach = {
  border: "1px solid #ced4da",
  borderColor: "#2b3553",
  backgroundColor: "transparent",
  borderRadius: "0.4285rem",
  color: "rgba(255, 255, 255, 0.8)",
  lineHeight: "normal",
  fontSize: "0.8571em",
  transition:
    "color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
  boxShadow: "none",
  marginLeft: "0.5em",
  display: "inline-block",
  width: "auto",
  height: "calc(1.8125rem + 2px)",
  padding: "0.25rem 0.5rem",
};
const searchLabel = {
  fontSize: "12px",
  fontWeight: "700",
  color: "rgba(255, 255, 255, 0.7)",
};

export default UserList;
