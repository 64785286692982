import React from "react";
import qs from "qs";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { getCompanyDetail, updateCompanyDetail,addCompanyDetail } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = { companyId: "", formInfo: null, loading: false };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const companyId = params.id;
    if (companyId) {
      this.setState({ companyId, loading: true }, () => {
        this.getCompanyDetails();
      });
    }
  }

  getCompanyDetails = () => {
    const { companyId } = this.state;
    getCompanyDetail(companyId)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getUser = getProp(["data", "data", "carBrand"]);
          this.setState({
            formInfo: getUser(response),
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  updateCompanyDetails = (e) => {
    e.preventDefault();
    const { companyId, formInfo } = this.state;
    this.setState({ loading: true });

    updateCompanyDetail(companyId, formInfo)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Company updated successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };
  addCompany = (e) => {
    e.preventDefault();
    const { companyId, formInfo } = this.state;
    this.setState({ loading: true });

    addCompanyDetail(formInfo)
  .then((response) => {
    const getSuccess = getProp(["data", "success"]);
    if (getSuccess(response) === 1) {
      const options = messageNotificationOptions(
        "Company added successfully.",
        "success"
      );
      this.refs.notificationAlert.notificationAlert(options);

      this.setState({
        loading: false,
      });
      history.goBack(null);
    } else {
      const getErrorMessage = getProp(["data", "message"]);
      const options = messageNotificationOptions(
        getErrorMessage(response),
        "danger"
      );
      this.refs.notificationAlert.notificationAlert(options);
      this.setState({ loading: false });
    }
  })
  .catch((err) => {
    const options = messageNotificationOptions(
      "Server error. Please try again.",
      "danger"
    );
    this.refs.notificationAlert.notificationAlert(options);
    this.setState({ loading: false });
  });
};

  /**
   * @function updateState
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */
  updateState = (changes, callback = null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...changes,
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * @function updatedFormInfo
   * @param updatedInfo
   * @description Returns updated login info
   */
  updatedFormInfo = (updatedInfo) => {
    const { formInfo } = this.state;
    return { ...formInfo, ...updatedInfo };
  };

  change = (e, key) => {
    const value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };

  render() {
    const { formInfo, loading, companyId } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">
                    {companyId ? "Edit Company" : "Add Company"}
                  </h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Insurance Company Name</label>
                          <Input
                            value={formInfo && formInfo.name}
                            type="text"
                            onChange={(e) => this.change(e, "name")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-fill"
                    color="primary"
                    type="submit"
                    onClick={(e) => {
                      if(companyId)
                      {
                      this.updateCompanyDetails(e);
                      }else{
                        this.addCompany(e);
                      }
                    }}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Company;
