import React from "react";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Col,
  FormGroup,
} from "reactstrap";
import { postLogin } from "../../services/AuthServices";
import { Loader } from "../components/../../components/Loader";
import {
  getProp,
  history,
  messageNotificationOptions,
  verifyLength,
  verifyEmail,
} from "../../helpers";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      usernameState: "",
      passwordState: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  change = (event, stateName, type) => {
    switch (type) {
      case "username":
        if (verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "password":
        if (verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  /**
   * @function onPressLogin
   * @param username
   * @param password
   * @description This function will let the user login
   */

  onPressLogin = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    postLogin(username, password)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getUserInfo = getProp(["data", "data", "admin"]);
          const getAuthToken = getProp(["data", "data", "api_key"]);
          localStorage.setItem(
            "userInfo",
            JSON.stringify(getUserInfo(response))
          );
          localStorage.setItem("authToken", getAuthToken(response));
          history.push("/admin/dashboard");
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };
  onClickForget = (e) => {
    e.preventDefault();
    history.push("/auth/lock-screen");
  };

  render() {
    const { usernameState, passwordState, isLoading } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>

        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form className="form">
                <Card className="card-login card-white">
                  <CardHeader>
                    <img
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    />
                    <CardTitle tag="h1">Log in</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className={`has-label ${usernameState}`}>
                      <label>Email Address *</label>
                      <Input
                        placeholder="Email"
                        type="text"
                        onFocus={(e) => this.setState({ emailFocus: true })}
                        onBlur={(e) => this.setState({ emailFocus: false })}
                        onChange={(e) => this.change(e, "username", "username")}
                      />
                      {usernameState === "has-danger" ? (
                        <label className="error">
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </FormGroup>
                    <FormGroup className={`has-label ${passwordState}`}>
                      <label>Password *</label>
                      <Input
                        placeholder="Password"
                        onFocus={(e) => this.setState({ passFocus: true })}
                        onBlur={(e) => this.setState({ passFocus: false })}
                        name="password"
                        type="password"
                        autoComplete="off"
                        onChange={(e) => this.change(e, "password", "password")}
                      />
                      {passwordState === "has-danger" ? (
                        <label className="error">Please enter password.</label>
                      ) : null}
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      disabled={
                        usernameState !== "has-success" ||
                        passwordState !== "has-success"
                      }
                      block
                      className="mb-3"
                      color="primary"
                      href="#pablo"
                      onClick={(e) => {
                        this.onPressLogin(e);
                      }}
                      size="lg"
                    >
                      Login
                    </Button>

                    <div className="pull-right">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={(e) => {
                            this.onClickForget(e);
                          }}
                        >
                          Forget Password?
                        </a>
                      </h6>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        {isLoading && <Loader />}
      </>
    );
  }
}

export default Login;
