import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import * as icons from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  termsAndConditionAction,
  updateTermsAndConditionAction,
} from "../../../redux/actions/termsAndCondition";

const PrivacyPolicy = () => {
  const Toast = window.Swal;

  const dispatch = useDispatch();

  const [_privacyPolicy, setPrivacyPolicy] = useState({
    privacy_policy: "",
  });

  const privacyPolicy = useSelector(
    (state) => state.termsAndConditionReducer.policy
  );

  useEffect(() => {
    document.title = "CemasKhidmat | Privacy Policy";

    setPrivacyPolicy({
      ..._privacyPolicy,
      privacy_policy: privacyPolicy.privacy_policy,
    });

    dispatch(termsAndConditionAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, privacyPolicy.privacy_policy]);

  const _updatePrivacyPolicy = (e) => {
    e.preventDefault();

    // console.log("_privacyPolicy: ", _privacyPolicy);
    dispatch(updateTermsAndConditionAction(_privacyPolicy));

    Toast.fire({
      icon: "success",
      title: "Privacy Policy updated successfully",
      toast: true,
      position: "top-end",
      timer: 5000,
      showConfirmButton: false,
      timerProgressBar: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Toast.stopTimer);
        toast.addEventListener("mouseleave", Toast.resumeTimer);
      },
    });
  };
  return (
    <>
      <div className="content">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <CardHeader>
                  <h5 className="title"> Privacy Policy</h5>
                </CardHeader>
              </Col>
              <Col className="text-right">
                <Button
                  className="mb-3"
                  title="Create notification"
                  onClick={_updatePrivacyPolicy}
                >
                  <icons.BsArrowRepeat /> Update Privacy Policy
                </Button>
              </Col>
            </Row>
            <ReactQuill
              theme="snow"
              value={_privacyPolicy.privacy_policy || " "}
              onChange={(value) =>
                setPrivacyPolicy({
                  ..._privacyPolicy,
                  privacy_policy: value,
                })
              }
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default PrivacyPolicy;
