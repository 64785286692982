import React from "react";
import qs from "qs";
import classNames from "classnames";
import moment from "moment";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
  CardTitle,
} from "reactstrap";
import Select from "react-select";
import NotificationAlert from "react-notification-alert";
// core components
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import { getBattery, editBattery, addBattery } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";
import CarBrandsModal from "./CarBrands";

class Battery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      batteryId: "",
      formInfo: null,
      loading: false,
      selectedCarBrand: null,
      selectedCarBrandModel: null,
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const batteryId = params.id;
    if (batteryId) {
      this.setState({ batteryId, loading: true }, () => {
        this.getBattery();
      });
    }
  }

  getBattery = () => {
    const { batteryId } = this.state;
    getBattery(batteryId)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getType = getProp(["data", "data", "carBattery"]);
          this.parsedFormInfoData(getType(response));
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  parsedFormInfoData = (data) => {
    this.setState({
      formInfo: {
        ...this.state.formInfo,
        type: data.type,
        model: data.model,
        description: data.description,
        retail_price: data.retail_price,
        promotion_price: data.promotion_price,
        dealer_percentage: data.dealer_percentage,
        car_brand_id: data.car_brand.id,
        car_brand_model_id: {
          label: data.car_model.name,
          value: data.car_model.id,
        },
        imageUrl:data.image&&data.image.url||"",
        iconUrl:data.logo&&data.logo.url||"",
      },
      selectedCarBrand: data.car_brand,
    });
  };

  updateBattery = (e) => {
    e.preventDefault();
    const { formInfo, batteryId } = this.state;
    this.setState({ loading: true });

    const formData = new FormData();
    if(this._batterLogoUploaderRef.state.file)
    formData.append("logo", this._batterLogoUploaderRef.state.file);
    if(this._batterImageUploaderRef.state.file)
    formData.append("image", this._batterImageUploaderRef.state.file);
    formData.append("type", formInfo.type);
    formData.append("model", formInfo.model);
    formData.append("description", formInfo.description);
    formData.append("retail_price", formInfo.retail_price);
    formData.append("promotion_price", formInfo.promotion_price);
    formData.append("dealer_percentage", formInfo.dealer_percentage);
    formData.append("car_brand_id", formInfo.car_brand_id);
    formData.append("car_brand_model_id", formInfo.car_brand_model_id.value);

    editBattery(batteryId, formData)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Battery updated successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function AddNewBattery
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */

  AddNewBattery = (e) => {
    e.preventDefault();
    const { formInfo } = this.state;
    this.setState({ loading: true });

    const formData = new FormData();
    formData.append("logo", this._batterLogoUploaderRef.state.file);
    formData.append("image", this._batterImageUploaderRef.state.file);
    formData.append("type", formInfo.type);
    formData.append("model", formInfo.model);
    formData.append("description", formInfo.description);
    formData.append("retail_price", formInfo.retail_price);
    formData.append("promotion_price", formInfo.promotion_price);
    formData.append("dealer_percentage", formInfo.dealer_percentage);
    formData.append("car_brand_id", formInfo.car_brand_id);
    formData.append("car_brand_model_id", formInfo.car_brand_model_id.value);

    addBattery(formData)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Battery added successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function updateState
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */
  updateState = (changes, callback = null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...changes,
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * @function updatedFormInfo
   * @param updatedInfo
   * @description Returns updated login info
   */
  updatedFormInfo = (updatedInfo) => {
    const { formInfo } = this.state;
    return { ...formInfo, ...updatedInfo };
  };

  change = (e, key) => {
    const value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };


  changeCheckBoxValue(e, key) {
    let value = e.target.value;
    let providerTypesFields = this.state.formInfo.providerTypesFields;
    let selectedCheckBoxIndex = providerTypesFields.findIndex(
      (o) => o.id == key
    );
    if (selectedCheckBoxIndex != -1) {
      providerTypesFields[selectedCheckBoxIndex].value = !providerTypesFields[
        selectedCheckBoxIndex
      ].value;
    }

    this.setState({
      formInfo: {
        ...this.state.formInfo,
        providerTypesFields: providerTypesFields,
      },
    });
  }

  editSlotDistance = (item) => {
    this.setState({
      formInfo: {
        ...this.state.formInfo,
        ...item,
      },
      selectedDistance: item,
    });
  };

  /**
   * @function deleteSlotDistance
   * @param e
   * @description delete distance from local model array
   */

  deleteSlotDistance = (item) => {
    const { slotDistances } = this.state;
    const deleteIndex = slotDistances.indexOf(item);
    slotDistances.splice(deleteIndex, 1);
    this.setState({ slotDistances });
  };

  cancelForm = (e) => {
    history.goBack();
  };

  getCarBrandModels = () => {
    const { selectedCarBrand } = this.state;
    let models = [];
    if (
      selectedCarBrand &&
      selectedCarBrand.models &&
      selectedCarBrand.models.length > 0
    ) {
      selectedCarBrand.models.map((model) => {
        models.push({
          value: model.id,
          label: model.name,
        });
      });
    }
    return models;
  };

  render() {
    const {
      formInfo,
      batteryId,
      selectedCarBrand,
      selectedCarBrandModel,
    } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">
                    {batteryId ? "Edit Battery" : "Add Battery"}
                  </h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="6">
                        <label>Add Battery Logo</label>
                        <ImageUpload
                          iconUrl={formInfo&&formInfo.iconUrl}
                          addBtnColor="default"
                          changeBtnColor="default"
                          logo={true}
                          ref={(ref) => (this._batterLogoUploaderRef = ref)}
                        />
                      </Col>
                      <Col md="6">
                        <label>Add Battery Image</label>
                        <ImageUpload
                          imageUrl={formInfo&&formInfo.imageUrl}
                          addBtnColor="default"
                          changeBtnColor="default"
                          ref={(ref) => (this._batterImageUploaderRef = ref)}
                        />
                      </Col>
                    </Row>
                    <Row className="row-center-align">
                      <Col md="12">
                        <FormGroup>
                          <label>Type</label>
                          <Input
                            type="text"
                            value={formInfo && formInfo.type}
                            onChange={(e) => this.change(e, "type")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label>Model</label>
                          <Input
                            type="text"
                            value={formInfo && formInfo.model}
                            onChange={(e) => this.change(e, "model")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            type="text"
                            value={formInfo && formInfo.description}
                            onChange={(e) => this.change(e, "description")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label>Retail Price</label>
                          <Input
                            type="number"
                            value={formInfo && formInfo.retail_price}
                            onChange={(e) => this.change(e, "retail_price")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label>Promotion Price</label>
                          <Input
                            type="number"
                            value={formInfo && formInfo.promotion_price}
                            onChange={(e) => this.change(e, "promotion_price")}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label>Dealer Percentage</label>
                          <Input
                            type="number"
                            value={formInfo && formInfo.dealer_percentage}
                            onChange={(e) =>
                              this.change(e, "dealer_percentage")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        md="6"
                        onClick={() => this._carBrandsModalRef.open()}
                      >
                        <FormGroup>
                          <label>Select Car Brand</label>
                          <Input
                            type="text"
                            value={selectedCarBrand && selectedCarBrand.name}
                            //disabled={true}
                            placeholder="Select Car Brand"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <label>Select Car Brand Model</label>
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          name="carModelSelect"
                          value={formInfo && formInfo.car_brand_model_id}
                          onChange={(value) =>
                            this.updateState({
                              formInfo: this.updatedFormInfo({
                                car_brand_model_id: value,
                              }),
                            })
                          }
                          options={this.getCarBrandModels()}
                          placeholder="Select Car Brand Model"
                        />
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-fill"
                    color="secondary"
                    type="submit"
                    onClick={(e) => {
                      this.cancelForm(e);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-fill"
                    color="primary"
                    type="submit"
                    onClick={(e) => {
                      if (batteryId) {
                        this.updateBattery(e);
                      } else {
                        this.AddNewBattery(e);
                      }
                    }}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <CarBrandsModal
            ref={(ref) => (this._carBrandsModalRef = ref)}
            onSelectMenuItem={(brand) => {
              this.updateState({
                selectedCarBrand: brand,
                formInfo: this.updatedFormInfo({
                  car_brand_id: brand.id,
                }),
              });
            }}
          />
        </div>
      </>
    );
  }
}

export default Battery;
