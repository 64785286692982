import axios from "axios";
import { getAuthHeaders, baseUrl } from "../helpers";

export const fetchContacts = (page, perPage) => {
  let url = `${baseUrl}panel-api/admin/default-emergency/gets?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const deleteContact = (companyId) => {
  let url = `${baseUrl}panel-api/admin/default-emergency/delete/${companyId}`;
  return axios.delete(url, {
    headers: getAuthHeaders(),
  });
};

export const getContactDetail = (id) => {
  let url = `${baseUrl}panel-api/admin/default-emergency/get/${id}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const updateContactDetail = (id, data) => {
  let url = `${baseUrl}panel-api/admin/default-emergency/edit/${id}`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const addContactDetail = (data) => {
  let url = `${baseUrl}panel-api/admin/default-emergency/add`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};
