import axios from "axios";
import { getAuthHeaders, baseUrl } from "../helpers";

export const fetchUsers = (page, perPage) => {
  let url = `${baseUrl}panel-api/admin/user/gets?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const deleteUser = (userId) => {
  let url = `${baseUrl}panel-api/admin/user/delete/${userId}`;
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
};

export const getUserDetail = (userId) => {
  let url = `${baseUrl}panel-api/admin/user/get/${userId}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const updateUserDetail = (userId, data) => {
  let url = `${baseUrl}panel-api/admin/user/edit/${userId}`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const fetchQueries = (params) => {
  let url = `${baseUrl}panel-api/admin/contact-us/gets`;
  return axios.get(url, {
    headers: getAuthHeaders(),
    params,
  });
};

export const fetchWithdrawls = () => {
  let url = `${baseUrl}panel-api/admin/user-wallet-withdraw/gets`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const setAcceptWithdrawl = (id, status) => {
  let url = `${baseUrl}panel-api/admin/user-wallet-withdraw/change-status`;
  return axios.post(
    url,
    { id, status },
    {
      headers: getAuthHeaders(),
    }
  );
};

export const updateQuery = (userId, status) => {
  let url = `${baseUrl}panel-api/admin/contact-us/changeStatus/${userId}`;
  return axios.post(
    url,
    { status },
    {
      headers: getAuthHeaders(),
    }
  );
};
