import React, { Component } from "react";
import classNames from "classnames";
import { fetchQueries, updateQuery } from "../../../services";
import { getProp, history, messageNotificationOptions } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Switch from "react-bootstrap-switch";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

class ProviderQueriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      alert: null,
    };
  }

  componentDidMount() {
    this.getProviderQueries();
  }

  updateQuery(item) {
    this.setState({ loading: true });
    updateQuery(item.provider_id, 1)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          this.getProviderQueries();
          const options = messageNotificationOptions(
            "Query updated successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  }

  /**
   * @function getProviderQueries
   * @description Get provider queries
   */

  getProviderQueries = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchQueries({
      page: currentPage,
      perPage,
      type: 1,
    })
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getProviderTypeList = getProp(["data", "data", "contactUs"]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const contactUs = getProviderTypeList(response);
          const modifiedContactUs = contactUs.map((item) => {
            return {
              ...item,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to edit */}
                  <Switch
                    offColor=""
                    onColor=""
                    onChange={(el, state) => this.updateQuery(item, state)}
                  />
                </div>
              ),
            };
          });
          this.setState({
            data: modifiedContactUs,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getProviderQueries();
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    const { loading, data, currentPage, totalPages, alert } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <div className="content">
          <Row>
            <Col md={8} className="ml-auto mr-auto">
              <h2 className="text-center">All Provider Queries</h2>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        filterable: false,
                        Header: "Provider Id",
                        accessor: "provider_id",
                      },
                      {
                        filterable: false,
                        Header: "Provider  Name",
                        accessor: "provider.name",
                      },
                      {
                        filterable: false,
                        Header: "Email",
                        accessor: "provider.email",
                      },
                      {
                        filterable: false,
                        Header: "Phone",
                        accessor: "provider.phone",
                      },
                      {
                        filterable: false,
                        Header: "Message",
                        accessor: "message",
                      },
                      {
                        Header: "Pending Queries",
                        accessor: "actions",
                        width: 120,
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ProviderQueriesList;
