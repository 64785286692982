import React from "react";
import qs from "qs";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
  CardTitle,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
// core components
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import {
  getVehicleType,
  editVehicleType,
  addVehicleType,
} from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";

class VehicleType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleTypeId: "",
      formInfo: null,
      loading: false,
      selectedCarBrand: null,
      selectedCarBrandModel: null,
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const vehicleTypeId = params.id;
    if (vehicleTypeId) {
      this.setState({ vehicleTypeId, loading: true }, () => {
        this.getVehicleType();
      });
    }
  }

  getVehicleType = () => {
    const { vehicleTypeId } = this.state;
    getVehicleType(vehicleTypeId)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getType = getProp(["data", "data", "userCarType"]);
          this.parsedFormInfoData(getType(response));
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  parsedFormInfoData = (data) => {
    this.setState({
      formInfo: {
        ...this.state.formInfo,
        name: data.name,
      },
    });
  };

  updateVehicleType = (e) => {
    e.preventDefault();
    const { formInfo, vehicleTypeId } = this.state;

    if (
      formInfo &&
      formInfo.name !== "" &&
      this._vehicleImageUploaderRef.state.file !== null
    ) {
      this.setState({ loading: true });

      const formData = new FormData();
      formData.append("image", this._vehicleImageUploaderRef.state.file);
      formData.append("name", formInfo.name);

      editVehicleType(vehicleTypeId, formData)
        .then((response) => {
          const getSuccess = getProp(["data", "success"]);
          if (getSuccess(response) === 1) {
            const options = messageNotificationOptions(
              "Vehicle type updated successfully.",
              "success"
            );
            this.refs.notificationAlert.notificationAlert(options);

            this.setState({
              loading: false,
            });
          } else {
            const getErrorMessage = getProp(["data", "message"]);
            const options = messageNotificationOptions(
              getErrorMessage(response),
              "danger"
            );
            this.refs.notificationAlert.notificationAlert(options);
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          const options = messageNotificationOptions(
            "Server error. Please try again.",
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        });
    } else {
      const options = messageNotificationOptions(
        "Name field can not be empty!",
        "danger"
      );
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  /**
   * @function AddNewBattery
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */

  AddNewVehicleType = (e) => {
    e.preventDefault();
    const { formInfo } = this.state;

    if (
      formInfo &&
      formInfo.name !== "" &&
      this._vehicleImageUploaderRef.state.file !== null
    ) {
      this.setState({ loading: true });
      const formData = new FormData();
      formData.append("image", this._vehicleImageUploaderRef.state.file);
      formData.append("name", formInfo.name);

      addVehicleType(formData)
        .then((response) => {
          const getSuccess = getProp(["data", "success"]);
          if (getSuccess(response) === 1) {
            const options = messageNotificationOptions(
              "Vehicle type added successfully.",
              "success"
            );
            this.refs.notificationAlert.notificationAlert(options);

            this.setState({
              loading: false,
            });
          } else {
            const getErrorMessage = getProp(["data", "message"]);
            const options = messageNotificationOptions(
              getErrorMessage(response),
              "danger"
            );
            this.refs.notificationAlert.notificationAlert(options);
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          const options = messageNotificationOptions(
            "Server error. Please try again.",
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        });
    } else {
      const options = messageNotificationOptions(
        "Name field can not be empty!",
        "danger"
      );
      this.refs.notificationAlert.notificationAlert(options);
    }
  };

  /**
   * @function updateState
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */
  updateState = (changes, callback = null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...changes,
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * @function updatedFormInfo
   * @param updatedInfo
   * @description Returns updated login info
   */
  updatedFormInfo = (updatedInfo) => {
    const { formInfo } = this.state;
    return { ...formInfo, ...updatedInfo };
  };

  change = (e, key) => {
    const value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };

  cancelForm = (e) => {
    history.goBack();
  };

  render() {
    const { formInfo, vehicleTypeId } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">
                    {vehicleTypeId ? "Edit Vehicle Type" : "Add Vehicle Type"}
                  </h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col md="6">
                        <label>Add Vehicle Type Image</label>
                        <ImageUpload
                          addBtnColor="default"
                          changeBtnColor="default"
                          ref={(ref) => (this._vehicleImageUploaderRef = ref)}
                        />
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            type="text"
                            value={formInfo && formInfo.name}
                            onChange={(e) => this.change(e, "name")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-fill"
                    color="secondary"
                    type="submit"
                    onClick={(e) => {
                      this.cancelForm(e);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-fill"
                    color="primary"
                    type="submit"
                    onClick={(e) => {
                      if (vehicleTypeId) {
                        this.updateVehicleType(e);
                      } else {
                        this.AddNewVehicleType(e);
                      }
                    }}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default VehicleType;
