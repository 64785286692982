import axios from "axios";
import { getAuthHeaders, baseUrl } from "../helpers";

export const fetchProviderTypes = (page, perPage) => {
  let url = `${baseUrl}panel-api/admin/provider-type/gets?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const addProviderType = (data) => {
  let url = `${baseUrl}panel-api/admin/provider-type/add`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const deleteProviderType = (providerTypeId) => {
  let url = `${baseUrl}panel-api/admin/provider-type/delete/${providerTypeId}`;
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
};

export const getProviderType = (providerTypeId) => {
  let url = `${baseUrl}panel-api/admin/provider-type/get/${providerTypeId}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const editProviderType = (providerTypeId, data) => {
  let url = `${baseUrl}panel-api/admin/provider-type/edit/${providerTypeId}`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const updateProviderTypePricing = (providerTypeId, data) => {
  let url = `${baseUrl}panel-api/admin/provider-type/update-pricing/${providerTypeId}`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};
