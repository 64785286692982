import React from "react";
import { useSelector } from "react-redux";

const SuccessMessage = () => {
  const Toast = window.Swal;

  const successOrError = useSelector(
    (state) => state.adsModuleReducer.successMessage
  );

  Toast.fire({
    icon:
      successOrError.success === 0
        ? "warning"
        : successOrError.success === 1
        ? "success"
        : null,
    title:
      successOrError.success === 0
        ? `${successOrError.message}`
        : successOrError.success === 1
        ? `${successOrError.message}`
        : null,
    toast: true,
    position: "top-end",
    timer: 5000,
    showConfirmButton: false,
    timerProgressBar: false,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Toast.stopTimer);
      toast.addEventListener("mouseleave", Toast.resumeTimer);
    },
  });
  return <div></div>;
};

export default SuccessMessage;
