import axios from "axios";
import {
  getAuthHeaders,
  baseUrl,
  getAuthHeadersForUploading,
} from "../helpers";

export const fetchCars = (page, perPage) => {
  let url = `${baseUrl}panel-api/admin/car-brand/gets?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const deleteCar = (carId) => {
  let url = `${baseUrl}panel-api/admin/car-brand/delete/${carId}`;
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
};

export const getCarDetail = (carId) => {
  let url = `${baseUrl}panel-api/admin/car-brand/get/${carId}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const updateCarDetail = (carId, data) => {
  let url = `${baseUrl}panel-api/admin/car-brand/edit/${carId}`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const addCar = (data) => {
  let url = `${baseUrl}panel-api/admin/car-brand/add`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const fetchCarTypes = (page, perPage) => {
  let url = `${baseUrl}panel-api/admin/user-car-type/gets?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const addVehicleType = (data) => {
  let url = `${baseUrl}panel-api/admin/user-car-type/add`;
  return axios.post(url, data, {
    headers: getAuthHeadersForUploading(),
  });
};

export const getVehicleType = (id) => {
  let url = `${baseUrl}panel-api/admin/user-car-type/get/${id}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const deleteVehicleType = (id) => {
  let url = `${baseUrl}panel-api/admin/user-car-type/delete/${id}`;
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
};

export const editVehicleType = (id, data) => {
  let url = `${baseUrl}panel-api/admin/user-car-type/edit/${id}`;
  return axios.post(url, data, {
    headers: getAuthHeadersForUploading(),
  });
};

export const uploadxlsFile = (selectedFile) => {
  // Create an object of formData
  const formData = new FormData();

  // Update the formData object
  formData.append("file", selectedFile, selectedFile.name);
  // Request made to the backend api
  // Send formData object
  return axios.post(`${baseUrl}panel-api/admin/car-brand/uploadXLS`, formData, {
    headers: getAuthHeaders(),
  });
};
