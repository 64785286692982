import React, { Component } from "react";
import classNames from "classnames";
import { fetchWithdrawls, setAcceptWithdrawl } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { textSpanIntersectsWithPosition } from "typescript";
import Switch from "react-bootstrap-switch";

class Withdrawls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedUser: "",
      alert: null,
      search: "",
    };
  }

  componentDidMount() {
    this.getWithdrawls();
  }

  /**
   * @function getWithdrawls
   * @description Get withdrawls
   */

  getWithdrawls = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchWithdrawls(currentPage, perPage)
      .then((response) => {
        // debugger;
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getWithdrawlList = getProp([
            "data",
            "data",
            "user_wallet_withdraws",
          ]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const withdrawls = getWithdrawlList(response);
          this.setState({
            data: withdrawls,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getWithdrawls();
    });
  };

  _setAcceptedWithdrawl(id, isVerified) {
    this.setState({ loading: true }, () => {
      setAcceptWithdrawl(id, isVerified)
        .then((response) => {
          const getSuccess = getProp(["data", "success"]);
          if (getSuccess(response) === 1) {
            this.getWithdrawls();
            const options = messageNotificationOptions(
              "Withdrawl updated successfully.",
              "success"
            );
            this.refs.notificationAlert.notificationAlert(options);
          } else {
            const getErrorMessage = getProp(["data", "message"]);
            const options = messageNotificationOptions(
              getErrorMessage(response),
              "danger"
            );
            this.refs.notificationAlert.notificationAlert(options);
          }
        })
        .catch((error) => {
          const options = messageNotificationOptions(
            "There is some server error. Please try again.",
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        });
    });
  }

  searchUserWithDrawal = (event) => {
    this.setState({ search: event.target.value.toLowerCase() });
  };

  render() {
    const { loading, currentPage, totalPages, alert } = this.state;

    var updatedList = this.state.data,
      search = this.state.search.toLowerCase();
    updatedList = updatedList.filter((item) => {
      return item.user.name.toLowerCase().match(search);
    });

    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <div className="content">
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">All User Withdrawls</h2>
          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <label style={searchLabel}>
                    Search:
                    <input
                      type="text"
                      style={seach}
                      placeholder="Search by name"
                      onChange={this.searchUserWithDrawal}
                    />
                  </label>
                  <ReactTable
                    data={updatedList}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        filterable: false,
                        Header: "User ID",
                        accessor: "user_id",
                      },
                      {
                        filterable: false,
                        Header: "User Name",
                        accessor: "user.name",
                      },
                      {
                        filterable: false,
                        Header: "User Phone",
                        accessor: "user.phone",
                      },
                      {
                        filterable: false,
                        Header: "Amount",
                        accessor: "amount",
                      },
                      {
                        filterable: false,
                        Header: "Bank Name",
                        accessor: "user.bank.bank_name",
                      },
                      {
                        filterable: false,
                        Header: "Account Number",
                        accessor: "user.bank.account_number",
                      },
                      {
                        filterable: false,
                        Header: "Account holder name",
                        accessor: "user.bank.account_holder_name",
                      },
                      {
                        filterable: false,
                        Header: "Open Requests",
                        accessor: "info",
                        Cell: (row) => {
                          if (row) {
                            return (
                              <div className="text-center">
                                {/* {row.value === 1 ? "Verified" : "Unverified"} */}
                                <Switch
                                  defaultValue={false}
                                  offColor=""
                                  onColor=""
                                  value={
                                    row.original.status &&
                                    row.original.status === 1
                                      ? true
                                      : false
                                  }
                                  onChange={(el) =>
                                    this._setAcceptedWithdrawl(
                                      row.original.id,
                                      el.state.value
                                    )
                                  }
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const seach = {
  border: "1px solid #ced4da",
  borderColor: "#2b3553",
  backgroundColor: "transparent",
  borderRadius: "0.4285rem",
  color: "rgba(255, 255, 255, 0.8)",
  lineHeight: "normal",
  fontSize: "0.8571em",
  transition:
    "color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
  boxShadow: "none",
  marginLeft: "0.5em",
  display: "inline-block",
  width: "auto",
  height: "calc(1.8125rem + 2px)",
  padding: "0.25rem 0.5rem",
};
const searchLabel = {
  fontSize: "12px",
  fontWeight: "700",
  color: "rgba(255, 255, 255, 0.7)",
};

export default Withdrawls;
