/**
 * @function getAuthHeaders
 * @desc This handles returns the auth headers array, which can be used in
 * making api requests
 */
export const getAuthHeaders = () => {
  const authToken = localStorage.getItem("authToken");
  if (authToken) {
    const headers = {
      "Content-Type": "application/json",
      "API-AUTH": authToken,
    };
    return headers;
  }
  return null;
};

export const getAuthHeadersForUploading = () => {
  let authToken = localStorage.getItem("authToken");
  if (authToken) {
    let headers = {
      "Content-Type": "multipart/form-data",
      "API-AUTH": authToken,
    };
    return headers;
  } else {
    return null;
  }
};
