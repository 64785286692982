import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Line, Bar } from "react-chartjs-2";
import Datetime from "react-datetime";
import Select from "react-select";
import {
  indexDashBoardAction,
  bookingAction,
  salesAction,
  signUpAction,
} from "../redux/actions/dashboard";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const Dashboard = () => {
  const dispatch = useDispatch();
  const d = new Date();

  //add one month by default
  const newDate = new Date(d.setMonth(d.getMonth() + 1));

  const indexDashboard = useSelector(
    (state) => state.dashboardReducers.dashboardIndex
  );

  //booking per month
  const bookingData = useSelector(
    (state) => state.dashboardReducers.bookingPerMonth
  );

  //booking per week
  const bookingPerWeek = useSelector(
    (state) => state.dashboardReducers.bookingPerWeek
  );

  //booking per day
  const bookingPerDay = useSelector(
    (state) => state.dashboardReducers.bookingPerDay
  );

  //Default value to show in the graph(Booking selection Monthly/Weekly/Daily)
  const bookingSelection = useSelector(
    (state) => state.dashboardReducers.bookingSelection
  );

  //booking per month defaul value when the page loaded
  const [bookingPerMonth, setBookingPerMonth] = useState({
    start_date: moment(new Date()).format("DD-MM-YYYY"),
    end_date: moment(newDate).format("DD-MM-YYYY"),
    type: 1,
  });

  //Booking selection
  const [selectBooking, setSelectBooking] = useState({
    bookingChoose: null,
  });

  //booking charts
  const booking = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

    //Booking keys label given by db(Booking per month)
    const bookingMonthlyLabels = Object.keys(bookingData)
      .join(", ")
      .split(", ");
    //Booking value given from db(Booking per month)
    const bookingValue = Object.values(bookingData)
      .join(", ")
      .split(", ")
      .map((el) => parseInt(el));

    //booking per week data
    const bookingDayNameLables = bookingPerWeek.map((item) => {
      return item["name"];
    });
    //booking per week count
    const bookingCount = bookingPerWeek.map((item) => {
      return item["count"];
    });

    const bookingDailyName = bookingPerDay.map((item) => {
      return (item["start"] = "Daily");
    });

    //booking per day count
    const bookingDailyCount = bookingPerDay.map((item) => {
      return item["count"];
    });

    // console.log("bookingDailyName: ", bookingDailyCount);

    return {
      labels:
        bookingSelection === "Monthly"
          ? bookingMonthlyLabels
          : bookingSelection === "Weekly"
          ? bookingDayNameLables
          : bookingSelection === "Daily"
          ? bookingDailyName
          : null,
      datasets: [
        {
          label:
            bookingSelection === "Monthly"
              ? "Booking per month"
              : bookingSelection === "Weekly"
              ? "Booking per week"
              : bookingSelection === "Daily"
              ? "Booking per day"
              : null,
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data:
            bookingSelection === "Monthly"
              ? bookingValue
              : bookingSelection === "Weekly"
              ? bookingCount
              : bookingSelection === "Daily"
              ? bookingDailyCount
              : null,
        },
      ],
    };
  };

  //Sale per month defaul value when the page loaded
  const [salesPerMonth, setSalesPerMonth] = useState({
    start_date: moment(new Date()).format("DD-MM-YYYY"),
    end_date: moment(newDate).format("DD-MM-YYYY"),
  });

  //Sale selection
  const [selectSales, setSelectSales] = useState({
    salesChoose: null,
  });

  //Default value to show in the graph(Sales selection Monthly/Weekly/Daily)
  const salesSelection = useSelector(
    (state) => state.dashboardReducers.salesSelection
  );

  //Sales per month
  const salesDataPerMonth = useSelector(
    (state) => state.dashboardReducers.salesPerMonth
  );

  //Sale per week
  const salesPerWeek = useSelector(
    (state) => state.dashboardReducers.salesPerWeek
  );

  //Sale per day
  const salePerDay = useSelector(
    (state) => state.dashboardReducers.salesPerDay
  );

  //Sales charts
  const sales = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    gradientStroke.addColorStop(0, "rgba(29,140,248,0)");

    //Sales keys label given by db(Sales per month)
    const salesMonthlyLabels = Object.keys(salesDataPerMonth)
      .join(", ")
      .split(", ");
    //Sales value given from db(Sales per month)
    const salesValue = Object.values(salesDataPerMonth)
      .join(", ")
      .split(", ")
      .map((el) => parseInt(el));

    //sale per week
    const salesPerWeekDayName = salesPerWeek.map((item) => {
      return item["name"];
    });
    //sale per week count
    const salePerWeekCount = salesPerWeek.map((item) => {
      return item["count"];
    });

    //sale per day
    const salesDailyName = salePerDay.map((item) => {
      return item["start"];
    });
    //sale per week count
    const salesPerWeekCount = salePerDay.map((item) => {
      return item["count"];
    });

    return {
      labels:
        salesSelection === "Monthly"
          ? salesMonthlyLabels
          : salesSelection === "Weekly"
          ? salesPerWeekDayName
          : salesSelection === "Daily"
          ? salesDailyName
          : null,
      datasets: [
        {
          label: "Data",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data:
            salesSelection === "Monthly"
              ? salesValue
              : salesSelection === "Weekly"
              ? salePerWeekCount
              : salesSelection === "Daily"
              ? salesPerWeekCount
              : null,
        },
      ],
    };
  };

  //Default value to show in the graph(Sing up selection Monthly/Weekly/Daily)
  const signUpSelection = useSelector(
    (state) => state.dashboardReducers.signUpSelection
  );

  //Sing up per month
  const signUpDataPerMonth = useSelector(
    (state) => state.dashboardReducers.signUpPerMonth
  );

  const signUpDataPerWeek = useSelector(
    (state) => state.dashboardReducers.signUpPerWeek
  );

  //Sign up per day
  const signUpPerDay = useSelector(
    (state) => state.dashboardReducers.signUpPerDay
  );

  //Sign up per month defaul value when the page loaded
  const [signUpPerMonth, setSignUpPerMonth] = useState({
    start_date: moment(new Date()).format("DD-MM-YYYY"),
    end_date: moment(newDate).format("DD-MM-YYYY"),
  });

  //Sale selection
  const [selectSignUp, setSelectSignUp] = useState({
    signUpChoose: null,
  });

  //sing up chart
  const signUp = (canvas) => {
    let ctx = canvas.getContext("2d");

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(66,134,121,0.15)");
    gradientStroke.addColorStop(0.4, "rgba(66,134,121,0.0)"); //green colors
    gradientStroke.addColorStop(0, "rgba(66,134,121,0)"); //green colors

    //Sing up keys label given by db(Sing up per month)
    const bookingMonthlyLabels = Object.keys(signUpDataPerMonth)
      .join(", ")
      .split(", ");
    //Sing up value given from db(Sing up per month)
    const bookingValue = Object.values(signUpDataPerMonth)
      .join(", ")
      .split(", ")
      .map((el) => parseInt(el));

    //sign up per week
    const signUpPerWeekDayName = signUpDataPerWeek.map((item) => {
      return item["name"];
    });
    //sign up per week count
    const signUpPerWeekCount = signUpDataPerWeek.map((item) => {
      return item["count"];
    });

    //Sign up per day start
    const signUpsDailyName = signUpPerDay.map((item) => {
      return item["start"];
    });

    //Sign up per week count
    const signUpPerDayCount = signUpPerDay.map((item) => {
      return item["count"];
    });

    return {
      labels:
        signUpSelection === "Monthly"
          ? bookingMonthlyLabels
          : signUpSelection === "Weekly"
          ? signUpPerWeekDayName
          : signUpSelection === "Daily"
          ? signUpsDailyName
          : null,
      datasets: [
        {
          label: "Data",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#00d6b4",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#00d6b4",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#00d6b4",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data:
            signUpSelection === "Monthly"
              ? bookingValue
              : signUpSelection === "Weekly"
              ? signUpPerWeekCount
              : signUpSelection === "Daily"
              ? signUpPerDayCount
              : null,
        },
      ],
    };
  };

  useEffect(() => {
    //booking per month
    dispatch(bookingAction(bookingPerMonth, selectBooking.bookingChoose));

    //sale per month
    dispatch(salesAction(salesPerMonth, selectSales.salesChoose));

    //signup per month
    dispatch(signUpAction(signUpPerMonth, selectSignUp.signUpChoose));

    dispatch(indexDashBoardAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //booking
  const _stateDate = (date) => {
    setBookingPerMonth({
      ...bookingPerMonth,
      start_date: moment(date._d).format("DD-MM-YYYY"),
    });
  };
  const _endDate = (date) => {
    setBookingPerMonth({
      ...bookingPerMonth,
      end_date: moment(date._d).format("DD-MM-YYYY"),
    });
  };
  const _searBooking = () => {
    if (selectBooking.bookingChoose === null) return;
    dispatch(bookingAction(bookingPerMonth, selectBooking.bookingChoose.value));
  };

  //sale
  const _stateDateSales = (date) => {
    setSalesPerMonth({
      ...salesPerMonth,
      start_date: moment(date._d).format("DD-MM-YYYY"),
    });
  };
  const _endDateSales = (date) => {
    setSalesPerMonth({
      ...salesPerMonth,
      end_date: moment(date._d).format("DD-MM-YYYY"),
    });
  };
  const _searSales = () => {
    if (selectSales.salesChoose === null) return;
    dispatch(salesAction(salesPerMonth, selectSales.salesChoose.value));
  };

  //sign up
  const _stateDateSignUp = (date) => {
    setSignUpPerMonth({
      ...signUpPerMonth,
      start_date: moment(date._d).format("DD-MM-YYYY"),
    });
  };
  const _endDateSignUp = (date) => {
    setSignUpPerMonth({
      ...signUpPerMonth,
      end_date: moment(date._d).format("DD-MM-YYYY"),
    });
  };
  const _searSingUp = () => {
    if (selectSignUp.signUpChoose === null) return;
    dispatch(signUpAction(signUpPerMonth, selectSignUp.signUpChoose.value));
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <Row>
              <Col lg="3" md="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col xs="5">
                        <div className="info-icon text-center icon-primary">
                          <i className="tim-icons icon-shape-star" />
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Providers</p>
                          <CardTitle tag="h3">
                            {indexDashboard && indexDashboard.total_providers}
                          </CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    {/* <hr /> */}
                    {/* <div className="stats">
                  <i className="tim-icons icon-sound-wave" /> Last Updated
                </div> */}
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col xs="5">
                        <div className="info-icon text-center icon-success">
                          <i className="tim-icons icon-single-02" />
                        </div>
                      </Col>
                      <Col xs="7">
                        <div className="numbers">
                          <p className="card-category">Users</p>
                          <CardTitle tag="h3">
                            {indexDashboard && indexDashboard.total_users}
                          </CardTitle>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    {/* <hr />
                <div className="stats">
                  <i className="tim-icons icon-trophy" /> Last Updated
                </div> */}
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle>
                      Bookings{" "}
                      <span className="text-muted" style={{ fontSize: "10px" }}>
                        e.g Monthly, Weekly, Daily
                      </span>
                    </CardTitle>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="select booking"
                      value={selectBooking.bookingChoose}
                      onChange={(value) =>
                        setSelectBooking({ bookingChoose: value })
                      }
                      options={[
                        {
                          value: "",
                          label: "Select Duration",
                          isDisabled: true,
                        },
                        { value: "Monthly", label: "Monthly" },
                        { value: "Weekly", label: "Weekly" },
                        { value: "Daily", label: "Daily" },
                      ]}
                      placeholder="Select Duration"
                    />
                  </Col>
                  <Col sm="6">
                    <Row>
                      <Col>
                        <CardTitle>Select start date</CardTitle>

                        <Datetime
                          timeFormat={false}
                          value={bookingPerMonth.start_date}
                          onChange={_stateDate}
                          inputProps={{ placeholder: "Select start date" }}
                        />
                      </Col>
                      <Col>
                        <CardTitle>Select end date</CardTitle>
                        <Datetime
                          timeFormat={false}
                          onChange={_endDate}
                          value={bookingPerMonth.end_date}
                          inputProps={{ placeholder: "Select end date" }}
                        />
                      </Col>
                      <Col className="mt-4">
                        <Button onClick={_searBooking}>Apply</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {bookingSelection === "Monthly" ? (
                    <Line data={booking} options={options} />
                  ) : bookingSelection === "Weekly" ? (
                    <Line data={booking} options={options} />
                  ) : bookingSelection === "Daily" ? (
                    <Line data={booking} options={options} />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col>
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle>
                      Sales{" "}
                      <span className="text-muted" style={{ fontSize: "10px" }}>
                        e.g Monthly, Weekly, Daily
                      </span>
                    </CardTitle>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="select Sales"
                      value={selectSales.salesChoose}
                      onChange={(value) =>
                        setSelectSales({
                          ...selectSales,
                          salesChoose: value,
                        })
                      }
                      options={[
                        {
                          value: "",
                          label: "Select Duration",
                          isDisabled: true,
                        },
                        { value: "Monthly", label: "Monthly" },
                        { value: "Weekly", label: "Weekly" },
                        { value: "Daily", label: "Daily" },
                      ]}
                      placeholder="Select Duration"
                    />
                  </Col>
                  <Col sm="6">
                    <Row>
                      <Col>
                        <CardTitle>Select start date</CardTitle>
                        <Datetime
                          timeFormat={false}
                          value={salesPerMonth.start_date}
                          onChange={_stateDateSales}
                          inputProps={{ placeholder: "Select start date" }}
                        />
                      </Col>
                      <Col>
                        <CardTitle>Select end date</CardTitle>
                        <Datetime
                          timeFormat={false}
                          onChange={_endDateSales}
                          value={salesPerMonth.end_date}
                          inputProps={{ placeholder: "Select end date" }}
                        />
                      </Col>
                      <Col className="mt-4">
                        <Button onClick={_searSales}>Apply</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {salesSelection === "Monthly" ? (
                    <Line data={sales} options={salePptions} />
                  ) : salesSelection === "Weekly" ? (
                    <Line data={sales} options={salePptions} />
                  ) : salesSelection === "Daily" ? (
                    <Line data={sales} options={salePptions} />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <CardTitle>
                      Registrations{" "}
                      <span className="text-muted" style={{ fontSize: "10px" }}>
                        e.g Monthly, Weekly, Daily
                      </span>
                    </CardTitle>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="select Sign up"
                      value={selectSignUp.signUpChoose}
                      onChange={(value) =>
                        setSelectSignUp({
                          ...selectSignUp,
                          signUpChoose: value,
                        })
                      }
                      options={[
                        {
                          value: "",
                          label: "Select Duration",
                          isDisabled: true,
                        },
                        { value: "Monthly", label: "Monthly" },
                        { value: "Weekly", label: "Weekly" },
                        { value: "Daily", label: "Daily" },
                      ]}
                      placeholder="Select Duration"
                    />
                  </Col>
                  <Col sm="6">
                    <Row>
                      <Col>
                        <CardTitle>Select start date</CardTitle>
                        <Datetime
                          timeFormat={false}
                          value={signUpPerMonth.start_date}
                          onChange={_stateDateSignUp}
                          inputProps={{ placeholder: "Select start date" }}
                        />
                      </Col>
                      <Col>
                        <CardTitle>Select end date</CardTitle>
                        <Datetime
                          timeFormat={false}
                          onChange={_endDateSignUp}
                          value={signUpPerMonth.end_date}
                          inputProps={{ placeholder: "Select end date" }}
                        />
                      </Col>
                      <Col className="mt-4">
                        <Button onClick={_searSingUp}>Apply</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  {signUpSelection === "Monthly" ? (
                    <Line data={signUp} options={singUpOptions} />
                  ) : signUpSelection === "Weekly" ? (
                    <Line data={signUp} options={singUpOptions} />
                  ) : signUpSelection === "Daily" ? (
                    <Line data={signUp} options={singUpOptions} />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
//chart options booking
let options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.0)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a",
        },
      },
    ],
  },
};
//sale option
const salePptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(186,84,245,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9e9e9e",
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(186,84,245,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9e9e9e",
        },
      },
    ],
  },
};

const singUpOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest",
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(186,84,245,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9e9e9e",
        },
      },
    ],
    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(186,84,245,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9e9e9e",
        },
      },
    ],
  },
};

export default Dashboard;
