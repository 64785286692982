import React from "react";
import qs from "qs";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { getUserDetail, updateUserDetail } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userId: "", formInfo: null, loading: false };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const userId = params.id;
    this.setState({ userId, loading: true }, () => {
      this.getUserDetails();
    });
  }

  getUserDetails = () => {
    const { userId } = this.state;
    getUserDetail(userId)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getUser = getProp(["data", "data", "user"]);
          this.setState({
            formInfo: getUser(response),
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  updateUserDetails = (e) => {
    e.preventDefault();
    const { userId, formInfo } = this.state;
    this.setState({ loading: true });

    updateUserDetail(userId, formInfo)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "User updated successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function updateState
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */
  updateState = (changes, callback = null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...changes,
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * @function updatedFormInfo
   * @param updatedInfo
   * @description Returns updated login info
   */
  updatedFormInfo = (updatedInfo) => {
    const { formInfo } = this.state;
    return { ...formInfo, ...updatedInfo };
  };

  change = (e, key) => {
    const value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };

  render() {
    const { formInfo, loading } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Full Name</label>
                          <Input
                            value={formInfo && formInfo.name}
                            type="text"
                            onChange={(e) => this.change(e, "name")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <label>Company (disabled)</label>
                          <Input
                            defaultValue="Creative Code Inc."
                            disabled
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      <Col className="pr-md-1" md="5">
                        <FormGroup>
                          <label>Phone Number</label>
                          <Input
                            value={formInfo && formInfo.phone}
                            type="text"
                            onChange={(e) => this.change(e, "phone")}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>Email address</label>
                          <Input
                            value={formInfo && formInfo.email}
                            type="email"
                            onChange={(e) => this.change(e, "email")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-fill"
                    color="primary"
                    type="submit"
                    onClick={(e) => {
                      this.updateUserDetails(e);
                    }}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default User;
