import React, { Component } from "react";
import classNames from "classnames";
import { fetchBookingReviews, deleteReview } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import Switch from "react-bootstrap-switch";
import { deleteWarningWithConfirmMessage } from "../../components/SweetAlert";

class ReviewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedReview: "",
      alert: null,
    };
  }

  componentDidMount() {
    this.getReviews();
  }

  /**
   * @function getReviews
   * @description Get reviews
   */

  getReviews = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchBookingReviews(currentPage, perPage)
      .then((response) => {
        // debugger;
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getWithdrawlList = getProp(["data", "data", "ratings"]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const withdrawls = getWithdrawlList(response);
          this.setState({
            data: withdrawls,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getReviews();
    });
  };

  _setAcceptedReview = () => {
    const { selectedReview } = this.state;
    this.setState({ loading: true, alert: null }, () => {
      deleteReview(selectedReview.id)
        .then((response) => {
          const getSuccess = getProp(["data", "success"]);
          if (getSuccess(response) === 1) {
            this.getReviews();
            const options = messageNotificationOptions(
              "Booking review deleted successfully.",
              "success"
            );
            this.refs.notificationAlert.notificationAlert(options);
          } else {
            const getErrorMessage = getProp(["data", "message"]);
            const options = messageNotificationOptions(
              getErrorMessage(response),
              "danger"
            );
            this.refs.notificationAlert.notificationAlert(options);
          }
        })
        .catch((error) => {
          const options = messageNotificationOptions(
            "There is some server error. Please try again.",
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        });
    });
  };

  deletePopup = (review) => {
    const alert = deleteWarningWithConfirmMessage(
      this._setAcceptedReview,
      this.hideAlert,
      "Are you sure you want to delete?",
      "Yes, delete it!"
    );
    this.setState({ selectedReview: review, alert });
  };

  render() {
    const { loading, data, currentPage, totalPages, alert } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <div className="content">
          <Col md={8} className="ml-auto mr-auto">
            <h2 className="text-center">All Booking Reviews</h2>
          </Col>
          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <ReactTable
                    data={data}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        filterable: false,
                        Header: "Booking ID",
                        accessor: "provider_type_booking_id",
                      },
                      {
                        filterable: false,
                        Header: "Booking Type",
                        accessor: "provider_type_booking.provider_type.name",
                      },
                      {
                        filterable: false,
                        Header: "Provider Rating",
                        accessor: "provider_stars",
                      },
                      {
                        filterable: false,
                        Header: "Provider Review",
                        accessor: "provider_text",
                      },
                      {
                        filterable: false,
                        Header: "User Rating",
                        accessor: "user_stars",
                      },

                      {
                        filterable: false,
                        Header: "Actions",
                        accessor: "info",
                        Cell: (row) => {
                          if (row) {
                            return (
                              <div className="text-center">
                                {/* {row.value === 1 ? "Verified" : "Unverified"} */}
                                <Button
                                  onClick={() => {
                                    this.deletePopup(row.original);
                                  }}
                                  color="danger"
                                  size="sm"
                                  className={classNames(
                                    "btn-icon btn-link like"
                                  )}
                                >
                                  <i className="tim-icons icon-simple-remove" />
                                </Button>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        },
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ReviewList;
