import axios from "axios";
import { getAuthHeaders, baseUrl } from "../helpers";

export const fetchProviders = (page, perPage) => {
  let url = `${baseUrl}panel-api/admin/provider/gets?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const deleteProvider = (providerId) => {
  let url = `${baseUrl}panel-api/admin/provider/delete/${providerId}`;
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
};

export const setVerifiedProvider = (providerId, isVerified) => {
  let url = `${baseUrl}panel-api/admin/provider/set-verified/${providerId}`;
  return axios.post(
    url,
    {
      admin_verified: isVerified ? 1 : 0,
    },
    {
      headers: getAuthHeaders(),
    }
  );
};

export const fetchProviderWithdrawls = () => {
  let url = `${baseUrl}panel-api/admin/provider-wallet-withdraw/gets`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const setAcceptProviderWithdrawl = (id, status) => {
  let url = `${baseUrl}panel-api/admin/provider-wallet-withdraw/change-status`;
  return axios.post(
    url,
    { id, status },
    {
      headers: getAuthHeaders(),
    }
  );
};
