import { DASHBOARD } from "../types";

const initState = {
  dashboardIndex: {
    total_providers: 0,
    total_users: 0,
  },
  bookingPerDay: [],
  bookingPerWeek: [],
  bookingPerMonth: {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  },
  bookingSelection: "Monthly",
  salesPerMonth: {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  },
  salesPerWeek: [],
  salesPerDay: [],
  salesSelection: "Monthly",
  signUpSelection: "Monthly",
  signUpPerMonth: {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  },
  signUpPerWeek: [],
  signUpPerDay: [],
};

const dashboardReducers = (state = initState, action) => {
  switch (action.type) {
    case DASHBOARD.INDEX:
      return {
        ...state,
        dashboardIndex: (state.dashboardIndex = action.payload),
      };

    case DASHBOARD.BOOKING_PER_MONTH:
      return {
        ...state,
        bookingPerMonth: (state.bookingPerMonth = action.payload),
      };

    case DASHBOARD.BOOKING_PER_WEEK:
      return {
        ...state,
        bookingPerWeek: (state.bookingPerWeek = action.payload),
      };
    case DASHBOARD.BOOKING_PER_DAY:
      return {
        ...state,
        bookingPerDay: (state.bookingPerDay = action.payload),
      };
    case DASHBOARD.BOOKING_SELECTION:
      return {
        ...state,
        bookingSelection: (state.bookingSelection = action.payload),
      };
    case DASHBOARD.SALES_PER_MONTH:
      return {
        ...state,
        salesPerMonth: (state.salesPerMonth = action.payload),
      };
    case DASHBOARD.SALES_PER_WEEK:
      return {
        ...state,
        salesPerWeek: (state.salesPerWeek = action.payload),
      };
    case DASHBOARD.SALES_PER_DAY:
      return {
        ...state,
        salesPerDay: (state.salesPerDay = action.payload),
      };
    case DASHBOARD.SALES_SELECTION:
      return {
        ...state,
        salesSelection: (state.salesSelection = action.payload),
      };
    case DASHBOARD.SIGNUP_SELECTION:
      return {
        ...state,
        signUpSelection: (state.signUpSelection = action.payload),
      };
    case DASHBOARD.SIGNUP_PER_MONTH:
      return {
        ...state,
        signUpPerMonth: (state.signUpPerMonth = action.payload),
      };
    case DASHBOARD.SIGNUP_PER_WEEK:
      return {
        ...state,
        signUpPerWeek: (state.signUpPerWeek = action.payload),
      };
    case DASHBOARD.SIGNUP_PER_DAY:
      return {
        ...state,
        signUpPerDay: (state.signUpPerDay = action.payload),
      };
    default:
      return state;
  }
};

export default dashboardReducers;
