import React from "react";
import axios from "axios";
import { getProp, logoutUser, history } from "../helpers";

/**
 * @function InterceptorService
 * @param props
 * @desc The request interceptor blocks invalid outgoing requests to avoid 403 or 401 errors
 * The response interceptor catches the errors and shows it on UI in the form of notifications
 */

const InterceptorService = (props) => {
  // Request interceptor
  axios.interceptors.request.use(
    function (config) {
      //Ignore the listed URLs
      let skipUrls = [
        "panel-api/admin/login/do",
        "panel-api/admin/login/forgot-password",
        "panel-api/admin/login/reset-password-code-verification",
        "panel-api/admin/login/reset-password",
      ];

      for (var i = 0; i < skipUrls.length; i++) {
        if (config.url.indexOf(skipUrls[i]) > -1) {
          return config;
        }
      }

      // Block the requests which do not have Authorization in request headers
      if (config.headers && config.headers["API-AUTH"] !== undefined) {
        return config;
      } else {
        logoutUser();
        history.push("/auth/login");
        return Promise.reject("Unauthorized request");
      }
    },
    function (error) {
      // Catch errors
      return Promise.reject(error);
    }
  );

  // Response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (errorMsg) {
      if (
        // Logout on 401 and 403 errors
        (errorMsg.response && errorMsg.response.status === 401) ||
        (errorMsg.response && errorMsg.response.status === 403)
      ) {
        //logout user
        logoutUser();
        history.push("/auth/login");
        return Promise.reject(errorMsg);
      } else {
        return Promise.reject(errorMsg);
      }
    }
  );
};
export { InterceptorService };
