import React, { Component } from "react";
import classNames from "classnames";
import { fetchCompanies, deleteCompany } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
import { deleteWarningWithConfirmMessage } from "../../components/SweetAlert";
// react component for creating dynamic tables
import ReactTable from "react-table";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { textSpanIntersectsWithPosition } from "typescript";

class CompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      loading: false,
      selectedCompany: "",
      alert: null,
      search: "",
    };
  }

  componentDidMount() {
    this.getCompanies();
  }

  /**
   * @function getCompanies
   * @description Get insurance companies
   */

  getCompanies = () => {
    const { currentPage, perPage } = this.state;
    this.setState({ loading: true });
    fetchCompanies(currentPage, perPage)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getUserList = getProp(["data", "data", "insuranceCompanies"]);
          const getCurrentPage = getProp(["data", "data", "current_page"]);
          const getTotalPages = getProp(["data", "data", "total_pages"]);
          const users = getUserList(response);
          const modifiedUsers = users.map((item) => {
            return {
              ...item,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to edit */}
                  <Button
                    onClick={() => {
                      this.viewPolicies(item);
                    }}
                    color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-single-copy-04" />
                  </Button>
                  <Button
                    onClick={() => {
                      this.editUser(item);
                    }}
                    color="warning"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  {/* use this button to remove the data row */}
                  <Button
                    onClick={() => {
                      this.deletePopup(item);
                    }}
                    color="danger"
                    size="sm"
                    className={classNames("btn-icon btn-link like")}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </div>
              ),
            };
          });
          this.setState({
            data: modifiedUsers,
            totalPages: getTotalPages(response),
            currentPage: getCurrentPage(response),
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  editUser = (company) => {
    history.push(`/admin/edit-company?id=${company.id}`);
  };

  viewPolicies = (company) => {
    history.push(`/admin/insurance-policies?id=${company.id}`);
  };

  deletePopup = (user) => {
    const alert = deleteWarningWithConfirmMessage(
      this.deleteSingleUser,
      this.hideAlert,
      "Are you sure you want to delete?",
      "Yes, delete it!"
    );
    this.setState({ selectedCompany: user, alert });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  deleteSingleUser = () => {
    const { selectedCompany } = this.state;
    this.setState({ alert: null });
    deleteCompany(selectedCompany.id)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          this.getCompanies();
          const options = messageNotificationOptions(
            "Company deleted successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page + 1 }, () => {
      this.getCompanies();
    });
  };

  onClickAddCompany = (e) => {
    e.preventDefault();
    history.push("/admin/edit-company");
  };
  searchCompany = (event) => {
    this.setState({ search: event.target.value.toLowerCase() });
  };
  render() {
    const { loading, currentPage, totalPages, alert } = this.state;

    var updatedList = this.state.data,
      search = this.state.search.toLowerCase();
    updatedList = updatedList.filter((item) => {
      return item.name.toLowerCase().match(search);
    });

    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        {alert}
        <div className="content">
          <Row>
            <Col md={8} className="ml-auto mr-auto">
              <h2 className="text-center">All Insurance Companies</h2>
            </Col>

            <Col md={1.5} className="ml-auto mr-auto mb-auto">
              <Button
                color="primary"
                onClick={(e) => {
                  this.onClickAddCompany(e);
                }}
              >
                Add Company
              </Button>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12} md={12}>
              <Card>
                <CardBody>
                  <label style={searchLabel}>
                    Search:
                    <input
                      type="text"
                      onChange={this.searchCompany}
                      style={seach}
                      placeholder="Seach by company name"
                    />
                  </label>
                  <ReactTable
                    data={updatedList}
                    filterable
                    resizable={false}
                    columns={[
                      {
                        filterable: false,
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    page={currentPage - 1}
                    pages={totalPages}
                    defaultPageSize={20}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    onPageChange={this.onPageChange}
                    className="-striped -highlight"
                    loading={loading}
                    manual={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
const seach = {
  border: "1px solid #ced4da",
  borderColor: "#2b3553",
  backgroundColor: "transparent",
  borderRadius: "0.4285rem",
  color: "rgba(255, 255, 255, 0.8)",
  lineHeight: "normal",
  fontSize: "0.8571em",
  transition:
    "color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out",
  boxShadow: "none",
  marginLeft: "0.5em",
  display: "inline-block",
  width: "auto",
  height: "calc(1.8125rem + 2px)",
  padding: "0.25rem 0.5rem",
};
const searchLabel = {
  fontSize: "12px",
  fontWeight: "700",
  color: "rgba(255, 255, 255, 0.7)",
};
export default CompanyList;
