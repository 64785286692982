import React from "react";
import qs from "qs";
import classNames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import TagsInput from "react-tagsinput";
import ReactTable from "react-table";
import NotificationAlert from "react-notification-alert";
import { updateCarDetail, addCar, getCarDetail } from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";

class Car extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carId: "",
      formInfo: null,
      loading: false,
      modelName: "",
      selectedModel: "",
      carModels: [],
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const carId = params.id;
    if (carId) {
      // Edit car
      this.setState({ carId, loading: true }, () => {
        this.getCarDetails();
      });
    }
  }

  getCarDetails = () => {
    const { carId } = this.state;
    getCarDetail(carId)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getCar = getProp(["data", "data", "carBrand"]);
          const getCarModels = getProp(["data", "data", "carBrand", "models"]);
          const models = getCarModels(response);
          this.setState({
            formInfo: getCar(response),
            loading: false,
            carModels: models,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  updateCarDetails = (e) => {
    e.preventDefault();
    let { carId, formInfo, carModels } = this.state;
    this.setState({ loading: true });
    formInfo.models = carModels;
    updateCarDetail(carId, formInfo)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Car updated successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function updateState
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */

  AddNewCarDetails = (e) => {
    e.preventDefault();
    const { formInfo } = this.state;
    this.setState({ loading: true });

    addCar(formInfo)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Car added successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function updateState
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */
  updateState = (changes, callback = null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...changes,
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * @function updatedFormInfo
   * @param updatedInfo
   * @description Returns updated login info
   */
  updatedFormInfo = (updatedInfo) => {
    const { formInfo } = this.state;
    return { ...formInfo, ...updatedInfo };
  };

  change = (e, key) => {
    const value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };

  /**
   * @function addCarModel
   * @param e
   * @description add or edit a model local
   */

  addCarModel = (e) => {
    e.preventDefault();
    const { carModels, selectedModel } = this.state;
    if (selectedModel && selectedModel.name) {
      if (selectedModel.id) {
        const deleteIndex = carModels.findIndex((item) => {
          return item.id === selectedModel.id;
        });
        carModels.splice(deleteIndex, 1, selectedModel);
      } else {
        carModels.push(selectedModel);
      }
      this.setState({ carModels });
    }
  };

  /**
   * @function onModelNameChange
   * @param e
   * @description update selected model name or new model
   */

  onModelNameChange = (e) => {
    const value = e.target.value;
    const { selectedModel } = this.state;
    let item = {};
    if (selectedModel) item = { ...selectedModel };
    item.name = value;
    this.setState({ selectedModel: item });
  };

  editCarModel = (item) => {
    this.setState({ selectedModel: item });
  };

  /**
   * @function deleteCarModel
   * @param e
   * @description delete model from local model array
   */

  deleteCarModel = (item) => {
    const { carModels } = this.state;
    const deleteIndex = carModels.indexOf(item);
    carModels.splice(deleteIndex, 1);
    this.setState({ carModels });
  };

  cancelForm = (e) => {
    history.goBack();
  };

  render() {
    const { formInfo, loading, carId, carModels, selectedModel } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">{carId ? "Edit Car" : "Add Car"}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Full Name</label>
                          <Input
                            value={formInfo && formInfo.name}
                            type="text"
                            onChange={(e) => this.change(e, "name")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="row-center-align">
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Add Model</label>
                          <Input
                            type="text"
                            value={selectedModel && selectedModel.name}
                            onChange={(e) => this.onModelNameChange(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <Button
                          className="btn-round btn-icon"
                          color="primary"
                          onClick={(e) => {
                            this.addCarModel(e);
                          }}
                        >
                          <i className="tim-icons icon-simple-add" />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ReactTable
                          showPagination={false}
                          data={carModels}
                          resizable={false}
                          minRows={0}
                          defaultPageSize={100}
                          columns={[
                            {
                              Header: "Name",
                              accessor: "name",
                              Cell: (row) => {
                                if (row) {
                                  return <div>{row.value}</div>;
                                } else {
                                  return null;
                                }
                              },
                              width: 90,
                            },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              Cell: (row) => {
                                if (row) {
                                  return (
                                    <div className="actions-right">
                                      {/* use this button to edit */}
                                      <Button
                                        onClick={() => {
                                          this.editCarModel(row.original);
                                        }}
                                        color="warning"
                                        size="sm"
                                        className={classNames(
                                          "btn-icon btn-link like"
                                        )}
                                      >
                                        <i className="tim-icons icon-pencil" />
                                      </Button>
                                      {/* use this button to remove the data row */}
                                      <Button
                                        onClick={() => {
                                          this.deleteCarModel(row.original);
                                        }}
                                        color="danger"
                                        size="sm"
                                        className={classNames(
                                          "btn-icon btn-link like"
                                        )}
                                      >
                                        <i className="tim-icons icon-simple-remove" />
                                      </Button>
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              },

                              width: 80,
                              sortable: false,
                              filterable: false,
                            },
                          ]}
                          showPageSizeOptions={false}
                          className="-striped -highlight"
                        />
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-fill"
                    color="secondary"
                    type="submit"
                    onClick={(e) => {
                      this.cancelForm(e);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-fill"
                    color="primary"
                    type="submit"
                    onClick={(e) => {
                      if (carId) this.updateCarDetails(e);
                      else this.AddNewCarDetails(e);
                    }}
                  >
                    Save
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Car;
