import React from "react";
import { Spinner } from "reactstrap";

/**
 * @function Loader
 * @desc This function returns the loader structure
 */
export const Loader = (props) => {
  return (
    <div className="loader-wrap">
      <Spinner className="loading-image"></Spinner>
    </div>
  );
};
