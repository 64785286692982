import axios from "axios";
import { getAuthHeaders, baseUrl } from "../helpers";

export const fetchCompanies = (page, perPage) => {
  let url = `${baseUrl}panel-api/admin/insurance-company/gets?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const deleteCompany = (companyId) => {
  let url = `${baseUrl}panel-api/admin/insurance-company/delete/${companyId}`;
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
};

export const getCompanyDetail = (id) => {
  let url = `${baseUrl}panel-api/admin/insurance-company/get/${id}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const updateCompanyDetail = (id, data) => {
  let url = `${baseUrl}panel-api/admin/insurance-company/edit/${id}`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const addCompanyDetail = ( data) => {
  let url = `${baseUrl}panel-api/admin/insurance-company/add`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const fetchCompanyInsurancePolicies = (companyId, page, perPage) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy/gets?insurance_company_id=${companyId}?page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const addInsurancePolicy = (data) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy/add`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const deleteInsurancePolicy = (policyId) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy/delete/${policyId}`;
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
};

export const getPolicyDetail = (id) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy/get/${id}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const updateInsurancePolicy = (id, data) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy/edit/${id}`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const fetchPolicySlots = (id, page, perPage) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy-time-slot/gets?insurance_company_policy_id=${id}&page=${page}&perPage=${perPage}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const addPolicySlot = (data) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy-time-slot/add`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};

export const deletePolicySlot = (id) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy-time-slot/delete/${id}`;
  return axios.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
};

export const getSlotDetail = (slotId) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy-time-slot/get/${slotId}`;
  return axios.get(url, {
    headers: getAuthHeaders(),
  });
};

export const updateSlotDetail = (slotId, data) => {
  let url = `${baseUrl}panel-api/admin/insurance-company-policy-time-slot/edit/${slotId}`;
  return axios.post(url, data, {
    headers: getAuthHeaders(),
  });
};
