import { ADSMODULE } from "../types";

const initState = {
  adsModule: [],
  loadingWhileFetching: false,
  adsModal: false,
  sucessfullyCreatedAds: false,
  successMessage: {
    success: 0,
    message: "",
  },
  adsModuleUpdate: {
    update: false,
    id: 0,
    image: null,
    imageToUpdate: null,
    url: "",
  },
};
const adsModuleReducer = (state = initState, action) => {
  switch (action.type) {
    case ADSMODULE.FETCH_ADS_MODULE:
      return {
        ...state,
        adsModule: (state.adsModule = action.payload),
      };
    case ADSMODULE.LOADING_WHILE_FETCHING:
      return {
        ...state,
        loadingWhileFetching: (state.loadingWhileFetching = action.payload),
      };
    case ADSMODULE.ADS_MODAL:
      return {
        ...state,
        adsModal: (state.adsModal = action.payload),
      };
    case ADSMODULE.CREATE_ADS:
      return {
        ...state,
        adsModule: [...state.adsModule, action.payload],
      };
    case ADSMODULE.SUCCESSFULLY_CREATED_ADS:
      return {
        ...state,
        sucessfullyCreatedAds: (state.sucessfullyCreatedAds = action.payload),
      };
    case ADSMODULE.SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: (state.successMessage = action.payload),
      };
    case ADSMODULE.DELETE_ADS_MODULE:
      return {
        ...state,
        adsModule: state.adsModule.filter((id) => id.id !== action.payload),
      };
    case ADSMODULE.UPDATE_ADS_MODULE:
      return {
        ...state,
        adsModuleUpdate: (state.adsModuleUpdate = action.payload),
      };
    case ADSMODULE.CONFIRM_UPDATE_ADS_MODULE:
      //const ads = state.adsModule.find((item) => item.id === action.payload.id);
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default adsModuleReducer;
