/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import { Button, Card, CardBody, CardText, Row, Col } from "reactstrap";

export const basicAlert = () => {
  this.setState({
    alert: (
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Here's a message!"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      />
    ),
  });
};
export const titleAndTextAlert = () => {
  this.setState({
    alert: (
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Here's a message!"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        It's pretty, isn't it?
      </ReactBSAlert>
    ),
  });
};
export const successAlert = () => {
  this.setState({
    alert: (
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        You clicked the button!
      </ReactBSAlert>
    ),
  });
};
export const htmlAlert = () => {
  this.setState({
    alert: (
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="HTML example"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        You can use <b>bold</b> text,{" "}
        <a href="https://www.creative-tim.com/">links</a> and other HTML tags
      </ReactBSAlert>
    ),
  });
};
export const deleteWarningWithConfirmMessage = (
  successDelete,
  hideAlert,
  title,
  confirmTitle
) => {
  return (
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title={title}
      onConfirm={() => {
        successDelete();
      }}
      onCancel={() => {
        hideAlert();
      }}
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="secondary"
      confirmBtnText={confirmTitle}
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this!!
    </ReactBSAlert>
  );
};

export const autoCloseAlert = () => {
  this.setState({
    alert: (
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Auto close alert!"
        onConfirm={() => this.hideAlert()}
        showConfirm={false}
      >
        I will close in 2 seconds.
      </ReactBSAlert>
    ),
  });
  setTimeout(this.hideAlert, 2000);
};
export const inputAlert = () => {
  this.setState({
    alert: (
      <ReactBSAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="Input something"
        onConfirm={(e) => this.inputConfirmAlert(e)}
        onCancel={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        btnSize=""
      />
    ),
  });
};
