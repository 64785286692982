import React from "react";
import qs from "qs";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import moment from "moment";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import {
  getPolicyDetail,
  addInsurancePolicy,
  updateInsurancePolicy,
} from "../../../services";
import { getProp, messageNotificationOptions, history } from "../../../helpers";

const MileageDropdownData = [
  { value: "0", label: "Unlimited" },
  { value: "1", label: "Limited" },
  { value: "2", label: "Accumulated" },
];

const TripsDropdownData = [
  { value: "0", label: "Unlimited" },
  { value: "1", label: "Limited" },
];

class Policy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: "",
      policyId: "",
      formInfo: {
        mileage: { value: "1", label: "Limited" },
        trips: { value: "1", label: "Limited" },
      },
      loading: false,
      edit: false,
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search.slice(1));
    const policyId = params.id;
    const companyId = params.companyId;
    const isEdit = params.edit;
    if (companyId) {
      this.setState({ companyId });
    }
    if (policyId && isEdit) {
      this.setState({ policyId, loading: true }, () => {
        this.getPolicyDetails();
      });
    }
    if (isEdit) {
      this.setState({ edit: true });
    }
  }

  getCurrentDateWithCustomTimeAppend(timeString) {
    let time = timeString.split(":");
    let hour = parseInt(time[0].trim());
    let min = parseInt(time[1].trim());
    let date = new Date();
    date.setHours(hour);
    date.setMinutes(min);

    return moment(date);
  }

  parsedFormData = (formData) => {
    let formInfoData = {
      ...this.state.formInfo,
      policyName: formData.name,
      numberStart: formData.no_start,
      numberEnd: formData.no_end,
      freeTierStartTime: this.getCurrentDateWithCustomTimeAppend(
        formData.free_tier.start
      ),
      freeTierEndTime: this.getCurrentDateWithCustomTimeAppend(
        formData.free_tier.end
      ),
      mileage: MileageDropdownData.find(
        (o) => o.value == formData.pricing_policy.mileage
      ),
      mileageValue: formData.pricing_policy.mileage_value,
      trips: TripsDropdownData.find(
        (o) => o.value == formData.pricing_policy.trips
      ),
      tripsValue: formData.pricing_policy.trips_value,
    };
    return formInfoData;
  };

  getPolicyDetails = () => {
    const { policyId } = this.state;
    getPolicyDetail(policyId)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        console.log(response, "responseresponse=>");
        if (getSuccess(response) === 1) {
          const getUser = getProp(["data", "data", "carBrand"]);
          this.setState({
            formInfo: this.parsedFormData(getUser(response)),
            loading: false,
            companyId: getUser(response).insurance_company.id,
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  updateInsurancePolicy = (e) => {
    e.preventDefault();
    const { policyId, formInfo, companyId } = this.state;
    this.setState({ loading: true });

    let updateInsurancePolicyPayload = {
      insurance_company_id: companyId,
      name: formInfo.policyName,
      no_start: formInfo.numberStart,
      no_end: formInfo.numberEnd,
      free_tier: {
        start: formInfo.freeTierStartTime.format("HH:mm"),
        end: formInfo.freeTierEndTime.format("HH:mm"),
      },
      pricing_category: {
        mileage: formInfo.mileage.value,
        mileage_value: formInfo.mileageValue,
        trips: formInfo.trips.value,
        trips_value: formInfo.tripsValue,
      },
    };

    updateInsurancePolicy(policyId, updateInsurancePolicyPayload)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Policy updated successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  addInsurancePolicy = (e) => {
    e.preventDefault();
    const { companyId, formInfo } = this.state;
    this.setState({ loading: true });

    let addInsurancePolicyPayload = {
      insurance_company_id: companyId,
      name: formInfo.policyName,
      no_start: formInfo.numberStart,
      no_end: formInfo.numberEnd,
      free_tier: {
        start: formInfo.freeTierStartTime.format("HH:mm"),
        end: formInfo.freeTierEndTime.format("HH:mm"),
      },
      pricing_category: {
        mileage: formInfo.mileage.value,
        mileage_value: formInfo.mileageValue,
        trips: formInfo.trips.value,
        trips_value: formInfo.tripsValue,
      },
    };

    addInsurancePolicy(addInsurancePolicyPayload)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Policy added successfully.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);

          this.setState({
            loading: false,
          });
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const options = messageNotificationOptions(
          "Server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
        this.setState({ loading: false });
      });
  };

  /**
   * @function updateState
   * @param changes
   * @param callback - optional
   * @description Update state based on changes
   */
  updateState = (changes, callback = null) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        ...changes,
      }),
      () => {
        if (callback) {
          callback();
        }
      }
    );
  };

  /**
   * @function updatedFormInfo
   * @param updatedInfo
   * @description Returns updated login info
   */
  updatedFormInfo = (updatedInfo) => {
    const { formInfo } = this.state;
    return { ...formInfo, ...updatedInfo };
  };

  change = (e, key) => {
    const value = e.target.value;
    this.updateState({ formInfo: this.updatedFormInfo({ [key]: value }) });
  };

  render() {
    const { formInfo, loading, companyId, policyId, edit } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">
                    {edit ? "Edit Policy" : "Add Policy"}
                  </h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            value={formInfo && formInfo.policyName}
                            type="text"
                            onChange={(e) => this.change(e, "policyName")}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Number Start</label>
                          <Input
                            value={formInfo && formInfo.numberStart}
                            type="number"
                            onChange={(e) => this.change(e, "numberStart")}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Number End</label>
                          <Input
                            value={formInfo && formInfo.numberEnd}
                            type="number"
                            onChange={(e) => this.change(e, "numberEnd")}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Free Tier</label>
                          <Row>
                            <Col md="6">
                              <ReactDatetime
                                viewMode="time"
                                dateFormat={false}
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Start Time",
                                }}
                                value={formInfo && formInfo.freeTierStartTime}
                                onChange={(date) =>
                                  this.updateState({
                                    formInfo: this.updatedFormInfo({
                                      freeTierStartTime: date,
                                    }),
                                  })
                                }
                              />
                            </Col>
                            <Col md="6">
                              <ReactDatetime
                                dateFormat={false}
                                timeFormat={true}
                                viewMode="time"
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "End Time",
                                }}
                                value={formInfo && formInfo.freeTierEndTime}
                                onChange={(date) =>
                                  this.updateState({
                                    formInfo: this.updatedFormInfo({
                                      freeTierEndTime: date,
                                    }),
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <label>Mileage</label>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={formInfo && formInfo.mileage}
                            onChange={(value) => {
                              this.updateState({
                                formInfo: this.updatedFormInfo({
                                  mileage: value,
                                }),
                              });
                            }}
                            options={MileageDropdownData}
                            placeholder="Trips"
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Mileage Value</label>
                          <Input
                            value={formInfo && formInfo.mileageValue}
                            type="number"
                            onChange={(e) => this.change(e, "mileageValue")}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Trips</label>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={formInfo && formInfo.trips}
                            onChange={(value) =>
                              this.updateState({
                                formInfo: this.updatedFormInfo({
                                  trips: value,
                                }),
                              })
                            }
                            options={TripsDropdownData}
                            placeholder="Trips"
                          />
                        </FormGroup>
                        <FormGroup>
                          <label>Trips Value</label>
                          <Input
                            value={formInfo && formInfo.tripsValue}
                            type="number"
                            onChange={(e) => this.change(e, "tripsValue")}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  {!edit ? (
                    <Button
                      className="btn-fill"
                      color="primary"
                      type="submit"
                      onClick={(e) => {
                        this.addInsurancePolicy(e);
                      }}
                    >
                      Add
                    </Button>
                  ) : (
                    <Button
                      className="btn-fill"
                      color="primary"
                      type="submit"
                      onClick={(e) => {
                        this.updateInsurancePolicy(e);
                      }}
                    >
                      Save
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Policy;
