/**
 * @function getUser
 * @desc This returns user info if the user is logged in
 */
export const getUser = () => {
  const user = localStorage.getItem("userInfo");
  const authToken = localStorage.getItem("authToken");
  return user && authToken ? JSON.parse(user) : null;
};

/**
 * @function isLoggedIn
 * @desc This checks whether user is logged in or not
 */
export const isLoggedIn = () => {
  const user = localStorage.getItem("userInfo");
  const authToken = localStorage.getItem("authToken");
  return user && authToken ? true : false;
};

/**
 * @function logoutUser
 * @desc logs user out
 */
export const logoutUser = () => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("authToken");
};

/**
 * @function capitalize
 * @desc This capitalizes the string
 */
export const capitalize = (s) => {
  if (s === "" || s === null || s === undefined) {
    return;
  }
  return s.toLowerCase().replace(/\b./g, function (a) {
    return a.toUpperCase();
  });
};

/**
 * @function getUserId
 * @desc returns User id
 */
export const getUserId = () => {
  let userId = null;
  const user = localStorage.getItem("user");
  if (user) {
    userId = JSON.parse(user).id;
  }
  return userId;
};
