import React, { useState, useEffect, useRef } from "react";
import { getTransactionFee, setTransactionFies } from "../../../services";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Label,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { messageNotificationOptions } from "../../../helpers";
const TransactionFee = () => {
  const notify = useRef(null);

  const [transactionFies, setTransactionFee] = useState({});

  const [addTransactionFee, setAddTransactionFee] = useState({
    transaction_fee: 0,
    transaction_fee_type: 0,
    changeTransaction: true,
  });

  // const [changeTransaction, setChangeTransaction] = useState(true);

  useEffect(() => {
    //console.log("User effect transaction fee");
    getTransactionFee()
      .then((response) => {
        console.log("Response: ", response.data.data);
        setTransactionFee(response.data.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  const handleChangeTransaction = () => {
    //console.log("Change transaction");
    setAddTransactionFee({ ...addTransactionFee, changeTransaction: false });
  };

  const changeTransactionFee = (event) => {
    let transaction_fee = event.target.value;

    setAddTransactionFee({
      ...addTransactionFee,
      transaction_fee: transaction_fee,
    });
  };

  const _handelUpdateData = (event) => {
    let transaction_fee_type = event.target.value;
    setTransactionFee({
      ...transactionFies,
      transaction_fee_type: transaction_fee_type,
    });
  };
  const updateTransaction = (event) => {
    event.preventDefault();
    setTransactionFies(transactionFies)
      .then((response) => {
        console.log("response: ", response);
        if (response.data.success === 1) {
          const options = messageNotificationOptions(
            "Transaction fee successfully updated",
            "success"
          );
          notify.current.notificationAlert(options);
        } else {
          const options = messageNotificationOptions(
            response.data.message,
            "danger"
          );

          notify.current.notificationAlert(options);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notify} />
      </div>
      {/* {addTransactionFee.changeTransaction ? (
        <div className="content">
          <Card>
            <CardHeader>
              <h4>Transaction fee</h4>
            </CardHeader>
            <CardBody>
              <Form>
                <FormGroup>
                  <FormGroup>
                    <label>Transaction fee</label>
                    <Input
                      type="number"
                      value={transactionFies.transaction_fee}
                    />
                  </FormGroup>

                  <Label>Select Transaction type</Label>
                  <Input type="select" name="select" id="exampleSelect">
                    {transactionFies.transaction_fee_type === "1" ? (
                      <option value={transactionFies.transaction_fee_type}>
                        Flat
                      </option>
                    ) : (
                      <option value={transactionFies.transaction_fee_type}>
                        Percentage
                      </option>
                    )}
                  </Input>
                </FormGroup>
              </Form>
            </CardBody>
            <CardFooter>
              <Button
                className="btn-fill"
                color="primary"
                type="submit"
                onClick={handleChangeTransaction}
              >
                Change transaction
              </Button>
            </CardFooter>
          </Card>
        </div>
      ) : ( */}
      <div className="content">
        <Card>
          <CardHeader>
            <h4>Transaction fee</h4>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <FormGroup>
                  <label>Transaction fee</label>
                  <Input
                    type="number"
                    value={transactionFies.transaction_fee}
                    onChange={(e) => changeTransactionFee(e)}
                  />
                </FormGroup>

                <Label>Select Transaction Fee type</Label>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  onChange={_handelUpdateData}
                  value={transactionFies.transaction_fee_type}
                >
                  <option disabled>Selct Fee type</option>
                  <option value="1">Flat</option>
                  <option value="2">Percentage</option>
                </Input>
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              className="btn-fill"
              color="primary"
              type="submit"
              onClick={(e) => {
                updateTransaction(e);
              }}
            >
              Save
            </Button>
          </CardFooter>
        </Card>
      </div>
      {/* )} */}
    </>
  );
};

export default TransactionFee;
