import React, { useState, useEffect, useRef } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { getTax, addNewTax } from "../../../services";
import { messageNotificationOptions } from "../../../helpers";
const Tax = () => {
  const notify = useRef(null);
  const [taxs, setTax] = useState({});

  useEffect(() => {
    getTax()
      .then((response) => {
        console.log("response: ", response.data.data);
        setTax(response.data.data);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);
  const _handleChangeTax = (event) => {
    let tax = event.target.value;
    // console.log("tax: ", tax);
    setTax({ ...taxs, tax: tax });
  };
  const updateTax = (event) => {
    event.preventDefault();
    addNewTax(taxs)
      .then((response) => {
        //console.log("Response: ", response);
        if (response.data.success === 1) {
          const options = messageNotificationOptions(
            "Tax updated successfully.",
            "success"
          );
          notify.current.notificationAlert(options);
        } else {
          const options = messageNotificationOptions(
            response.data.message,
            "danger"
          );

          notify.current.notificationAlert(options);
        }
      })
      .catch((error) => console.log("Error: ", error));
  };
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notify} />
      </div>
      <div className="content">
        <Card>
          <CardHeader>
            <h5 className="title">Tax</h5>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <label>Tax</label>
                <Input
                  value={taxs.tax}
                  type="number"
                  onChange={(e) => _handleChangeTax(e)}
                />
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              className="btn-fill"
              color="primary"
              type="submit"
              onClick={(e) => {
                updateTax(e);
              }}
            >
              update tax
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default Tax;
