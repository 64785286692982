import React from "react";
// reactstrap components
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  Container,
  Col,
  FormGroup,
} from "reactstrap";
import qs from "qs";
import { verifyCode } from "../../services/AuthServices";
import {
  getProp,
  history,
  messageNotificationOptions,
  verifyLength,
} from "../../helpers";

class VerifyCode extends React.Component {
  state = { code: "", codeState: "", email: "" };
  componentDidMount() {
    // document.body.classList.toggle("lock-page");
    const params = qs.parse(this.props.location.search.slice(1));
    const email = params.ph;
    this.setState({ email });
  }
  componentWillUnmount() {
    // document.body.classList.toggle("lock-page");
  }

  change = (event, stateName, type) => {
    switch (type) {
      case "code":
        if (verifyLength(event.target.value, 4)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;

      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  verifyResetCode = (e) => {
    e.preventDefault();
    const { code, email } = this.state;
    const params = { username: email, code };
    verifyCode(params)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const options = messageNotificationOptions(
            "Code has been verified. Please reset your password.",
            "success"
          );
          this.refs.notificationAlert.notificationAlert(options);
          setTimeout(() => {
            history.push(`/auth/reset-password?ph=${email}&vc=${code}`);
          });
        } else {
          const getErrorMessage = getProp(["data", "message"]);
          const options = messageNotificationOptions(
            getErrorMessage(response),
            "danger"
          );
          this.refs.notificationAlert.notificationAlert(options);
        }
      })
      .catch((error) => {
        const options = messageNotificationOptions(
          "There is some server error. Please try again.",
          "danger"
        );
        this.refs.notificationAlert.notificationAlert(options);
      });
  };

  render() {
    const { codeState } = this.state;
    return (
      <>
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Card className="card-lock card-white text-center">
                {/* <CardHeader>
                  <img alt="..." src={require("assets/img/emilyz.jpg")} />
                </CardHeader> */}
                <CardBody>
                  <CardTitle tag="h4">Forget Password</CardTitle>

                  <FormGroup className={`has-label ${codeState}`}>
                    <label>Email Verification Code *</label>
                    <Input
                      placeholder="Verification Code"
                      type="text"
                      onChange={(e) => this.change(e, "code", "code")}
                    />
                    {codeState === "has-danger" ? (
                      <label className="error">
                        Please enter a valid verification code.
                      </label>
                    ) : null}
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    disabled={codeState !== "has-success"}
                    className="btn-round"
                    color="primary"
                    href="#pablo"
                    size="lg"
                    onClick={(e) => {
                      this.verifyResetCode(e);
                    }}
                  >
                    Verify
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default VerifyCode;
