import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import defaultImg from "../../../assets/img/image_placeholder.jpg";
import {
  adsModalAction,
  confirmUpdateAdsModuleAction,
  createAdsAction,
  updateAdsModuleAction,
} from "../../../redux/actions/adsModule";

const AdsModule = () => {
  const img = useRef(null);

  const dispatch = useDispatch();

  const modal = useSelector((state) => state.adsModuleReducer.adsModal);

  const updateAdsModule = useSelector(
    (state) => state.adsModuleReducer.adsModuleUpdate
  );

  const [adsModule, setAdsModule] = useState({
    id: 0,
    screen_type: 1,
    image: null,
    url: "",
    priority: 2,
    previewImge: defaultImg,
    imageToUpdate: null,
  });

  useEffect(() => {
    setAdsModule({
      ...adsModule,
      id: !updateAdsModule.update ? 0 : updateAdsModule.id,
      image: !updateAdsModule.update ? null : updateAdsModule.image,
      url: !updateAdsModule.update ? "" : updateAdsModule.url,
      imageToUpdate: !updateAdsModule.imageToUpdate
        ? null
        : updateAdsModule.imageToUpdate,
      previewImge: !updateAdsModule.update ? defaultImg : updateAdsModule.image,
    });
  }, [dispatch, updateAdsModule]);

  const toggle = () => {
    //this will be reset into the default value
    dispatch(
      updateAdsModuleAction({
        update: false,
        id: 0,
        image: null,
        imageToUpdate: null,
        url: "",
      })
    );

    dispatch(adsModalAction(false));
  };

  // const toggle = () => dispatch(adsModalAction(false));

  const _selectImg = (e) => {
    e.preventDefault();
    img.current.click();
  };

  const _chooseAnImg = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setAdsModule({
        ...adsModule,
        image: file,
        previewImge: reader.result,
        imageToUpdate: 1,
      });
    };
    reader.readAsDataURL(file);
  };

  const _removeImg = () => {
    setAdsModule({
      ...adsModule,
      image: null,
      previewImge: defaultImg,
    });
    img.current.value = null;
  };

  const _createAds = (e) => {
    e.preventDefault();

    dispatch(createAdsAction(adsModule));
  };
  const _updateAd = (e) => {
    e.preventDefault();
    dispatch(confirmUpdateAdsModuleAction(adsModule));
  };
  return (
    <>
      <div className="content">
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            {" "}
            {!updateAdsModule.update ? "Create Ad" : "Update Ad"}{" "}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Url</Label>
                <Input
                  type="url"
                  name="url"
                  placeholder="Url"
                  style={{ color: "#000" }}
                  value={
                    !updateAdsModule.update ? adsModule.url : adsModule.url
                  }
                  onChange={(e) =>
                    setAdsModule({
                      ...adsModule,
                      url: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <div className="fileinput text-center">
                <input
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={_chooseAnImg}
                  ref={img}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={adsModule.previewImge}
                  alt="..."
                  style={{
                    maxWidth: "250px",
                    maxHeight: "225px",
                    width: "250px",
                    height: "225px",
                  }}
                />
              </div>
              <div
                style={{
                  margin: "auto",
                  width: "50%",
                  textAlign: "center",
                }}
              >
                {adsModule.image === null ? (
                  <Button className="btn-round" size="sm" onClick={_selectImg}>
                    Select image
                  </Button>
                ) : (
                  <span>
                    <Button
                      className="btn-round"
                      size="sm"
                      onClick={_selectImg}
                    >
                      Change
                    </Button>

                    <Button
                      className="btn-round"
                      size="sm"
                      color="danger"
                      onClick={_removeImg}
                    >
                      Remove
                    </Button>
                  </span>
                )}
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={!updateAdsModule.update ? _createAds : _updateAd}>
              {" "}
              {!updateAdsModule.update ? " Create Ad" : " Update Ad"}{" "}
            </Button>
            <Button color="danger" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default AdsModule;
