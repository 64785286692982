import { TERMSANDCONDITION } from "../types";

const initState = {
  loading: false,
  policy: {},
};

const termsAndConditionReducer = (state = initState, action) => {
  switch (action.type) {
    case TERMSANDCONDITION.GET_TERMS_AND_CONDITION:
      return {
        ...state,
        policy: (state.policy = action.payload),
      };

    default:
      return state;
  }
};
export default termsAndConditionReducer;
