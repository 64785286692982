import axios from "axios";
import { getAuthHeaders, baseUrl } from "../../helpers";
import {
  GET_STATES,
  NOTIFICATION_LIST,
  CREATE_NOTIFICATION,
  MODAL_TOGGLE,
  UPDATE_NOTIFICATION,
  DATA_TO_BE_UPDATE,
  UPDATED_NOTIFICATION_DATA,
} from "../types";

/* get-states */

const listOfStates = (payload) => {
  return {
    type: GET_STATES,
    payload,
  };
};

export const getStates = () => {
  let url = `${baseUrl}panel-api/admin/user-notification/get-states`;
  return async (dispatch) => {
    const response = await axios.get(url, {
      headers: getAuthHeaders(),
    });
    if (response.status === 200 && response.data.success === 1) {
      //   console.log("Response data: ", response.data.data.states);
      const data = response.data.data.states;
      //   dispatch(listOfStates(data));
    }
  };
};

/* Notification list */
const notificationPayload = (payload) => {
  return {
    type: NOTIFICATION_LIST,
    payload,
  };
};
export const notificationList = () => {
  let url = `${baseUrl}panel-api/admin/user-notification/gets`;
  return async (dispatch) => {
    const response = await axios.get(url, {
      headers: getAuthHeaders(),
    });

    if (response.status === 200 && response.data.success === 1) {
      const data = response.data.data.user_notifications;
      dispatch(notificationPayload(data));
    }
  };
};
/* ===End notification list=== */

/* create notification */
const createNotificationPayload = (payload) => {
  return {
    type: CREATE_NOTIFICATION,
    payload,
  };
};

export const createNotification = ({
  title,
  city,
  message,
  postCode,
  state,
  userType,
  providerType,
}) => {
  const url = `${baseUrl}panel-api/admin/user-notification/add`;
  const now = new Date();
  const dateTime = `${now.getFullYear()}-0${
    now.getMonth() + 1
  }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()} `;
  return async (dispatch) => {
    let data = {
      title,
      message,
      send_type: userType,
      scheduled_on: dateTime,
    };
    if (userType === "2") {
      data.provider_types = providerType;
    } else {
      data.postcodes = postCode;
    }
    const response = await axios.post(url, data, {
      headers: getAuthHeaders(),
    });
    //It must be return the newly created notification
    console.log("Response: ", response);
  };
};
/* End of create notification */

/* update the modal */
const updateNotificationPayload = (payload) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload,
  };
};

export const updateNotificationModal = (update) => {
  return (dispatch) => {
    dispatch(updateNotificationPayload(update));
  };
};
/* ===End of update the modal=== */

const dataToBeUpdatePayload = (payload) => {
  return {
    type: DATA_TO_BE_UPDATE,
    payload,
  };
};

export const dataTobeUpdate = (data) => {
  return (dispatch) => {
    dispatch(dataToBeUpdatePayload(data));
  };
};

/* actuall update the notification */
const actualUpdateNotificationPayload = (payload) => {
  return {
    type: UPDATED_NOTIFICATION_DATA,
    payload,
  };
};

export const actualUpdatedNotification = ({
  id,
  message,
  postCode,
  state,
  title,
}) => {
  let url = `${baseUrl}panel-api/admin/user-notification/edit/${id}`;
  return async (dispatch) => {
    const updatedData = {
      message,
      title,
      state: [state],
      postcodes: [postCode],
    };
    const response = await axios.post(url, updatedData, {
      headers: getAuthHeaders(),
    });

    if (response.status === 200 && response.data.success === 1) {
      console.log(response);
    }
  };
};

/* Modal */
const notificationModal = (payload) => {
  return {
    type: MODAL_TOGGLE,
    payload,
  };
};
export const notificationModalToggle = (toggle) => {
  return (dispatch) => {
    dispatch(notificationModal(toggle));
  };
};
/* ===End of modal=== */
