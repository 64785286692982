import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
  Button,
  Modal,
  ModalHeader,
  FormGroup,
  Form,
  Input,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationModalToggle,
  createNotification,
  updateNotificationModal,
  actualUpdatedNotification,
  dataTobeUpdate,
  notificationList,
} from "../../../redux/actions/notification";
import { getProp, messageNotificationOptions } from "../../../helpers";
import NotificationAlert from "react-notification-alert";
import { fetchProviderTypes } from "../../../services";

const Notification = () => {
  const notify = useRef(null);
  const modalToggle = useSelector(
    (state) => state.notificationReducer.modoToggle
  );
  const update = useSelector(
    (state) => state.notificationReducer.updateNotification
  );
  const toBeUpdate = useSelector(
    (state) => state.notificationReducer.dataTobeUpdate
  );

  const dispatch = useDispatch();

  /* state */
  const [state, setState] = useState([
    {
      value: "",
      label: " Select state",
      isDisabled: true,
    },
    { value: "JHR", label: "JOHOR" },
    { value: "KDH", label: "KEDAH" },
    { value: "KTN", label: "KTN" },
    { value: "KUL", label: "KUL" },
    { value: "LBN", label: "LBN" },
    { value: "KUL", label: "Kuala Lumpur" },
    { value: "LBN", label: "Labuan" },
    { value: "MLK", label: "MLK" },
    { value: "NSN", label: "NSN" },
    { value: "PHG", label: "PHG" },
    { value: "PJY", label: "Putrajaya" },
    { value: "PLS", label: "PLS" },
    { value: "PNG", label: "PNG " },
    { value: "PRK", label: "PRK" },
    { value: "SBH", label: "SBH" },
    { value: "SGR", label: "SGR" },
    { value: "SRW", label: "SRW " },
    { value: "TRG", label: "TRG" },
  ]);
  /* city */
  const [city, setCity] = useState([
    {
      value: "",
      label: " Select city",
      isDisabled: true,
    },
    { value: "Arau", label: "Arau" },
    { value: "Kaki Bukit", label: "Kaki Bukit" },
    { value: "Kangar", label: "Kangar" },
    { value: "Kuala Perlis", label: "Kuala Perlis" },
    { value: "Padang Besar", label: "Padang Besar" },
    { value: "Simpang Ampat", label: "Simpang Ampat" },
  ]);

  const [providerTypes, setProviderTypes] = useState([]);
  const [providerType, setProviderType] = useState([]);
  const [userType, setUserType] = useState([
    {
      value: "",
      label: " Select user type",
      isDisabled: true,
    },
    { value: "1", label: "User" },
    { value: "2", label: "Provider" },
  ]);
  /* post code */
  const [postCode, setPostCode] = useState([
    {
      value: "",
      label: " Select post code",
      isDisabled: true,
    },
    { value: "1000 ", label: "1000 " },
    { value: "1007 ", label: "1007 " },
    { value: "1009 ", label: "1009 " },
    { value: "1500 ", label: "1500 " },
    { value: "1502 ", label: "1502 " },
    { value: "1503 ", label: "1503 " },
    { value: "1504 ", label: "1504 " },
    { value: "1505 ", label: "1505 " },
    { value: "1506 ", label: "1506 " },
    { value: "1508 ", label: "1508 " },
    { value: " 1512", label: " 1512" },
    { value: " 1514", label: " 1514" },
    { value: " 1516", label: " 1516" },
    { value: " 1517", label: " 1517" },
    { value: " 1518", label: " 1518" },
    { value: " 1524", label: " 1524" },
    { value: " 1529", label: " 1529" },
    { value: " 1532", label: " 1532" },
    { value: " 1538", label: " 1538" },
    { value: " 1540", label: " 1540" },
    { value: " 1546", label: " 1546" },
    { value: " 1550", label: " 1550" },
    { value: " 1551", label: " 1551" },
    { value: " 1556", label: " 1556" },
    { value: " 1560", label: " 1560" },
    { value: " 1564", label: " 1564" },
    { value: " 1570", label: " 1570" },
    { value: " 1572", label: " 1572" },
    { value: " 1576", label: " 1576" },
    { value: " 1578", label: " 1578" },
    { value: " 1582", label: " 1582" },
    { value: " 1586", label: " 1586" },
    { value: " 1590", label: " 1590" },
    { value: " 1592", label: " 1592" },
    { value: " 1594", label: " 1594" },
    { value: " 1596", label: " 1596" },
    { value: " 1598", label: " 1598" },
    { value: " 1600", label: " 1600" },
    { value: " 1604", label: " 1604" },
    { value: " 1606", label: " 1606" },
    { value: " 1608", label: " 1608" },
    { value: " 1609", label: " 1609" },
    { value: " 1610", label: " 1610" },
    { value: " 1612", label: " 1612" },
    { value: " 1614", label: " 1614" },
    { value: " 1620", label: " 1620" },
    { value: " 1622", label: " 1622" },
    { value: " 1626", label: " 1626" },
    { value: " 1628", label: " 1628" },
    { value: " 1630", label: " 1630" },
    { value: " 1632", label: " 1632" },
    { value: " 1634", label: " 1634" },
    { value: " 1644", label: " 1644" },
    { value: " 1646", label: " 1646" },
    { value: " 1648", label: " 1648" },
    { value: " 1660", label: " 1660" },
    { value: " 1664", label: " 1664" },
    { value: " 1670", label: " 1670" },
    { value: " 1672", label: " 1672" },
    { value: " 1673", label: " 1673" },
    { value: " 1674", label: " 1674" },
    { value: " 1676", label: " 1676" },
    { value: " 1680", label: " 1680" },
    { value: " 1694", label: " 1694" },
    { value: " 2400", label: " 2400" },
    { value: " 2450", label: " 2450" },
    { value: " 2500", label: " 2500" },
    { value: " 2600", label: " 2600" },
    { value: " 2607", label: " 2607" },
    { value: " 2609", label: " 2609" },
  ]);

  const [notificationData, setNotificationData] = useState({
    title: "",
    message: "",
    state: "",
    city: "",
    postCode: [],
    userType: 1,
    providerType: [],
  });

  useEffect(() => {
    //Data to be update for redux state
    setNotificationData({
      id: toBeUpdate.id,
      title: toBeUpdate.title,
      message: toBeUpdate.message,
      state: toBeUpdate.state,
      postCode: toBeUpdate.postcodes,
      userType: toBeUpdate.userType,
      providerType: toBeUpdate.providerType,
    });
    getProviderTypes();
  }, [dispatch, toBeUpdate]);

  const toggleModal = () => {
    dispatch(notificationModalToggle(false));
    dispatch(updateNotificationModal(false));

    //reset the state toBeUpdate obj into empty obj
    dispatch(dataTobeUpdate({}));
  };
  const createNotificationOrUpdate = () => {
    if (!update) {
      dispatch(createNotification(notificationData));
      const options = messageNotificationOptions(
        "You have successfully created the notification",
        "success"
      );
      notify.current.notificationAlert(options);
      setTimeout(() => {
        dispatch(notificationList());
      }, 3000);
    } else {
      dispatch(actualUpdatedNotification(notificationData));
      const options = messageNotificationOptions(
        "You have successfully updated the notification",
        "success"
      );
      notify.current.notificationAlert(options);
      setTimeout(() => {
        dispatch(notificationList());
      }, 3000);

      //reset the state toBeUpdate obj into empty obj
      dispatch(dataTobeUpdate({}));
    }
    //close the modal
    toggleModal();
  };

  /**
   * @function getProviderTypes
   * @description Get provider types
   */

  const getProviderTypes = () => {
    fetchProviderTypes(1, 20)
      .then((response) => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response) === 1) {
          const getProviderTypeList = getProp([
            "data",
            "data",
            "providerTypes",
          ]);
          const providerTypes = getProviderTypeList(response);
          let newTypes = [];
          providerTypes.map((item) => {
            newTypes.push({ label: item.name, value: item.id });
          });
          setProviderTypes(newTypes);
        } else {
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notify} />
      </div>
      <Modal isOpen={modalToggle} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {!update ? "Create notification" : "Update notification"}
        </ModalHeader>

        <div className="modal-body">
          <Form>
            <div className="card-content">
              <FormGroup>
                <Input
                  type="text"
                  name="title"
                  placeholder="Title.."
                  value={notificationData.title}
                  onChange={(e) =>
                    setNotificationData({
                      ...notificationData,
                      title: e.target.value,
                    })
                  }
                  className="text-muted"
                />
              </FormGroup>
              {/* <FormGroup>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Choose State"
                  onChange={(item) =>
                    setNotificationData({
                      ...notificationData,
                      state: item.value,
                    })
                  }
                  options={state}
                />
              </FormGroup> */}
              {/* <FormGroup>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Choose City"
                  onChange={(item) =>
                    setNotificationData({
                      ...notificationData,
                      city: item.value,
                    })
                  }
                  options={city}
                />
              </FormGroup> */}
              <FormGroup>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Select User Type"
                  onChange={(item) =>
                    setNotificationData({
                      ...notificationData,
                      userType: item.value,
                    })
                  }
                  options={userType}
                />
              </FormGroup>
              {notificationData.userType === "2" ? (
                <FormGroup>
                  <Select
                    isMulti={true}
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Select Provider Type"
                    onChange={(item) => {
                      let selectedProviders = [];
                      item.map((i) => {
                        selectedProviders.push(i.value);
                      });
                      setNotificationData({
                        ...notificationData,
                        providerType: selectedProviders,
                      });
                    }}
                    options={providerTypes}
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <Select
                    isMulti={true}
                    className="react-select info"
                    classNamePrefix="react-select"
                    placeholder="Choose post code"
                    onChange={(item) => {
                      let selectedPostcodes = [];
                      item.map((i) => {
                        selectedPostcodes.push(i.value);
                      });
                      setNotificationData({
                        ...notificationData,
                        postCode: selectedPostcodes,
                      });
                    }}
                    options={postCode}
                  />
                </FormGroup>
              )}

              <FormGroup>
                <Label>Description</Label>
                <Input
                  className="text-muted"
                  type="textarea"
                  name="text"
                  placeholder="type message..."
                  value={notificationData.message}
                  onChange={(e) =>
                    setNotificationData({
                      ...notificationData,
                      message: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </div>
          </Form>
        </div>
        <div className="modal-footer text-center pt-1 justify-content-center">
          <Button
            type="submit"
            className="btn-round"
            onClick={createNotificationOrUpdate}
          >
            {!update ? "Create notification" : "Update notification"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Notification;
